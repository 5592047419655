import queryString from 'query-string';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';

import { hash } from '@breathelife/hash';
import { NodeIds } from '@breathelife/insurance-form-builder';
import { NodeIdAnswersResolver, deserializeNodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { ModuleName, TypewriterTracking } from '@breathelife/frontend-tracking';
import { authenticationSlice, navigationSlice } from '@breathelife/redux';
import { Language, LeadMarketingMetadata, LocalizedInsuranceProduct, VersionedAnswers } from '@breathelife/types';
import { MessageType } from '@breathelife/ui-components';

import { shortLocale, text, toCurrency } from '../../Localization/Localizer';
import { AccessTokenMethod } from '../../Models/AccessTokenMethod';
import Urls, { generateQuestionWithStepIdUrl } from '../../Navigation/Urls';
import ApiService from '../../Services/ApiService';
import * as ApplicationService from '../../Services/ApplicationService';
import { QuestionResponse } from '../../Services/ApplicationService';
import * as QuestionnaireLandingStepService from '../../Services/QuestionnaireLandingStepService';
import { identifyApplicant } from '../Analytics/AnalyticsOperations';
import { configurationSlice } from '../Configuration/ConfigurationSlice';
import { notificationSlice } from '../Notification/NotificationSlice';
import { getStep } from '../Step/StepOperations';
import { ConsumerFlowStore } from '../Store';
import { submissionSlice } from '../Submission/SubmissionSlice';
import { insuranceApplicationSlice } from './InsuranceApplicationSlice';

const { actions } = insuranceApplicationSlice;
const notificationActions = notificationSlice.actions;

export type StartFlowOptions = {
  allowSelfServe: boolean;
  firstStepId: string;
  onApplicationFetched?: () => Promise<void>;
};

type StartFlowProps = StartFlowOptions & {
  navigate: NavigateFunction;
  appId?: string;
  token?: string;
  method?: string;
};

type StartGenericFlowProps = {
  navigate: NavigateFunction;
  answers: VersionedAnswers;
  stepId: string;
  lang: Language;
  marketingMetadata?: LeadMarketingMetadata;
};

export const fetchApplicationById = (insuranceApplicationId: string) => async (dispatch: Dispatch) => {
  dispatch(actions.setIsLoading(true));

  try {
    const applicationResponse = await ApplicationService.fetchApplication(insuranceApplicationId);
    dispatch(actions.setInsuranceApplication({ insuranceApplication: applicationResponse.application }));
  } catch (e: any) {
    dispatch(notificationActions.setError({ message: e.response?.message }));
  }

  return dispatch(actions.setIsLoading(false));
};

export const fetchStepAndNavigate =
  (navigate: NavigateFunction, insuranceApplicationId?: string, stepId?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!insuranceApplicationId || !stepId) return;

    const lang = shortLocale();
    const firstQuestion = await dispatch<any>(getStep(stepId, lang, navigate));
    if (firstQuestion) {
      dispatch(navigationSlice.actions.setLoadingPage({ isVisible: false }));
      const url = generateQuestionWithStepIdUrl(firstQuestion.id);
      navigate(url);
    }
  };

export const dispatchCreatedApplication = (applicationResponse: QuestionResponse) => async (dispatch: Dispatch) => {
  dispatch(authenticationSlice.actions.setToken({ token: applicationResponse.token }));
  return dispatch(actions.setInsuranceApplication({ insuranceApplication: applicationResponse.application }));
};

export const createApplication = (data?: Record<string, unknown>) => async (dispatch: Dispatch) => {
  dispatch(submissionSlice.actions.reset());
  const applicationResponse = (await ApplicationService.createApplication({
    lang: shortLocale(),
    ...data,
  })) as QuestionResponse;

  return dispatchCreatedApplication(applicationResponse)(dispatch);
};

export function updateApplication(id: string, data: { lang?: Language; premium?: number }) {
  return async function (dispatch: Dispatch): Promise<void> {
    dispatch(actions.setIsLoading(true));

    try {
      const lang = shortLocale();
      const response = await ApplicationService.updateApplication(id, lang, data);
      dispatch(actions.setInsuranceApplication({ insuranceApplication: response.application }));
    } catch (err: any) {
      dispatch(
        notificationActions.setError({
          message: err.response?.message,
        }),
      );
    } finally {
      dispatch(actions.setIsLoading(false));
    }
  };
}

export const fetchApplicationByToken =
  (token: string, options?: { language: Language; maintainLoadingState?: boolean }) => async (dispatch: Dispatch) => {
    dispatch(actions.setIsLoading(true));
    dispatch(submissionSlice.actions.reset());

    try {
      const applicationResponse = await ApiService.consumer.getApplicationByToken(token);
      const application = applicationResponse.data.application;

      if (options?.language && options.language !== application.lang) {
        const updatedApplicationResponse = await ApiService.consumer.updateLanguage(application.id, options.language);
        applicationResponse.data.application = updatedApplicationResponse.data.application;
      }

      dispatch(authenticationSlice.actions.setToken({ token: applicationResponse.data.token }));
      dispatch(actions.setInsuranceApplication({ insuranceApplication: applicationResponse.data.application }));
    } catch (err: any) {
      dispatch(notificationActions.setError({ message: err.response?.message }));
    }

    // This check is required because with a private link, transitioning setIsLoading to true is done using the
    // step redux slice. Bypassing false now avoids a false>true>false condition where the landing page briefly flickers
    if (options?.maintainLoadingState) {
      return;
    }

    return dispatch(actions.setIsLoading(false));
  };

export const startFlow =
  (props: StartFlowProps) =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<void> => {
    let state = store();

    const existingAppId = state.consumerFlow.insuranceApplication?.insuranceApplication?.id;
    const { appId, token, method, allowSelfServe, firstStepId, onApplicationFetched, navigate } = props;
    const parsedUrl = queryString.parseUrl(window.location.href);
    const language = parsedUrl.query.lang as Language;

    if (!props.allowSelfServe && !props.appId && !props.token && !existingAppId) {
      // If there is no way to fetch an application, 404
      navigate(Urls.fourOhFour.fullPath);
      return;
    }

    dispatch(insuranceApplicationSlice.actions.setIsLoading(true));

    // If the application was accessed by /plan/new, fetch the application
    if (appId) {
      await dispatch<any>(fetchApplicationById(appId));
    } else if (token && method === AccessTokenMethod.publicLink) {
      // If the PlanFinder was accessed by a public link, create an application
      // If an appId is already present, do nothing.
      if (existingAppId) {
        const landingStepId = await fetchAndSetLandingStepId(dispatch, existingAppId, firstStepId);

        await dispatch<any>(fetchStepAndNavigate(navigate, existingAppId, landingStepId));
        dispatch(insuranceApplicationSlice.actions.setIsLoading(false));
        return;
      }

      try {
        await dispatch<any>(createApplication({ accessToken: token }));
      } catch (err: any) {
        dispatch(notificationActions.setError({ message: text('apiErrors.fetchApplication') }));
        dispatch(insuranceApplicationSlice.actions.setIsLoading(false));
        return;
      }
    } else if (token) {
      // If the PlanFinder was accessed by a private link, fetch the application
      await dispatch<any>(fetchApplicationByToken(token, { language, maintainLoadingState: true }));
    } else if (allowSelfServe) {
      try {
        await dispatch<any>(createApplication(undefined));
      } catch (err: any) {
        dispatch(notificationActions.setError({ message: text('apiErrors.fetchApplication') }));
        dispatch(insuranceApplicationSlice.actions.setIsLoading(false));
        return;
      }
    }

    // Update local state so the newly created application can be used for tracking
    state = store();

    const insuranceApplication = state.consumerFlow.insuranceApplication.insuranceApplication;

    if (insuranceApplication) {
      const applicationId = insuranceApplication.id;

      await identifyApplicant(applicationId);

      const needsAnalysisId = insuranceApplication.needsAnalysisId ?? null;
      const trackingLeadId = insuranceApplication.leadId ? Number(insuranceApplication.leadId) : null;

      TypewriterTracking.startedNewApplication({
        communicationMethod: method ?? '',
        hashedId: hash(applicationId),
        hashedNeedsAnalysisId: hash(needsAnalysisId),
        leadId: trackingLeadId,
        moduleName: ModuleName.needsAnalysis,
      });

      const landingStepId = await fetchAndSetLandingStepId(dispatch, applicationId, firstStepId);

      // If the carrier wants to do something after creating/fetching the application,
      // but before navigating to the next step it needs to pass a callback
      await onApplicationFetched?.();
      await dispatch<any>(fetchStepAndNavigate(navigate, insuranceApplication.id, landingStepId));
    }

    dispatch(insuranceApplicationSlice.actions.setIsLoading(false));
  };

export const startGenericFlow =
  (props: StartGenericFlowProps) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(insuranceApplicationSlice.actions.setIsLoading(true));
    const { answers, stepId, lang, marketingMetadata, navigate } = props;

    try {
      const applicationResponse = await QuestionnaireLandingStepService.submitQuestionnaireLandingStep(
        answers,
        stepId,
        lang,
        marketingMetadata,
      );
      void dispatchCreatedApplication(applicationResponse)(dispatch);

      const nextStepId = applicationResponse.step.id;
      const application = applicationResponse.application;

      await identifyApplicant(application.id);

      TypewriterTracking.startedNewApplication({
        communicationMethod: '',
        hashedId: hash(application.id),
        hashedNeedsAnalysisId: hash(null),
        leadId: application.leadId ? Number(application.leadId) : null,
        moduleName: ModuleName.needsAnalysis,
      });

      await dispatch<any>(fetchStepAndNavigate(navigate, application.id, nextStepId));
      dispatch(insuranceApplicationSlice.actions.setIsLoading(false));
    } catch (err: any) {
      dispatch(notificationActions.setError({ message: text('apiErrors.fetchApplication') }));
    }
  };

export const getRecommendedCoverage =
  (insuranceApplicationId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.setIsLoading(true));
    try {
      const recommendedCoverageResponse = await ApiService.consumer.getRecommendedCoverage(insuranceApplicationId);
      if (!recommendedCoverageResponse) {
        TypewriterTracking.viewedQuote({
          recommendedProducts: [
            {
              coverageAmount: -1,
            },
          ],
          hashedId: hash(insuranceApplicationId),
        });
        throw { response: { message: 'Error calculating coverage need' } };
      }
      dispatch(actions.setRecommendedCoverage(recommendedCoverageResponse.data.amount));
    } catch (e: any) {
      dispatch(notificationActions.setError({ message: e.response?.message }));
    }
    dispatch(actions.setIsLoading(false));
  };

export const getQuotes =
  (insuranceApplicationId: string, coverageAmount: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.setIsLoading(true));
    try {
      const quotesResponse = await ApiService.fetchQuotes({ appId: insuranceApplicationId, coverageAmount });
      const quotes = quotesResponse.data;
      dispatch(actions.setQuotes(quotes.quotePerProduct));
    } catch (e: any) {
      dispatch(notificationActions.setError({ message: text('apiErrors.fetchQuotes') }));
    }
    dispatch(actions.setIsLoading(false));
  };

export const getProducts =
  (insuranceApplicationId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.setIsLoading(true));
    try {
      const productsResponse = await ApiService.fetchProducts(insuranceApplicationId);
      dispatch(actions.setProducts(productsResponse.data));
    } catch (e: any) {
      dispatch(notificationActions.setError({ message: e.response?.message }));
    }
    dispatch(actions.setIsLoading(false));
  };

export const resetProducts =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.setProducts([]));
  };

export const getAddons =
  (insuranceApplicationId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.setIsLoading(true));
    try {
      const addonsResponse = await ApiService.getAddons(insuranceApplicationId);
      dispatch(actions.setAddons(addonsResponse.data));
    } catch (e: any) {
      dispatch(notificationActions.setError({ message: e.response?.message }));
    } finally {
      dispatch(actions.setIsLoading(false));
    }
  };

export const createReferencePremium =
  (referencePremium: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.setReferencePremium(referencePremium));
  };

export const resetQuotes =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(actions.resetQuotes());
  };

export const refreshApplicationPremium =
  () =>
  async (dispatch: Dispatch, store: () => ConsumerFlowStore): Promise<void> => {
    const state = store();

    const insuranceApplication = state.consumerFlow.insuranceApplication?.insuranceApplication;
    if (!insuranceApplication) return;

    const answers = insuranceApplication.answers;
    const nodeIdToAnswerPathMap = deserializeNodeIdToAnswerPathMap(insuranceApplication.answersDataStructure);

    const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);

    const productId = answersResolver.getAnswer(answers, NodeIds.productId, {});

    const coverageAmount = answersResolver.getAnswer(answers, NodeIds.coverageAmount, {});

    if (!productId || !coverageAmount) return;

    const applicationId = insuranceApplication.id;
    const currentMonthlyPremium = insuranceApplication.monthlyPremium;
    let newMonthlyPremium: number | null | undefined;

    try {
      const response = await ApiService.fetchQuotes({ appId: applicationId, coverageAmount });
      const quotes = response.data;
      if (!quotes || !quotes.quotePerProduct) return;
      dispatch(insuranceApplicationSlice.actions.setQuotes(quotes.quotePerProduct));

      const products = state.consumerFlow.insuranceApplication.products;

      const eligibleProductIdToIdMap = (products as Array<LocalizedInsuranceProduct>).reduce<Record<string, string>>(
        (acc, product) => {
          if (!product.productId || !product.isEligible) return acc;

          acc[product.productId] = product.id;

          return acc;
        },
        {},
      );

      const productUUID = eligibleProductIdToIdMap[productId];

      newMonthlyPremium = quotes.quotePerProduct[productUUID];

      if (newMonthlyPremium === currentMonthlyPremium) return;
    } catch (err: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: text('product.error.fetchQuotes'),
        }),
      );
      return;
    }

    try {
      const lang = shortLocale();
      const response = await ApplicationService.updateApplication(applicationId, lang, { premium: newMonthlyPremium });
      dispatch(
        insuranceApplicationSlice.actions.setInsuranceApplication({ insuranceApplication: response.application }),
      );
    } catch (err: any) {
      dispatch(
        notificationSlice.actions.setError({
          message: err.response?.message,
        }),
      );
      return;
    }

    dispatch(
      notificationSlice.actions.setNotification({
        type: MessageType.warning,
        message: text('product.updatedPremium', { amount: toCurrency(newMonthlyPremium ?? null) }),
        autoHideDuration: 5000,
      }),
    );
  };

async function fetchAndSetLandingStepId(
  dispatch: Dispatch,
  insuranceApplicationId: string,
  firstStepId?: string,
): Promise<string> {
  let landingStepId: string = '';
  try {
    if (firstStepId) {
      landingStepId = firstStepId;
    } else {
      const { data: questionnaireLandingStepId } =
        await ApiService.consumer.getQuestionnaireLandingStepId(insuranceApplicationId);
      landingStepId = questionnaireLandingStepId;
    }

    dispatch(
      configurationSlice.actions.setLandingStepIds({
        landingStepsIds: [landingStepId],
      }),
    );
  } catch (error: any) {
    dispatch(notificationSlice.actions.setError({ message: 'Error loading the first questionnaire step' }));
  }

  return landingStepId;
}
