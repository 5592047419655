import _ from 'lodash';
import { ReactElement } from 'react';

import { text } from '../../Localization/Localizer';

import {
  HeaderProps,
  ProgressHeader as BaseProgressHeader,
  PlanFinderHeader,
} from './PlanFinderHeader/PlanFinderHeader';

type ParallaxProps = {
  parallax?: boolean;
};

type ProgressHeaderProps = {
  progress: number;
} & HeaderProps &
  ParallaxProps;

export function ProgressHeader(props: ProgressHeaderProps): ReactElement {
  const progressText = text('header.completion', { progress: props.progress });

  return <BaseProgressHeader headerComponent={PlanFinderHeader} progressText={progressText} {...props} />;
}
