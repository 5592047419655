import { FormEvent, Fragment, ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logger } from '@breathelife/monitoring-frontend';
import { StripeCreditCardForm, StripeFormRefHandle, StripeProvider } from '@breathelife/payments-ui';
import { Box, Loader } from '@breathelife/ui-components';

import { Alert } from '../../../../../../../Alert/Alert';
import { GenericError, Props as GenericErrorProps } from '../../../../../../../Error/GenericError';
import { useCarrierContext, useDispatch, useLanguage, useNavigation } from '../../../../../../../../Hooks';
import { useFetchPaymentTransaction } from '../../../../../../../../ReactQuery/Payment/paymentTransaction.queries';
import {
  useCreatePaymentTokenMutation,
  usePatchPaymentTokenMutation,
} from '../../../../../../../../ReactQuery/Payment/paymentTransaction.mutations';
import { CreditCardFormModal } from '../../CreditCardFormModal';
import { PaymentSubsectionHeader } from '../../PaymentSubsectionHeader';
import { StripeSubsectionView } from './StripeSubsectionView';
import { notificationSlice } from '../../../../../../../../ReduxStore/Notification/NotificationSlice';

type RefetchControl = {
  count: number;
  isLimitReached: boolean;
};

type ContainerProps = {
  applicationId: string;
};

const maxRefetchCount = 3;
const defaultRefetchControl: RefetchControl = { count: 0, isLimitReached: false };
const recreateSetupStatuses: string[] = ['requires_confirmation', 'requires_action', 'canceled'];
const refetchPaymentTransactionStatuses: string[] = ['processing'];
const successfulStripeSubmissionStatuses: string[] = ['succeeded'];

function Container({ applicationId }: ContainerProps): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef<StripeFormRefHandle>(null);
  const refetchControl = useRef<RefetchControl>({ ...defaultRefetchControl });
  const [isCardProcessingRefetchComplete, setIsCardProcessingRefetchComplete] = useState<boolean>(false);
  const [tempClientSecret, setTempClientSecret] = useState<string | null>(null);
  const [formError, setFormError] = useState<string | undefined>();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<GenericErrorProps | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { mutate: createPaymentToken, isLoading: isCreatePaymentTokenLoading } = useCreatePaymentTokenMutation();
  const { mutate: patchPaymentToken } = usePatchPaymentTokenMutation();

  const clearRefetchControl = (): void => {
    refetchControl.current = { ...defaultRefetchControl };
  };

  const generateTemporaryClientSecret = useCallback(async () => {
    return await createPaymentToken(
      { data: { applicationId }, shouldInvalidateFetchQuery: false },
      {
        onSuccess: (paymentTransaction) => {
          setTempClientSecret(paymentTransaction?.paymentToken?.clientSecret);
        },
      },
    );
  }, [applicationId, createPaymentToken]);

  const {
    data: paymentTransaction,
    isLoading: isFetchPaymentTransactionLoading,
    isRefetching: isFetchPaymentTransactionRefetching,
    refetch: refetchPaymentTransaction,
  } = useFetchPaymentTransaction(
    applicationId,
    { withCardDetails: true, withToken: true },
    {
      onError: (err) => {
        logger.error(err);
        setError({ message: t('notifications.payment.failedToLoadPaymentInformation') });
      },
      onSuccess: async (paymentTransaction) => {
        if (!paymentTransaction) {
          clearRefetchControl();
          return await createPaymentToken({ data: { applicationId } });
        }

        if (recreateSetupStatuses.includes(paymentTransaction?.paymentToken?.status ?? '')) {
          // A payment method can only be added if the setup intent status is `requires_payment_method`
          // This workflow does not support 'requires_action', `requires_confirmation', or 'canceled' however,
          // these statuses should still be accounted for
          // See – https://stripe.com/docs/payments/intents#intent-statuses
          clearRefetchControl();
          // A temporary secret is generated upon opening the credit card form modal
          return;
        }

        if (refetchPaymentTransactionStatuses.includes(paymentTransaction?.paymentToken?.status ?? '')) {
          // If the payment method is still in a state of "processing", 3 attempts will be made to re-fetch
          // the transaction to see if it's state has changed
          const newCount = refetchControl.current.count + 1;
          refetchControl.current = {
            count: newCount,
            isLimitReached: newCount >= maxRefetchCount,
          };

          if (!refetchControl.current.isLimitReached) return;

          logger.error(
            `Max Retries reached: Stripe setup intent for payment transaction: ${paymentTransaction.id} is still in 'processing' state`,
          );
          setIsCardProcessingRefetchComplete(true);
          return;
        }

        clearRefetchControl();
      },
      refetchOnMount: 'always',
      refetchInterval: (paymentTransaction) => {
        const isRefetchCriteriaMet = refetchPaymentTransactionStatuses.includes(
          paymentTransaction?.paymentToken?.status ?? '',
        );
        return isRefetchCriteriaMet && !refetchControl.current.isLimitReached ? 3000 : false;
      },
    },
  );

  const clientSecretToUse = useMemo<string | null>(() => {
    if (!paymentTransaction?.paymentToken) {
      return null;
    }

    const clientSecret = tempClientSecret ?? paymentTransaction?.paymentToken?.clientSecret;

    if (!clientSecret) {
      // If this block is reached, the error is fatal
      // A payment transaction exists but has no client secret and no temporary client secret has been created
      // Due to this, the Stripe form is useless as it requires a client secret
      logger.error(
        `Unable to find a Stripe client secret – Neither a stored or temporary client secret could be found. Application ID: ${paymentTransaction.applicationId} Transaction ID: ${paymentTransaction.id}`,
      );
      setError({ message: t('notifications.payment.failedToLoadPaymentInformation') });
      return null;
    }

    return clientSecret;
  }, [paymentTransaction, t, tempClientSecret]);

  const onAddClick = useCallback(() => {
    const isReady =
      paymentTransaction?.paymentToken?.clientSecret &&
      paymentTransaction.paymentToken?.status === 'requires_payment_method';
    const isBeingRecreated =
      !tempClientSecret && recreateSetupStatuses.includes(paymentTransaction?.paymentToken?.status ?? '');

    if (isBeingRecreated) {
      // useFetchPaymentTransaction should not be invalidated because
      // the generated setup intent is temporary until confirmed by the credit card form
      void generateTemporaryClientSecret();
    }

    if (!isModalOpen && (isReady || isBeingRecreated)) setIsModalOpen(true);
  }, [generateTemporaryClientSecret, isModalOpen, paymentTransaction, tempClientSecret]);

  const onEditClick = useCallback(() => {
    if (!tempClientSecret) {
      // useFetchPaymentTransaction should not be invalidated because
      // the generated setup intent is temporary until confirmed by the credit card form
      void generateTemporaryClientSecret();
    }
    if (!isModalOpen) setIsModalOpen(true);
  }, [generateTemporaryClientSecret, isModalOpen, tempClientSecret]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setIsFormSubmitting(false);
    setFormError(undefined);
  }, []);

  const onSubmit = async (event?: FormEvent): Promise<void> => {
    event?.preventDefault();

    if (!paymentTransaction) {
      return;
    }

    setIsFormSubmitting(true);

    await formRef.current?.onSubmit({
      onSubmitFailure: (error) => {
        logger.error(error);

        setFormError(t('notifications.payment.failedToSubmitPaymentMethod'));
        setIsFormSubmitting(false);
      },
      onSubmitValidationFailure: () => setIsFormSubmitting(false),
      onSubmitSuccess: async ({ id: paymentToken, status = '' }) => {
        const isStatusValid = successfulStripeSubmissionStatuses.includes(status);

        if (!isStatusValid) {
          setFormError(t('notifications.payment.failedToSubmitPaymentMethod'));
          setIsFormSubmitting(false);
          return;
        }

        if (!tempClientSecret) {
          await refetchPaymentTransaction();
          closeModal();
          return;
        }

        const { id: paymentTransactionId } = paymentTransaction;

        return await patchPaymentToken(
          { applicationId, data: { paymentToken }, paymentTransactionId },
          {
            onSettled: (_, err) => {
              if (err) {
                logger.error(err);
                dispatch(
                  notificationSlice.actions.setError({
                    message: t('notifications.payment.failedToUpdatePaymentInformation'),
                  }),
                );
              }

              setTempClientSecret(null);
              closeModal();
            },
          },
        );
      },
    });
  };

  const isLoadingView =
    isFetchPaymentTransactionLoading ||
    isFetchPaymentTransactionRefetching ||
    (!paymentTransaction && isCreatePaymentTokenLoading) ||
    (!isCardProcessingRefetchComplete &&
      refetchPaymentTransactionStatuses.includes(paymentTransaction?.paymentToken?.status ?? ''));

  return (
    <Box mb={3}>
      <PaymentSubsectionHeader />
      {isLoadingView ? (
        <Loader />
      ) : isCardProcessingRefetchComplete ? (
        <Alert severity='info'>{t('assistedApplication.paymentSubsection.alerts.creditCardProcessing')}</Alert>
      ) : error ? (
        <GenericError {...error} />
      ) : clientSecretToUse ? (
        <Fragment>
          <StripeSubsectionView
            lastFourCardDigits={paymentTransaction?.paymentToken?.last4}
            onAddClick={onAddClick}
            onEditClick={onEditClick}
          />
          <CreditCardFormModal
            closeModal={closeModal}
            error={formError}
            hideCancel={isFormSubmitting}
            isLoading={isCreatePaymentTokenLoading}
            isOpen={isModalOpen}
            isSubmitting={isFormSubmitting}
            onSubmit={onSubmit}
          >
            <form onSubmit={onSubmit}>
              <StripeCreditCardForm clientSecret={clientSecretToUse} disabled={isFormSubmitting} ref={formRef} />
            </form>
          </CreditCardFormModal>
        </Fragment>
      ) : (
        <GenericError />
      )}
    </Box>
  );
}

export function StripeSubsectionContainer(): ReactElement | null {
  const { features } = useCarrierContext();
  const language = useLanguage();
  const { applicationId } = useNavigation();

  if (!features.payments.enabled) {
    logger.error('Attempting to render Stripe payments form when payments are not enabled');
    return null;
  }

  if (!features.payments.stripe) {
    logger.error('Attempting to render Stripe payments without Stripe configuration');
    return null;
  }

  if (!applicationId) {
    logger.error('Attempting to render Stripe payments without an application id');
    return null;
  }

  return (
    <StripeProvider config={features.payments.stripe} language={language}>
      <Container applicationId={applicationId} />
    </StripeProvider>
  );
}
