import { Fragment, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Grid } from '@breathelife/mui';
import { EqualityConditionValue, Language, PercentOfComparisonConditionValue } from '@breathelife/types';
import { Input } from '@breathelife/ui-components';

import { nodeWithBooleanValues } from '../../../Components/Conditions/Helpers/nodeIdFilters';
import RadioGroup, { Radio } from '../../../Components/RadioGroup/RadioGroup';
import { ValidationValues } from '../../../Helpers/inputValidation/form/salesDecisionRules';
import { NodeDetail, QuestionnaireNodeIds } from '../../../Helpers/questionnaireEditor/questionnaireNodeIds';
import { BooleanValuesRadioGroup, CriteriaValuesRadioGroup } from '../Helpers/CriteriaValuesRadioGroup';
import { ValidationErrorText } from '../ValidationErrorText';
import { ConditionTargetNodeAutocomplete } from './ConditionTargetNodeAutocomplete';

enum ComparisonValueType {
  string = 'string',
  nodeId = 'nodeId',
}

type Props = {
  value: string | number | boolean | undefined;
  onChange: (newValues: { value?: string | boolean | undefined; nodeIdOfValue?: string }) => void;
  nodeIdOfValue: string | undefined;
  nodeIdList: NodeDetail[];
  validationErrors: _.Dictionary<yup.ValidationError> | undefined;
  language: Language;
  selectOptionsByNodeId: QuestionnaireNodeIds['selectOptionsByNodeId'];
  condition: EqualityConditionValue | PercentOfComparisonConditionValue;
};

function EqualityConditionValueInput(props: {
  name: string;
  selectedNode?: NodeDetail;
  selectOptionsByNodeId: QuestionnaireNodeIds['selectOptionsByNodeId'];
  nodeIdOfValue: string | undefined;
  nodeIdList: NodeDetail[];
  value: string | number | boolean | undefined;
  onChange: (value: string) => void;
  onBooleanChange: (value: boolean) => void;
  onNodeIdOfValueChange: (value: string) => void;
  language: Language;
  validationErrors: _.Dictionary<yup.ValidationError> | undefined;
}): ReactElement {
  const { name, selectedNode, selectOptionsByNodeId, onChange, onBooleanChange, value } = props;

  const { t } = useTranslation();

  if (selectedNode && selectOptionsByNodeId[selectedNode.answerNodeId]) {
    const selectOptions = selectOptionsByNodeId[selectedNode.answerNodeId];
    return <CriteriaValuesRadioGroup name={name} value={value as string} options={selectOptions} onChange={onChange} />;
  } else if (selectedNode && nodeWithBooleanValues(selectedNode)) {
    return <BooleanValuesRadioGroup name={name} value={value as boolean} onChange={onBooleanChange} />;
  }

  return (
    <Input
      inputVariant='outlined'
      label={t('admin.conditions.labels.controlValue')}
      value={value ?? ''}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    />
  );
}

export function StringValueOrNodeIdInput(props: Props): ReactElement {
  const { onChange, nodeIdOfValue, nodeIdList, language, validationErrors, selectOptionsByNodeId, condition } = props;
  const { t } = useTranslation();
  const [comparisonValueType, setComparisonValueType] = useState<ComparisonValueType>(
    nodeIdOfValue ? ComparisonValueType.nodeId : ComparisonValueType.string,
  );
  return (
    <Fragment>
      <Grid item xs={12}>
        <RadioGroup
          onChange={setComparisonValueType}
          label={t('admin.conditions.labels.comparisonValueType')}
          name='condition-emptiness-radio-group'
          value={comparisonValueType}
        >
          <Grid container spacing={1}>
            <Grid item xs={3} sm={3}>
              <Radio value={ComparisonValueType.string} label={t('admin.conditions.labels.string')} />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Radio value={ComparisonValueType.nodeId} label={t('admin.conditions.labels.nodeId')} />
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>

      {comparisonValueType === ComparisonValueType.nodeId ? (
        <Grid item xs={12}>
          <ConditionTargetNodeAutocomplete
            value={nodeIdOfValue || ''}
            nodeIdList={nodeIdList}
            label={t('admin.conditions.labels.nodeId')}
            onSelect={(nodeIdOfValue) => {
              if (nodeIdOfValue !== null) {
                onChange({ nodeIdOfValue: nodeIdOfValue });
              }
            }}
            validationError={validationErrors && validationErrors[ValidationValues.nodeIdOfValue]}
            selectedLanguage={language}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <EqualityConditionValueInput
            name='equality-condition-value-input'
            selectedNode={nodeIdList.find((node) => node.answerNodeId === condition.targetNodeId)}
            selectOptionsByNodeId={selectOptionsByNodeId}
            nodeIdOfValue={nodeIdOfValue}
            onChange={(value: string) => onChange({ value })}
            onBooleanChange={(value: boolean) => onChange({ value })}
            onNodeIdOfValueChange={(value: string) => onChange({ value: undefined, nodeIdOfValue: value })}
            language={language}
            validationErrors={validationErrors}
            value={condition.value}
            nodeIdList={nodeIdList}
          />
          {validationErrors?.[ValidationValues.value] && (
            <ValidationErrorText>{validationErrors[ValidationValues.value].message}</ValidationErrorText>
          )}
        </Grid>
      )}
    </Fragment>
  );
}
