import { ReactElement, Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../Styles/themed-styled-components';

import { SalesDecisionRule } from '@breathelife/types';
import { AddIcon, Box } from '@breathelife/mui';

import { ActionButton } from '../../../Components/Button/ActionButton';
import { HelmetContainer } from '../../../Components/HelmetContainer';
import { useDispatch, useSelector } from '../../../Hooks';
import { DetailViewType } from '../../../Models/Layout';
import {
  getIsReloadingRules,
  getSalesDecisionRules,
  getTotal,
} from '../../../ReduxStore/Admin/SalesDecisionRulesManagement/SalesDecisionRulesManagementSelectors';
import { defaultState, layoutSlice } from '../../../ReduxStore/Layout/LayoutSlice';

import { QuestionnaireVersionsContext } from '../Questionnaire/ContextProvider/QuestionnaireVersionsContextProvider';
import { SalesDecisionRulesTable } from './SalesDecisionRulesTable';
import { Select, SelectProps } from '../../../Components/Select/Select';
import { useLanguage } from '../../../Hooks/useLanguage';
import { SalesDecisionRuleDrawer } from './SalesDecisionRuleDrawer';
import { QuestionnaireVersionDataContext } from '../Questionnaire/ContextProvider/QuestionnaireVersionDataContextProvider';

type SelectFunctionType = <T extends string | number>(props: SelectProps<T>) => React.ReactElement<SelectProps<T>>;

const createStyledSelect = <T extends string | number>(
  component: React.ComponentType<SelectProps<T>>,
): SelectFunctionType =>
  styled(component)`
    &&& {
      width: 300px;
    }
  ` as SelectFunctionType;

const QuestionnaireVersionSelect = createStyledSelect<string>(Select);
const QuestionnaireSelect = createStyledSelect<string>(Select);

export function SalesDecisionRulesManagementView({
  onQuestionnaireVersionFilterChanged,
}: {
  onQuestionnaireVersionFilterChanged: (version: string) => void;
}): ReactElement | null {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = useLanguage();

  const { questionnaires, questionnaireVersions, setSelectedQuestionnaireId, selectedQuestionnaireId } =
    useContext(QuestionnaireVersionsContext);
  const { questionnaireVersionId } = useContext(QuestionnaireVersionDataContext);

  useEffect(() => {
    setSelectedQuestionnaireId('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const questionnaireVersionsSelectOptions = useMemo(() => {
    return questionnaireVersions.map((questionnaireVersion) => {
      const versionDescription = questionnaireVersion.isDraft
        ? t('admin.questionnaireManagement.draftQuestionnaireVersion')
        : questionnaireVersion.description;

      return {
        value: questionnaireVersion.id,
        label: `${questionnaireVersion.majorVersion}.${questionnaireVersion.minorVersion}: ${versionDescription}`,
      };
    });
  }, [questionnaireVersions, t]);

  const questionnaireSelectOptions = useMemo(() => {
    return questionnaires.map((questionnaire) => {
      return {
        value: questionnaire.id,
        label: questionnaire.name[language] as string,
      };
    });
  }, [language, questionnaires]);

  const salesDecisionRules = useSelector(getSalesDecisionRules);
  const isReloadingRules = useSelector(getIsReloadingRules);
  const total = useSelector(getTotal);
  const { isOpen } = useSelector((store) => store.leadPlatform.layout.rightPanelState);

  const [selectedRule, setSelectedRule] = useState<SalesDecisionRule | null>(null);

  const onRowClick = useCallback(
    (rowId?: string) => {
      if (!rowId) return;
      const rule = salesDecisionRules.find((rule) => rule.id === rowId);
      if (!rule) return;
      setSelectedRule(rule);
      dispatch(
        layoutSlice.actions.setRightPanelState({ rightPanelState: { type: DetailViewType.edit, isOpen: true } }),
      );
    },
    [salesDecisionRules, dispatch],
  );

  const onQuestionnaireFilterChanged = useCallback(
    (questionnaireId: string) => {
      setSelectedQuestionnaireId(questionnaireId);
      const questionnaire = questionnaires.find((questionnaire) => questionnaire.id === questionnaireId);
      if (!questionnaire) return;
      const questionnaireVersion = questionnaireVersions.filter((q) => q.questionnaireId === questionnaireId)[0];
      if (!questionnaireVersion) return;
      onQuestionnaireVersionFilterChanged(questionnaireVersion.id);
    },
    [questionnaires, onQuestionnaireVersionFilterChanged, questionnaireVersions, setSelectedQuestionnaireId],
  );

  const onDrawerOpen = useCallback(() => {
    dispatch(
      layoutSlice.actions.setRightPanelState({
        rightPanelState: { isOpen: true, type: DetailViewType.create },
      }),
    );
  }, [dispatch]);

  const onDrawerClose = useCallback(() => {
    if (!isOpen) return;
    dispatch(layoutSlice.actions.setRightPanelState({ rightPanelState: defaultState.rightPanelState }));
    setSelectedRule(null);
  }, [dispatch, isOpen]);

  if (!questionnaireVersionId) return null;

  return (
    <Box m={2} width='100%'>
      <HelmetContainer text={t('pageTitles.salesDecisionRulesManagement')} />
      <Fragment>
        <Box display='flex' justifyContent='space-between' mb={2}>
          <QuestionnaireSelect
            isSearchFilter
            id='questionnaire-select'
            label={t('admin.questionnaireManagement.title')}
            value={selectedQuestionnaireId}
            options={questionnaireSelectOptions}
            onChange={onQuestionnaireFilterChanged}
          />
          <QuestionnaireVersionSelect
            isSearchFilter
            id='questionnaire-version-select'
            label={t('admin.salesDecisionRulesManagement.questionnaireVersion')}
            value={questionnaireVersionId}
            options={questionnaireVersionsSelectOptions}
            onChange={onQuestionnaireVersionFilterChanged}
          />
          <ActionButton
            data-testid='add-new-rule-button'
            color='primary'
            variant='contained'
            onClick={onDrawerOpen}
            startIcon={<AddIcon htmlColor='white' />}
            disabled={isReloadingRules}
          >
            {t('cta.addNew')}
          </ActionButton>
        </Box>
        <SalesDecisionRulesTable
          salesDecisionRules={salesDecisionRules}
          onRowClick={onRowClick}
          isReloadingRules={isReloadingRules}
          total={total}
          questionnaireVersionId={questionnaireVersionId}
        />
        <SalesDecisionRuleDrawer selectedRule={selectedRule} onDrawerClose={onDrawerClose} />
      </Fragment>
    </Box>
  );
}
