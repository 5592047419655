import i18next from 'i18next';

export type TimeFormat = '24' | '12';
export type TimeFrame = [number, number];

export const convertTimeIntoFormat = (time: number, format: TimeFormat): string => {
  if (time > 24 || time < 0) {
    throw new Error('time should be between 0-24');
  }

  const minutePart = time % 1;
  const minutes = Math.trunc(minutePart * 60);

  const hourPart = time - minutePart;

  if (format === '12') {
    const AmOrPM = hourPart >= 12 ? 'pm' : 'am';
    const hours = hourPart % 12 || 12;
    return minutes > 0 ? `${hours}:${minutes}${AmOrPM}` : `${hours}${AmOrPM}`;
  }
  return minutes > 0 ? `${hourPart}h${minutes}` : `${hourPart}h`;
};

export const getDayInText = (day: number, format: 'short' | 'full' = 'short'): string => {
  return i18next.t(`week.${day}.${format}`);
};

export const convertToReadableTime = (time: TimeFrame, format: TimeFormat): string =>
  `${convertTimeIntoFormat(time[0], format)} to ${convertTimeIntoFormat(time[1], format)}`;

export const getMethodDisplayText = (preferredMethods?: string[]): string => {
  const translatedMethods = preferredMethods?.map((method) => i18next.t(method));
  const methods = translatedMethods?.join(` ${i18next.t('and')} `);
  return methods ? `${i18next.t('by')} ${methods}` : '';
};
