import { useEffect, useState } from 'react';

import { Language } from '@breathelife/types';

import { useCarrierContext, useSelector } from '.';
import { getLanguage } from '../Localization/utils';

export function useLanguage(): Language {
  const { languageSettings } = useCarrierContext();
  const { user } = useSelector((store) => store.leadPlatform.authentication);
  const [language, setLanguage] = useState<Language>(getLanguage(languageSettings.default));

  useEffect(() => {
    if (!user || user.platformLanguage === language) {
      return;
    }

    setLanguage(user.platformLanguage);
  }, [user, language]);

  return language;
}
