import styled from '../../../../Styles/themed-styled-components';

export const ComprehensivePricingContractProductsContainer = styled.div`
  margin-top: 8px;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.grey[20]};
`;

export const ComprehensivePricingProductContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: start;
`;

export const ComprehensivePricingCardFooterContainer = styled.div`
  padding: 0 16px 0 24px;
`;

export const ComprehensivePricingCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  border-top: 1px solid ${(props) => props.theme.colors.grey[40]};
`;

export const ComprehensivePricingSubtotalContainer = styled.div<{ isLast: boolean }>`
  border-bottom: ${(props) => (props.isLast ? 'none' : `1px solid ${props.theme.colors.grey[40]}`)};
  padding-bottom: ${(props) => (props.isLast ? 0 : `24px`)};
`;
