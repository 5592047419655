import { ReactElement, useCallback, useEffect, useState } from 'react';
import { NumericFormat, NumericFormatProps, NumberFormatValues } from 'react-number-format';

import { InputAdornment, OutlinedTextFieldProps } from '@breathelife/mui';
import { Language } from '@breathelife/types';

import { FieldProps, formatRequiredFieldTitle } from './Helpers/FieldHelper';
import { Input } from './TextInput';

type MoneyInputProps = Omit<OutlinedTextFieldProps, 'variant'> &
  FieldProps & {
    boldedBorder?: boolean;
    value?: number;
    locale?: Language;
    allowTextWrap?: boolean;
  };

function getLocaleOptions(
  lang?: Language,
): Pick<NumericFormatProps, 'thousandSeparator' | 'decimalSeparator' | 'allowedDecimalSeparators'> {
  if (!lang || lang === Language.en)
    return {
      thousandSeparator: ',',
      decimalSeparator: '.',
      allowedDecimalSeparators: ['.'],
    };

  return {
    thousandSeparator: ' ',
    decimalSeparator: ',',
    allowedDecimalSeparators: [',', '.'],
  };
}

export function MoneyInput(props: MoneyInputProps): ReactElement {
  const {
    allowTextWrap,
    disabled,
    id,
    label,
    locale,
    name,
    onAnswerChange,
    optionalText,
    placeholder,
    required,
    subtitle,
    title,
    validationError,
    value: initialValue,
    boldedBorder,
  } = props;

  const localeOptions = getLocaleOptions(locale);

  const formatValue = useCallback(
    (value?: number) => {
      if (!value) return '';
      return locale === Language.fr ? String(value).replace(/\./, ',') : String(value);
    },
    [locale],
  );

  const [value, setValue] = useState<string>();

  useEffect(() => {
    setValue(formatValue(initialValue));
  }, [initialValue, formatValue]);

  const InputProps: Record<string, any> = {
    inputProps: { inputMode: 'decimal' },
    required,
  };

  if (locale === Language.fr) {
    InputProps.endAdornment = <InputAdornment position='end'>$</InputAdornment>;
  } else {
    InputProps.startAdornment = <InputAdornment position='start'>$</InputAdornment>;
  }

  const onBlur = useCallback(() => {
    if (onAnswerChange && typeof value !== 'undefined') {
      onAnswerChange(value);
    }
  }, [onAnswerChange, value]);

  const onValueChange = useCallback(
    (newValue: NumberFormatValues) => {
      if (newValue.value === value) return;
      setValue(newValue.formattedValue);
    },
    [value],
  );

  const labelValue = formatRequiredFieldTitle(required, title, label, optionalText);

  return (
    <NumericFormat
      id={id}
      data-testid={props['data-testid']}
      name={name}
      error={!!validationError}
      validationError={validationError}
      label={labelValue}
      placeholder={placeholder}
      customInput={Input}
      thousandSeparator={localeOptions.thousandSeparator}
      decimalSeparator={localeOptions.decimalSeparator}
      allowNegative={false}
      allowedDecimalSeparators={localeOptions.allowedDecimalSeparators}
      decimalScale={2}
      displayType='input'
      value={value}
      onValueChange={onValueChange}
      onBlur={onBlur}
      // TextField props
      inputVariant='outlined'
      InputProps={InputProps}
      subtitle={labelValue ? subtitle : formatRequiredFieldTitle(required, subtitle, '')}
      allowTextWrap={allowTextWrap}
      disabled={disabled}
      boldedBorder={boldedBorder}
    />
  );
}
