import _ from 'lodash';
import { SyntheticEvent, ReactElement, useCallback, useEffect, useState, Fragment } from 'react';

import { Box } from '@breathelife/mui';

import {
  DeepPartial,
  Language,
  SchedulingBestMomentType,
  SchedulingTimeFrames,
  SchedulingTimeFrame,
} from '@breathelife/types';

import {
  CheckboxCard,
  CheckboxCardContainer,
  Input,
  Label,
  LegalText,
  PhoneInput,
  RunningText,
  Text,
  WarningText,
} from '@breathelife/ui-components';

import { localizationStrings as defaultLocalizationStrings, LocalizationStrings } from './Localization';
import { CtaButton, StyledInputWrapper } from './Styles';
import { createScheduleCallModalSchema, getValidationError } from './Validation';
import { OnSubmitScheduleDataType, PreferredMethodsType } from './types';
import styled from 'styled-components';
import { ScheduleTimeTable } from '../ScheduleTimeTable/ScheduleTimeTable';

type Props = {
  schedulingTimeFrames: SchedulingTimeFrames;
  scheduleData: Partial<OnSubmitScheduleDataType>;
  language: Language;
  localizationStrings?: DeepPartial<LocalizationStrings>;
  isPlural: boolean;
  hideEmail?: boolean;
  hidePhone?: boolean;
  isSubmitting?: boolean;
  onSubmitContactForm: (onSubmitContactArgs: OnSubmitScheduleDataType) => void;
};

type StackProps = {
  direction?: 'horizontal' | 'vertical';
  size?: string;
};

const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: ${(props) => (props.direction === 'horizontal' ? 'row' : 'column')};

  > div + * {
    margin-left: ${(props) => (props.direction === 'horizontal' && props.size) ?? '16px'};
    margin-top: ${(props) => (props.direction !== 'horizontal' && props.size) ?? '16px'};
  }
`;

export enum StringGrammarNumber {
  single = 'single',
  plural = 'plural',
}

export function ContactForm(props: Props): ReactElement {
  const {
    schedulingTimeFrames,
    scheduleData,
    language,
    localizationStrings,
    isPlural,
    hideEmail,
    hidePhone,
    isSubmitting,
    onSubmitContactForm,
  } = props;

  const mergedLocalizationStrings = _.merge({}, defaultLocalizationStrings, localizationStrings || {});
  const textStrings = mergedLocalizationStrings[language];
  const noun = isPlural ? StringGrammarNumber.plural : StringGrammarNumber.single;

  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [bestMoments, setBestMoments] = useState<SchedulingBestMomentType>({});
  const [isEmailChecked, setIsEmailChecked] = useState(!!hidePhone);
  const [isPhoneChecked, setIsPhoneChecked] = useState(!!hideEmail);
  const [displaySubmissionError, setDisplaySubmissionError] = useState(false);

  const scheduleCallContactInfo = { phoneNumber, email, firstName, lastName };
  const scheduleCallModalSchema = createScheduleCallModalSchema({
    phoneErrorText: textStrings.validation.phone,
    emailErrorText: textStrings.validation.email,
    firstNameErrorText: textStrings.validation.firstName,
    lastNameErrorText: textStrings.validation.lastName,
  });

  const phoneNumberError = getValidationError(scheduleCallModalSchema, 'phoneNumber', scheduleCallContactInfo);
  const emailError = getValidationError(scheduleCallModalSchema, 'email', scheduleCallContactInfo);
  const firstNameError = getValidationError(scheduleCallModalSchema, 'firstName', scheduleCallContactInfo);
  const lastNameError = getValidationError(scheduleCallModalSchema, 'lastName', scheduleCallContactInfo);

  const isBestMomentsEmpty = _.isEmpty(_.flattenDeep(Object.values(bestMoments)));
  const isPhoneValid = isPhoneChecked ? !_.isEmpty(phoneNumber) && !phoneNumberError : true;
  const isEmailValid = isEmailChecked ? !_.isEmpty(email) && !emailError : true;
  const isFirstNameValid = !_.isEmpty(firstName) && !firstNameError;
  const isLastNameValid = !_.isEmpty(lastName) && !lastNameError;

  const isFormValid: boolean =
    isFirstNameValid &&
    isLastNameValid &&
    isPhoneValid &&
    isEmailValid &&
    (isEmailChecked || isPhoneChecked) &&
    (isPhoneChecked ? !isBestMomentsEmpty : true);
  const showContactMethodSelector = !props.hideEmail && !props.hidePhone;

  const onBestMomentChanged = useCallback(
    (isChecked: boolean, day: string, period: SchedulingTimeFrame) => {
      const newNewBestMoment = buildNewBestMoment(bestMoments, isChecked, day, period);
      setBestMoments(newNewBestMoment);
    },
    [bestMoments],
  );

  const onSubmit = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault();

      if (!isFormValid) {
        setDisplaySubmissionError(true);
        return;
      }

      const preferredMethods: Array<PreferredMethodsType> = [];
      isEmailChecked && preferredMethods.push('email');
      isPhoneChecked && preferredMethods.push('phone');

      onSubmitContactForm({
        bestMoments,
        preferredMethods,
        email,
        phoneNumber,
        firstName,
        lastName,
      });
    },
    [
      firstName,
      lastName,
      isFormValid,
      bestMoments,
      email,
      isEmailChecked,
      isPhoneChecked,
      onSubmitContactForm,
      phoneNumber,
    ],
  );

  useEffect(() => {
    scheduleData.firstName && setFirstName(scheduleData.firstName);
    scheduleData.lastName && setLastName(scheduleData.lastName);
    scheduleData.email && setEmail(scheduleData.email);
    scheduleData.phoneNumber && setPhoneNumber(scheduleData.phoneNumber);
    scheduleData.bestMoments && setBestMoments(scheduleData.bestMoments);
    scheduleData.preferredMethods && setIsEmailChecked(scheduleData.preferredMethods.includes('email'));
    scheduleData.preferredMethods && setIsPhoneChecked(scheduleData.preferredMethods.includes('phone'));
  }, [
    scheduleData.firstName,
    scheduleData.lastName,
    scheduleData.email,
    scheduleData.phoneNumber,
    scheduleData.preferredMethods,
    scheduleData.bestMoments,
  ]);

  return (
    <form onSubmit={onSubmit}>
      <Box mb={3}>
        <RunningText>{textStrings.subtitle[noun].callYou}</RunningText>
      </Box>
      <Stack>
        <StyledInputWrapper displaySubmissionError={!isFirstNameValid}>
          <Input
            name='fname'
            error={!!firstNameError}
            validationError={firstNameError ? { message: firstNameError } : undefined}
            inputVariant='outlined'
            label={textStrings.fields.firstName}
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            onBlur={() => setFirstName((prevValue) => prevValue.trim())}
          />
          {!isFirstNameValid && !firstNameError && (
            <Box component={WarningText} mt={2}>
              {textStrings.validation.required}
            </Box>
          )}
        </StyledInputWrapper>
        <StyledInputWrapper displaySubmissionError={!isLastNameValid}>
          <Input
            name='lname'
            error={!!lastNameError}
            validationError={lastNameError ? { message: lastNameError } : undefined}
            inputVariant='outlined'
            label={textStrings.fields.lastName}
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            onBlur={() => setLastName((prevValue) => prevValue.trim())}
          />
          {!isLastNameValid && !lastNameError && (
            <Box component={WarningText} mt={2}>
              {textStrings.validation.required}
            </Box>
          )}
        </StyledInputWrapper>
        {showContactMethodSelector && (
          <Fragment>
            <Label>{textStrings.contactMethod.preference}</Label>
            <Box mt={1}>
              <CheckboxCardContainer optionWidth={'half'}>
                <CheckboxCard
                  marginless
                  optionId='phone'
                  groupName='contact-method'
                  onChange={() => setIsPhoneChecked(!isPhoneChecked)}
                  checked={isPhoneChecked}
                  showErrorBorder={displaySubmissionError && !isPhoneChecked && !isEmailChecked}
                >
                  <Text>{textStrings.contactMethod.phone}</Text>
                </CheckboxCard>
                <CheckboxCard
                  marginless
                  optionId='email'
                  groupName='contact-method'
                  onChange={() => setIsEmailChecked(!isEmailChecked)}
                  checked={isEmailChecked}
                  showErrorBorder={displaySubmissionError && !isPhoneChecked && !isEmailChecked}
                >
                  <Text>{textStrings.contactMethod.email}</Text>
                </CheckboxCard>
              </CheckboxCardContainer>
              {displaySubmissionError && !isPhoneChecked && !isEmailChecked && (
                <Box component={WarningText} mt={2}>
                  {textStrings.validation.required}
                </Box>
              )}
            </Box>
          </Fragment>
        )}
        {isEmailChecked && (
          <StyledInputWrapper displaySubmissionError={!isEmailValid}>
            <Input
              value={email}
              type='email'
              inputVariant='outlined'
              error={!!emailError}
              validationError={emailError ? { message: emailError } : undefined}
              label={textStrings.fields.email}
              onChange={(event) => setEmail(event.target.value)}
              onBlur={() => setEmail((prevValue) => prevValue.trim())}
            />
            {!isEmailValid && !emailError && (
              <Box component={WarningText} mt={2}>
                {textStrings.validation.required}
              </Box>
            )}
          </StyledInputWrapper>
        )}
        {isPhoneChecked && (
          <Fragment>
            <StyledInputWrapper displaySubmissionError={displaySubmissionError && !isPhoneValid}>
              <PhoneInput
                value={phoneNumber}
                name='phoneNumber'
                label={textStrings.fields.phone}
                inputVariant='outlined'
                validationError={phoneNumberError ? { message: phoneNumberError } : undefined}
                placeholder={textStrings.fields.phonePlaceholder}
                onAnswerChange={(answer: any) => setPhoneNumber(answer)}
              />
              {displaySubmissionError && !isPhoneValid && !phoneNumberError && (
                <Box component={WarningText} mt={2}>
                  {textStrings.validation.required}
                </Box>
              )}
            </StyledInputWrapper>
            <Label>{textStrings.selectTimeSlots}</Label>
            <Box mt={1}>
              <ScheduleTimeTable
                language={language}
                bestMoments={bestMoments}
                onChange={(isChecked: boolean, dayOfTheWeekIndex: string, timeFrame: SchedulingTimeFrame) =>
                  onBestMomentChanged(isChecked, dayOfTheWeekIndex, timeFrame)
                }
                schedulingTimeFrames={schedulingTimeFrames}
              />
            </Box>{' '}
            {displaySubmissionError && isBestMomentsEmpty && (
              <Box component={WarningText} mt={2}>
                {textStrings.validation.bestMoments}
              </Box>
            )}
          </Fragment>
        )}
        <Box mt={5}>
          <CtaButton loading={isSubmitting} data-testid='submitSchedule' type='submit'>
            {textStrings.submit}
          </CtaButton>
        </Box>
        <Box mt={5}>
          <LegalText>{textStrings.legal}</LegalText>
        </Box>
      </Stack>
    </form>
  );
}

export function buildNewBestMoment(
  initialBestMoments: SchedulingBestMomentType,
  isChecked: boolean,
  dayOfTheWeekIndex: string,
  timeFrame: SchedulingTimeFrame,
): SchedulingBestMomentType {
  const copyOfBestMoments = _.cloneDeep(initialBestMoments);
  let timeFramesArray = copyOfBestMoments[dayOfTheWeekIndex] ?? [];
  if (isChecked) {
    if (!_.some(timeFramesArray, _.matches(timeFrame))) {
      timeFramesArray.push(timeFrame);
    }
  } else {
    timeFramesArray = timeFramesArray.filter((currentTimeFrame: number[]) => !_.isEqual(currentTimeFrame, timeFrame));
  }
  if (timeFramesArray.length <= 0) {
    delete copyOfBestMoments[dayOfTheWeekIndex];
  } else {
    copyOfBestMoments[dayOfTheWeekIndex] = timeFramesArray;
  }

  return copyOfBestMoments;
}
