import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import Typography from '../../../../Components/Typography';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';

type SubmitPaperAppModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function SubmitPaperAppModal(props: SubmitPaperAppModalProps): ReactElement {
  const { t } = useTranslation();
  const { isOpen, onClose, onConfirm } = props;

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onClose}
      title={t('modals.submitPaperApp.title')}
      submitButton={
        <SubmitButton data-testid='submit-paper-app-confirm' onClick={onConfirm}>
          {t('modals.submitPaperApp.submitButton')}
        </SubmitButton>
      }
    >
      <Box>
        <Typography variant='body1'>{t('modals.submitPaperApp.body')}</Typography>
      </Box>
    </ModalLayout>
  );
}
