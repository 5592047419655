import styled from 'styled-components';
import { FlexWrapper } from '../../Centered/CenteredLayoutHeader/Styles';
import { ProgressBar } from '../../../Components/ProgressBar/ProgressBar';

export const Container = styled.header`
  position: relative;
  width: 100%;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 9;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    position: static;
  }

  ${FlexWrapper}:first-child {
    * {
      margin-right: auto;
    }
  }
`;

export const ProgressLine = styled(ProgressBar)`
  position: absolute;
  left: 0;
  top: 0;
`;

export const ProgressText = styled.span`
  font-weight: 600;
  letter-spacing: 0.815px;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.base.primary};

  position: absolute;
  top: 72px;
  left: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    position: static;
    width: unset;
  }
`;
