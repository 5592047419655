import {
  CreateUserData,
  User,
  UserImportData,
  UserNotificationOptions,
  UserNotificationPreference,
  UserPage,
  UsersImportResponse,
} from '@breathelife/types';

import {
  convertObjectToQueryString,
  ConvertObjectToQueryStringOptions,
  CustomFieldMap,
} from '../../Services/helpers/query';
import ApiService from '../ApiService';

export type GetUsersFilterParams = {
  status?: string;
};

export type SortParams = { field: keyof User; direction: 'asc' | 'desc' };

export type GetUsersOptions = {
  limit?: number;
  filters?: GetUsersFilterParams;
  fields?: string[];
  search?: string;
  query?: string;
  page?: number;
  sort?: SortParams;
  includeAssociationCounts?: boolean;
};

// the ordering of fields matter
const customFieldMapping: CustomFieldMap = {
  fullName: ['firstName', 'lastName'],
};

export async function updateUserInformation(id: string, userData: Partial<CreateUserData>): Promise<User> {
  const { data } = await ApiService.admin.updateUser(id, userData);

  return data;
}

export async function updateUserNotificationPreferences(
  id: string,
  notificationData: UserNotificationOptions,
): Promise<UserNotificationPreference> {
  const response = await ApiService.updateUserNotificationPreferences(id, notificationData);
  return response.data || {};
}

export async function createUser(data: CreateUserData): Promise<User> {
  const response = await ApiService.admin.createUser(data);
  return response.data || {};
}

export async function bulkCreateUsers(users: UserImportData[]): Promise<UsersImportResponse> {
  const response = await ApiService.admin.bulkCreateUsers(users);
  return response.data || {};
}

export async function getUser(auth0Id: string): Promise<User> {
  const { data } = await ApiService.admin.getUser(auth0Id);
  return data;
}

export async function getUsers(options?: GetUsersOptions): Promise<UserPage> {
  let queryObject: ConvertObjectToQueryStringOptions = {};
  if (options) {
    const { sort, page, limit, filters, ...rest } = options;
    queryObject = { ...rest };
    if (sort) {
      queryObject['$sort'] = sort;
    }
    if (page) {
      queryObject['$page'] = page;
    }
    if (limit) {
      queryObject['$limit'] = limit.toString();
    }
    if (filters?.status) {
      queryObject['status'] = filters.status;
    }
  }

  const queryString = convertObjectToQueryString(queryObject ?? {}, customFieldMapping);
  const response = await ApiService.admin.getUsers(queryString);
  return response.data || {};
}

export async function sendAuth0InvitationEmail(userId: string): Promise<void> {
  await ApiService.admin.sendAuth0InvitationEmail(userId);
}

export async function deleteUser(userId: string): Promise<User> {
  const response = await ApiService.admin.deleteUser(userId);
  return response.data || {};
}

export default {
  createUser,
  getUser,
  getUsers,
  sendAuth0InvitationEmail,
  deleteUser,
  updateUserInformation,
  updateUserNotificationPreferences,
};
