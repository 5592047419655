import { Drawer as MUIDrawer, DrawerProps } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';

export const Drawer = styled(MUIDrawer)<DrawerProps & { width?: string }>`
  .paper {
    width: ${(props) => props.width ?? '620px'};

    @media (max-width: ${(props) => props.theme.moduleBreakpoints.md}) {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0;
  }
`;
