import _ from 'lodash';
import { ReactNode, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '../../../../Styles/themed-styled-components';

import { FieldGenerator, RenderContext, StyleVariant } from '@breathelife/field-generator';
import {
  getRepeatedQuestionIndex,
  isRenderingRepeatedQuestion,
  RenderingQuestion,
} from '@breathelife/questionnaire-engine';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { useCarrierContext, useLanguage } from '../../../../Hooks';
import { useAssistedApplicationContext } from '../../../../Hooks/useAssistedApplicationContext';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { useViewNextIncompleteFieldContext } from '../../AssistedApplicationView/ViewNextIncompleteFieldContext';

type FieldGroupCardModalProps = {
  isOpen: boolean;
  onCloseModal: () => void;
  secondaryAction?: ReactNode;
  question: RenderingQuestion;
};

const modalLayoutBodyStyling = css`
  overflow-y: auto;
  max-height: 500px;
`;

export function FieldGroupCardModal(props: FieldGroupCardModalProps): ReactElement {
  const { isOpen, onCloseModal, secondaryAction, question } = props;
  const { iconMap } = useCarrierContext();
  const language = useLanguage();
  const { t } = useTranslation();
  const { onAnswerChange, onAnswerComplete } = useAssistedApplicationContext();

  const { focusedIncompleteFieldId } = useViewNextIncompleteFieldContext();

  /**
   * We already display the question's title as the modal's title, hence we don't want to
   * display it again in the modal's content and can remove it
   */
  const fieldGeneratorProps = {
    context: RenderContext.web,
    styleVariant: StyleVariant.pro,
    iconMap,
    locale: language,
    question: _.omit(question, 'title'),
    onAnswerChange,
    onAnswerComplete,
    focusedIncompleteFieldId,
  };

  /**
   * /!\ IMPORTANT /!\
   * In order to get the repeatedInstanceIdentifierContext to account for repeated questions and multi-insured,
   * we want to handle the UI logic here instead of letting FieldGenerator do it.
   * The only issue with that is that we lose the repeatedQuestionIndex in the answer
   * complete callback added by FieldGenerator, hence the repeated question flag to manually add it.
   */

  fieldGeneratorProps.onAnswerChange = (items) => {
    const overrideAnswers = items.map((item) => ({
      ...item,
      repeatedIndices: question.metadata?.repeatedInstanceIdentifierContext,
    }));
    onAnswerChange(overrideAnswers);
  };

  if (isRenderingRepeatedQuestion(question)) {
    const repeatedQuestionIndex = getRepeatedQuestionIndex(question);

    fieldGeneratorProps.question = _.omit(fieldGeneratorProps.question, 'options.repeatable');
    fieldGeneratorProps.onAnswerComplete = (fieldId, answer, previousAnswer) =>
      onAnswerComplete(fieldId, answer, previousAnswer, repeatedQuestionIndex);
  }

  const modalLabelProps = question.title
    ? { title: question.title }
    : { ariaLabel: t('modals.assistedApplication.fieldCard.fallbackTitle', { id: question.id }) };

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onCloseModal}
      secondaryAction={secondaryAction}
      submitButton={
        <SubmitButton onClick={onCloseModal} data-testid={`${question.id}-save`}>
          {t('cta.save')}
        </SubmitButton>
      }
      bodyStyling={modalLayoutBodyStyling}
      {...modalLabelProps}
    >
      <FieldGenerator {...fieldGeneratorProps} />
    </ModalLayout>
  );
}
