import { Box, Skeleton } from '@breathelife/mui';
import { ReactElement, useEffect, useCallback, Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { Permission } from '@breathelife/types';
import { Loader } from '@breathelife/ui-components';

import styled from '../Styles/themed-styled-components';
import { Sidebar } from '../Components/Sidebar/Sidebar';
import { MobileMenu } from '../Components/MobileMenu/MobileMenu';
import { useCarrierContext, useDispatch, useSelector } from '../Hooks';
import { useCreateAgentLicenseMutation } from '../ReactQuery/AgentLicense/agentLicense.mutations';
import { fetchPublicSettings } from '../ReduxStore/Admin/SettingsManagement/SettingsOperations';
import { useFetchDefaultThemeQuery } from '../ReactQuery/Themes/themes.queries';
import { Logo } from '../Components/Sidebar/Styles';
import { useTranslation } from 'react-i18next';

type Props = {
  displayContentFullScreen?: boolean;
};

const Container = styled(Box)`
  @media (max-width: ${(props) => props.theme.moduleBreakpoints.md}) {
    padding-bottom: 10px;
  }
`;

const Content = styled(Box)`
  @media (max-width: ${(props) => props.theme.moduleBreakpoints.md}) {
    width: 100% !important;
    margin: 0 !important;
  }
`;

export function PrivateRootApplication(props: Props): ReactElement {
  const { displayContentFullScreen } = props;

  const dispatch = useDispatch();
  const { carrierInfo, features } = useCarrierContext();
  const { t } = useTranslation();

  const theme = useSelector((state) => state.leadPlatform.theme);
  const authentication = useSelector((store) => store.leadPlatform.authentication);

  const { mutate } = useCreateAgentLicenseMutation();

  const isMultiBrandingEnabled = features.multiBranding.enabled;
  const isMga = isMultiBrandingEnabled && !carrierInfo.isDefaultTheme;
  const { data: defaultTheme } = useFetchDefaultThemeQuery({
    enabled: isMga,
  });

  const themeLogo = (
    <Box mb={4.5}>
      <Logo
        src={carrierInfo.logoCompact}
        radius={0}
        alt={`${carrierInfo.companyName} ${t('sidebar.logo')}`}
        isProfile={false}
      />
    </Box>
  );
  const mgaLogos = defaultTheme ? (
    <Fragment>
      <Box mb={2}>
        <Logo
          src={defaultTheme.compactLogoImgUrl}
          radius={0}
          alt={`${carrierInfo.companyName} ${t('sidebar.logo')}`}
          isProfile={false}
        />
      </Box>
      {/* Show mga themed logo only if its different from the default theme logo*/}
      {defaultTheme.compactLogoImgUrl !== carrierInfo.logoCompact && themeLogo}
    </Fragment>
  ) : (
    <Skeleton width='50px' height='80px' />
  );

  const logo = isMga ? mgaLogos : themeLogo;

  const fetchAgentLicenses = useCallback(async (): Promise<void> => {
    if (authentication.user && authentication.user.permissions?.includes(Permission.AgentLicenseCreate)) {
      mutate(authentication.user);
    }
  }, [authentication.user, mutate]);

  useEffect(() => {
    void dispatch(fetchPublicSettings());
  }, [dispatch]);

  useEffect(() => {
    if (features.agentLicenseValidation?.enabled) {
      void fetchAgentLicenses();
    }
  }, [fetchAgentLicenses, features.agentLicenseValidation]);

  if (!theme) return <Loader color='#000000' />;

  return (
    <Container width='100%'>
      {!displayContentFullScreen && (
        /* Using a position fixed for the sidebar with a margin left on the page layout because having
        a table inside a `display: flex;` is not working well. The table is rendered wider than the parent even if
        the parent of the table is a div with a `flex: 1;` */
        <Box height='100vh' position='fixed' top='0' left='0' p={2}>
          <Sidebar logo={logo} />
        </Box>
      )}

      <MobileMenu logo={logo} />
      <Content ml={displayContentFullScreen ? 0 : 13} mr={displayContentFullScreen ? 0 : 2}>
        <Outlet />
      </Content>
    </Container>
  );
}
