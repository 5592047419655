import { Box } from '@breathelife/mui';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ChangeEvent, ReactElement, Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonName, TypewriterTracking } from '@breathelife/frontend-tracking';
import { Input as TextInput } from '@breathelife/ui-components';

import Typography from '../../../../../Components/Typography';
import { getLanguage } from '../../../../../Localization/utils';
import { Lead } from '../../../../../Models/Lead';
import { LeadNote } from '../../../../../Models/LeadsNote';
import { useUpdateLeadNoteMutation } from '../../../../../ReactQuery/Lead/lead.mutations';
import { ContentHeading, CtaButton, DateTextContainer } from '../Styles';

type NotesViewProps = {
  lead?: Lead;
};

dayjs.extend(relativeTime);

export function NotesView({ lead }: NotesViewProps): ReactElement {
  const { t } = useTranslation();
  const leadNotes = lead?.['leads-notes'];
  const [isNoteChanged, setIsNoteChanged] = useState(false);
  const [note, setNote] = useState('');
  const [date, setDate] = useState('');
  const [currentNote, setCurrentNote] = useState<Partial<LeadNote>>({ note: '' });
  const language = getLanguage();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setNote(value);
    },
    [setNote],
  );

  useEffect(() => {
    setIsNoteChanged(note !== (currentNote.note || ''));
  }, [note, currentNote]);

  useEffect(() => {
    const leadNote = leadNotes && leadNotes[0];
    const date = leadNote?.updatedAt || Date.now();
    const formattedDate = dayjs(new Date(date)).locale(language).fromNow();

    setDate(formattedDate);
    setNote(leadNote?.note || '');
    if (leadNote) setCurrentNote(leadNote);
  }, [leadNotes, language]);

  const updateLeadNoteMutation = useUpdateLeadNoteMutation({
    onMutate: () => {
      TypewriterTracking.clickedButton({ buttonName: ButtonName.leadNoteSave, hashedId: null });
    },
  });

  function onSaveClick(): void {
    if (!isNoteChanged || !lead) return;
    updateLeadNoteMutation.mutate({ leadId: lead.id, note });
  }

  return (
    <Fragment>
      <ContentHeading py={2} px={2.5}>
        <DateTextContainer>
          <Typography variant='body3' grey={70}>
            {currentNote.updatedAt ? `${t('lastUpdated')}: ${date}` : t('new')}
          </Typography>
        </DateTextContainer>

        <CtaButton
          variant='contained'
          color='primary'
          size='small'
          disabled={!isNoteChanged || !lead}
          onClick={onSaveClick}
        >
          {t('cta.save')}
        </CtaButton>
      </ContentHeading>

      <Box p={3}>
        <TextInput
          label={t('notes')}
          multiline
          defaultValue={currentNote.note}
          inputVariant='outlined'
          fullWidth
          onChange={handleChange}
        />
      </Box>
    </Fragment>
  );
}
