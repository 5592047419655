import _ from 'lodash';
import queryString from 'query-string';
import { Dispatch } from 'redux';

import { hash } from '@breathelife/hash';
import { TypewriterTracking } from '@breathelife/frontend-tracking';

import { shortLocale } from '../../Localization/Localizer';
import { AccessTokenMethod } from '../../Models/AccessTokenMethod';
import * as ApplicationService from '../../Services/ApplicationService';
import { getLeadCommunication, updateLeadCommunication } from '../../Services/LeadsService';
import * as InsuranceApplicationOperations from '../InsuranceApplication/InsuranceApplicationOperations';
import { notificationSlice } from '../Notification/NotificationSlice';
import { ConsumerFlowStore } from '../Store';
import { communicationSlice } from './CommunicationSlice';
import {
  getApiFormattedScheduleCallPreferences,
  getFormattedPreferenceFromApiScheduleCall,
} from './ScheduleCallPreferencesConverter';
import { OnSubmitScheduleDataType } from '../../Components/ContactForm';

const communicationActions = communicationSlice.actions;
const notificationActions = notificationSlice.actions;

export const getCommunicationPreferences = (applicationId: string) => async (dispatch: Dispatch) => {
  dispatch(communicationActions.setIsLoading(true));

  try {
    const leadCommunicationResponse = await getLeadCommunication(applicationId);
    if (leadCommunicationResponse.email || leadCommunicationResponse.phoneNumber) {
      dispatch(
        communicationActions.setUserInfo({
          firstName: leadCommunicationResponse.firstName,
          lastName: leadCommunicationResponse.lastName,
          email: leadCommunicationResponse.email,
          phoneNumber: leadCommunicationResponse.phoneNumber,
        }),
      );
    }

    if (!_.isEmpty(leadCommunicationResponse['leads-communication-schedules'])) {
      const preferences = getFormattedPreferenceFromApiScheduleCall(leadCommunicationResponse);
      dispatch(communicationActions.setCommunicationPreferences(preferences));
    }
  } catch (error: any) {
    dispatch(notificationActions.setError({ message: error.response?.data?.message }));
  }

  return dispatch(communicationActions.setIsLoading(false));
};

export const setCommunicationPreferences =
  (appId: string, data: OnSubmitScheduleDataType) => async (dispatch: Dispatch, store: () => ConsumerFlowStore) => {
    // backend validation fails with an empty string
    // @ts-ignore the operand of a 'delete' operator must be optional (due to strictNullChecks config)
    if (data.email === '') delete data.email;
    dispatch(communicationActions.setIsLoading(true));

    const state = store();
    const stepId = state.consumerFlow.step.currentStep?.id;
    const questionId = state.consumerFlow.step.currentStep?.id;
    let applicationId = appId;

    const apiPreferences = getApiFormattedScheduleCallPreferences(data);

    try {
      if (!applicationId) {
        const { token, method } = queryString.parse(location.search) as Record<string, string | undefined>;
        const isPublicLink = method === AccessTokenMethod.publicLink;

        if (isPublicLink) {
          const applicationResponse = (await ApplicationService.createApplication({
            lang: shortLocale(),
            accessToken: token,
          })) as ApplicationService.QuestionResponse;

          applicationId = applicationResponse.application.id;
          await InsuranceApplicationOperations.dispatchCreatedApplication(applicationResponse)(dispatch);
        }
      }
      await updateLeadCommunication(applicationId, { ...apiPreferences });

      const { bestMoments, preferredMethods, firstName, lastName, email, phoneNumber } = data;
      dispatch(communicationActions.setCommunicationPreferences({ bestMoments, preferredMethods }));
      dispatch(communicationActions.setUserInfo({ firstName, lastName, email, phoneNumber }));

      TypewriterTracking.scheduledACall({
        hashedId: hash(applicationId),
        stepId: questionId ?? stepId ?? '',
      });
    } catch (e: any) {
      dispatch(notificationActions.setError({ message: e.response?.data.message }));
      dispatch(communicationActions.setIsLoading(false));
      throw e;
    }

    return dispatch(communicationActions.setIsLoading(false));
  };
