import _ from 'lodash';
import { ReactElement, Component } from 'react';
import { PatternFormat } from 'react-number-format';

import { StandardTextFieldProps } from '@breathelife/mui';
import { Language } from '@breathelife/types';

import { FieldProps, formatRequiredFieldTitle, InputVariant } from './Helpers/FieldHelper';
import { Input } from './TextInput';

export type DateMaskMap = Record<Language, string>;
export type BaseMaskedDateFieldProps = Omit<FieldProps, 'onAnswerChange'> & {
  value?: string;
  onAnswerChange?: (answer: string) => void;
  onAnswerComplete?: (fieldId: string, answer: string | number, previousAnswer?: string | number) => void;
  inputVariant?: InputVariant;
  locale?: Language;
  required?: boolean;
  placeholder?: string;
  boldedBorder?: boolean;
};

type Props = StandardTextFieldProps & BaseMaskedDateFieldProps;

type State = {
  value?: string;
  valueOnPreviousBlur?: string;
};
export class MaskedDateInputField extends Component<Props, State> {
  state = {
    value: this.props.value,
    valueOnPreviousBlur: this.props.value,
  };

  debouncedParentOnAnswerChange = (): void => {
    this.props.onAnswerChange ? _.debounce(this.props.onAnswerChange, 200) : () => null;
  };

  onAnswerChange = (answer: string): void => {
    this.setState({ value: answer }, () => {
      this.debouncedParentOnAnswerChange();
    });
  };

  onBlur = (): void => {
    if (this.props.onAnswerChange && typeof this.state.value !== 'undefined') {
      this.props.onAnswerChange(this.state.value);
    }
    if (this.props.onAnswerComplete && typeof this.state.value !== 'undefined') {
      this.props.onAnswerComplete(this.props.name, this.state.value, this.state.valueOnPreviousBlur);
    }
    this.setState({ valueOnPreviousBlur: this.state.value });
  };

  render(): ReactElement {
    const {
      disabled,
      inputVariant,
      label,
      locale: localeProps,
      name,
      optionalText,
      required,
      subtitle,
      title,
      validationError,
      boldedBorder,
    } = this.props;
    const locale = localeProps ?? Language.en;
    const labelValue = formatRequiredFieldTitle(required, title, label, optionalText);
    const localeDatePlaceholderMap = {
      [Language.en]: 'YYYY-MM',
      [Language.fr]: 'AAAA-MM',
    };

    const customProps = {
      'data-testid': this.props['data-testid'],
      name,
      error: !!validationError,
      label: labelValue,
      subtitle: labelValue ? subtitle : formatRequiredFieldTitle(required, subtitle, ''),
      placeholder: localeDatePlaceholderMap[locale],
      validationError: validationError,
      inputVariant,
      disabled,
      required,
      boldedBorder,
    };

    return (
      <PatternFormat
        {...customProps}
        format='####-##'
        mask='_'
        value={this.state.value}
        onBlur={this.onBlur}
        onValueChange={(values) => {
          this.onAnswerChange(values.formattedValue);
        }}
        type='text'
        customInput={Input}
      />
    );
  }
}
