import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { PropsWithChildren, ReactElement, createContext, useCallback, useMemo, useState } from 'react';

import { ScreenName, TypewriterTracking } from '@breathelife/frontend-tracking';
import { Permission } from '@breathelife/types';
import { ImageTypes } from '@breathelife/ui-components';
import { ModalType } from '../Models/Layout';
import { layoutSlice } from '../ReduxStore/Layout/LayoutSlice';
import { MenuElement } from '../Components/PopupMenu/PopupMenu';
import { useCarrierContext, useDispatch, useSelector } from '../Hooks';
import { logout as reduxLogout } from '../ReduxStore/Authentication/AuthenticationOperations';
import { publicLinkSettingsEnabled } from '../ReduxStore/Admin/SettingsManagement/SettingsSelectors';
import { Icon } from '../Components/Icons';

type UserMenuContextValue = {
  items: MenuElement[];
  setIsSharePublicLinkModalOpen: (isOpen: boolean) => void;
  setIsAgentLicensesModalOpen: (isOpen: boolean) => void;
  setIsNotificationPreferencesModalOpen: (isOpen: boolean) => void;
  isSharePublicLinkModalOpen: boolean;
  isNotificationPreferencesModalOpen: boolean;
  isAgentLicensesModalOpen: boolean;
  openUserEditModal: () => void;
};

export const UserMenuContext = createContext<UserMenuContextValue>({
  items: [],
  setIsSharePublicLinkModalOpen: () => {},
  setIsAgentLicensesModalOpen: () => {},
  setIsNotificationPreferencesModalOpen: () => {},
  isSharePublicLinkModalOpen: false,
  isNotificationPreferencesModalOpen: false,
  isAgentLicensesModalOpen: false,
  openUserEditModal: () => {},
});

export function UserMenuContextProvider(props: PropsWithChildren<unknown>): ReactElement {
  const [isSharePublicLinkModalOpen, setIsSharePublicLinkModalOpen] = useState(false);
  const [isNotificationPreferencesModalOpen, setIsNotificationPreferencesModalOpen] = useState(false);
  const [isAgentLicensesModalOpen, setIsAgentLicensesModalOpen] = useState(false);

  const { externalResources, features } = useCarrierContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const arePublicLinkSettingsEnabled = useSelector(publicLinkSettingsEnabled);
  const user = useSelector((store) => store.leadPlatform.authentication.user);

  const logout = useCallback(() => dispatch(reduxLogout()), [dispatch]);

  const openDownloadFilesModal = useCallback(() => {
    dispatch(
      layoutSlice.actions.setModalState({
        modalState: { isOpen: true, type: ModalType.downloadFiles },
      }),
    );
  }, [dispatch]);

  const openAgentLicensesModal = useCallback(() => {
    setIsAgentLicensesModalOpen(true);
  }, []);

  const openPublicLinkModal = useCallback(() => {
    TypewriterTracking.viewedScreen({
      screenName: ScreenName.sharePublicLink,
      hashedId: null,
    });

    setIsSharePublicLinkModalOpen(true);
  }, []);

  const openNotificationsModal = useCallback(() => {
    TypewriterTracking.viewedScreen({
      screenName: ScreenName.notificationPreferences,
      hashedId: null,
    });

    setIsNotificationPreferencesModalOpen(true);
  }, []);

  const openUserEditModal = useCallback(() => {
    TypewriterTracking.viewedScreen({ screenName: ScreenName.userProfile, hashedId: null });
    dispatch(
      layoutSlice.actions.setModalState({
        modalState: { isOpen: true, type: ModalType.userEdit },
      }),
    );
    setIsSharePublicLinkModalOpen(false);
  }, [dispatch]);

  const headerMenuActions = externalResources?.headerMenuResources?.map((externalResource): MenuElement => {
    return {
      tag: 'MenuAction',
      onClick: _.noop,
      icon: externalResource.icon,
      label: externalResource.title,
      linkProperties: {
        href: externalResource.link,
        target: '_blank',
        rel: 'noopener no,referrer',
      },
    };
  });

  const downloadableFiles = externalResources?.downloadableFiles;

  const items: MenuElement[] = useMemo(() => {
    const item: MenuElement[] = [];
    if (_.includes(user?.permissions, Permission.UserEditSelf)) {
      item.push({
        tag: 'MenuAction',
        onClick: () => openUserEditModal(),
        icon: <Icon name='profile' />,
        label: t('userMenu.myProfile'),
        'data-testid': 'my-profile-menu-item',
      });
    }
    if (
      _.includes(user?.permissions, Permission.LeadInvitePublic) &&
      (!features.loadPublicLinkMetadataFromDb?.enabled || arePublicLinkSettingsEnabled)
    ) {
      item.push({
        tag: 'MenuAction',
        onClick: () => openPublicLinkModal(),
        icon: <Icon name='link' />,
        label: t('userMenu.shareMyPublicLink'),
        'data-testid': 'openPublicLinkModal',
      });
    }
    if (features.userNotificationEmail.enabled) {
      item.push({
        tag: 'MenuAction',
        onClick: () => openNotificationsModal(),
        icon: <Icon name='email' />,
        label: t('userMenu.notifications'),
      });
    }
    if (downloadableFiles) {
      item.push({
        tag: 'MenuAction',
        onClick: openDownloadFilesModal,
        icon: <Icon name='files' />,
        label: t('userMenu.filesAndDocuments'),
        'data-testid': 'openDownloadFilesModal',
      });
    }
    if (headerMenuActions) {
      item.push(...headerMenuActions);
    }
    if (features.agentLicenseValidation?.enabled) {
      item.push({
        tag: 'MenuAction',
        onClick: openAgentLicensesModal,
        icon: <Icon name='licensesIcon' imageType={ImageTypes.decorative} />,
        label: t('userMenu.agentLicenses'),
        'data-testid': 'openAgentLicensesModal',
      });
    }
    item.push({
      tag: 'MenuAction',
      onClick: () => logout(),
      icon: <Icon name='logout' />,
      label: t('userMenu.logout'),
      'data-testid': 'logout-menu-item',
    });
    return item;
  }, [
    t,
    openUserEditModal,
    user,
    logout,
    openPublicLinkModal,
    openDownloadFilesModal,
    openNotificationsModal,
    headerMenuActions,
    downloadableFiles,
    features.userNotificationEmail.enabled,
    features.loadPublicLinkMetadataFromDb?.enabled,
    arePublicLinkSettingsEnabled,
  ]);

  return (
    <UserMenuContext.Provider
      value={{
        items,
        setIsSharePublicLinkModalOpen,
        setIsAgentLicensesModalOpen,
        setIsNotificationPreferencesModalOpen,
        isSharePublicLinkModalOpen,
        isNotificationPreferencesModalOpen,
        isAgentLicensesModalOpen,
        openUserEditModal,
      }}
    >
      {props.children}
    </UserMenuContext.Provider>
  );
}
