import styled from '../../../Styles/themed-styled-components';

import { tableHeight } from '../../../Models/Layout';

export const tableStyles = {
  boxShadow: 'none',
  padding: '20px',
  paddingTop: 0,
};

// This is far from perfect, but this is to be able to target some html tag in the Material table component.
// It would be better to have our own table to have more control on this.
// > div  includes the table header, body and footer
// > div > div includes the table header and body
// > div > div > div is just an extra div added by Material table with overflow-x: auto
// > div > div > div > div is to target the div before the table to limit his height so we don't have to scroll the page.
export const MaterialTableContainer = styled.div`
  &&&&& {
    > div:first-child {
      padding: 0 20px !important;
      border-radius: 0px;

      @media (max-width: ${(props) => props.theme.moduleBreakpoints.md}) {
        padding: 0 !important;
      }

      > div > div > div {
        height: ${() => `calc(100vh - ${tableHeight}px)`};
      }
    }
  }

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.md}) {
    padding: 10px !important;
  }
`;
