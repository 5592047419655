import { IncompleteFieldIdentifier } from '@breathelife/questionnaire-engine';
import { createContext, PropsWithChildren, ReactElement, useCallback, useContext, useState } from 'react';

import {
  getSectionGroupIdAndIndexFromSectionGroupWithIndicesId,
  getSectionGroupWithIndicesId,
  getValuesFromSectionIdsToVisibleSectionIndicesMapKey,
  makeSectionIdsToVisibleSectionIndicesMapKey,
  SectionGroupIndices,
  SectionIdsToVisibleSectionIndicesMap,
} from '../../../Helpers/assistedApplication/sectionGroups';
import { useAssistedApplicationContext } from '../../../Hooks';
import { SectionGroupId } from '../../../Helpers/questionnaireAnswers';

function getActiveSectionId(
  activeSectionIndex: number,
  activeSectionGroupId: string,
  sectionIdsToVisibleSectionIndicesMap: SectionIdsToVisibleSectionIndicesMap,
): string | undefined {
  const activeSectionIdKey = Object.entries(sectionIdsToVisibleSectionIndicesMap).find(
    ([sectionIdsToVisibleSectionIndicesMapKey, sectionIndex]) => {
      const { sectionGroupIdWithRepeatableIndex } = getValuesFromSectionIdsToVisibleSectionIndicesMapKey(
        sectionIdsToVisibleSectionIndicesMapKey,
      );

      return sectionIndex === activeSectionIndex && sectionGroupIdWithRepeatableIndex === activeSectionGroupId;
    },
  )?.[0];
  const { sectionId } = getValuesFromSectionIdsToVisibleSectionIndicesMapKey(activeSectionIdKey);
  return sectionId;
}

type ViewNextIncompleteFieldContextValue = {
  focusedIncompleteFieldId: string | undefined;
  onClickViewNextIncompleteFieldButton: () => void;
  applicationHasIncompleteFields: boolean;
};

const ViewNextIncompleteFieldContext = createContext<ViewNextIncompleteFieldContextValue>({
  focusedIncompleteFieldId: undefined,
  onClickViewNextIncompleteFieldButton: () => {},
  applicationHasIncompleteFields: false,
});

export function ViewNextIncompleteFieldContextProvider(
  props: PropsWithChildren<{
    incompleteFieldIdentifiers: IncompleteFieldIdentifier[];
    visitedSectionIndicesByGroup: SectionGroupIndices[];
    sectionIdsToVisibleSectionIndicesMap: SectionIdsToVisibleSectionIndicesMap;
  }>,
): ReactElement {
  const { incompleteFieldIdentifiers, sectionIdsToVisibleSectionIndicesMap } = props;
  const {
    onSelectProposedInsured,
    onSwitchSectionGroupTab,
    onSetActiveSectionIndex,
    currentSectionGroupId,
    currentProposedInsuredIndex,
    activeSectionIndex,
  } = useAssistedApplicationContext();

  const [focusedIncompleteFieldId, setFocusedIncompleteFieldId] = useState<string | undefined>(undefined);
  const [activeIncompleteField, setActiveIncompleteField] = useState<IncompleteFieldIdentifier | undefined>(undefined);

  const onClickViewNextIncompleteFieldButton = useCallback(() => {
    if (!incompleteFieldIdentifiers.length) {
      return;
    }
    // Make active section group Id with index (insuredPeople.1)
    const activeSectionGroupId = getSectionGroupWithIndicesId(
      currentSectionGroupId,
      currentProposedInsuredIndex.toString(),
    );

    // Find activeSectionId
    const activeSectionId = getActiveSectionId(
      activeSectionIndex,
      activeSectionGroupId,
      sectionIdsToVisibleSectionIndicesMap,
    );

    let setNextIncompleteFieldAfterCurrent: boolean = false;
    let newActiveIncompleteField: IncompleteFieldIdentifier | undefined;
    let firstIncompleteFieldOfActiveSectionGroup: IncompleteFieldIdentifier | undefined;
    let firstIncompleteFieldOfActiveSection: IncompleteFieldIdentifier | undefined;

    // If there is an active incomplete field in the section been displayed, set the next incomplete field as active
    if (
      activeIncompleteField &&
      activeIncompleteField.sectionGroupId === activeSectionGroupId &&
      activeIncompleteField.sectionId === activeSectionId
    ) {
      setNextIncompleteFieldAfterCurrent = true;
    }
    for (const [i, incompleteField] of incompleteFieldIdentifiers.entries()) {
      if (
        setNextIncompleteFieldAfterCurrent &&
        incompleteField.fieldId === activeIncompleteField?.fieldId &&
        incompleteField.sectionGroupId === activeIncompleteField.sectionGroupId
      ) {
        // If the current incomplete field is in the section been displayed, select the next incomplete field)
        const indexOfNextIncompleteField = i + 1 < incompleteFieldIdentifiers.length ? i + 1 : 0;
        newActiveIncompleteField = incompleteFieldIdentifiers[indexOfNextIncompleteField];
      } else if (!setNextIncompleteFieldAfterCurrent) {
        // If not, find the first incomplete field in the active section and in the active sectionGroup
        if (!firstIncompleteFieldOfActiveSectionGroup && incompleteField.sectionGroupId === activeSectionGroupId) {
          firstIncompleteFieldOfActiveSectionGroup = incompleteField;
        }
        if (
          !firstIncompleteFieldOfActiveSection &&
          incompleteField.sectionId === activeSectionId &&
          incompleteField.sectionGroupId === activeSectionGroupId
        ) {
          firstIncompleteFieldOfActiveSection = incompleteField;
        }
      }
    }

    // If there is an incomplete field in the active section, select it
    if (!newActiveIncompleteField && firstIncompleteFieldOfActiveSection) {
      newActiveIncompleteField = firstIncompleteFieldOfActiveSection;
    }

    // Or, if there is an incomplete field in the active sectionGroup, select it
    if (!newActiveIncompleteField && firstIncompleteFieldOfActiveSectionGroup) {
      newActiveIncompleteField = firstIncompleteFieldOfActiveSectionGroup;
    }

    // Else, select the first incomplete field
    if (!newActiveIncompleteField) {
      newActiveIncompleteField = incompleteFieldIdentifiers[0];
    }

    setActiveIncompleteField(newActiveIncompleteField);

    if (newActiveIncompleteField) {
      // Switch to the correct sectionGroup tab
      const { sectionGroupId, sectionGroupIndex } = getSectionGroupIdAndIndexFromSectionGroupWithIndicesId(
        newActiveIncompleteField.sectionGroupId,
      );

      onSwitchSectionGroupTab(sectionGroupId as SectionGroupId);
      if (sectionGroupIndex || sectionGroupIndex === 0) {
        onSelectProposedInsured(sectionGroupIndex);
      }

      // Switch to the correct section
      const activeSectionIndex =
        sectionIdsToVisibleSectionIndicesMap[
          makeSectionIdsToVisibleSectionIndicesMapKey(
            newActiveIncompleteField.sectionId,
            newActiveIncompleteField.sectionGroupId,
          )
        ];

      onSetActiveSectionIndex(activeSectionIndex);

      // Enable the auto-scroll
      setFocusedIncompleteFieldId(newActiveIncompleteField?.fieldId);
    }
  }, [
    activeIncompleteField,
    activeSectionIndex,
    currentSectionGroupId,
    incompleteFieldIdentifiers,
    onSetActiveSectionIndex,
    sectionIdsToVisibleSectionIndicesMap,
    onSelectProposedInsured,
    onSwitchSectionGroupTab,
    currentProposedInsuredIndex,
  ]);

  return (
    <ViewNextIncompleteFieldContext.Provider
      value={{
        focusedIncompleteFieldId,
        onClickViewNextIncompleteFieldButton,
        applicationHasIncompleteFields: !!incompleteFieldIdentifiers.length,
      }}
    >
      {props.children}
    </ViewNextIncompleteFieldContext.Provider>
  );
}

export function useViewNextIncompleteFieldContext(): ViewNextIncompleteFieldContextValue {
  return useContext(ViewNextIncompleteFieldContext);
}
