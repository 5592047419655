import { evaluateConditions, evaluateQuery } from '@breathelife/condition-engine';
import { OperatorResult, InstanceScope, Answers, Timezone, IAnswerResolver } from '@breathelife/types';

import { DefaultIfRule, DefaultValue, isDefaultValueQuery } from './defaultIfRule';

export function evaluateDefaultIfRules(
  rules: DefaultIfRule[],
  answers: Answers,
  answersResolver: IAnswerResolver,
  repeatedInstanceIdentifiers: InstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): DefaultIfRule | undefined {
  const firstConditionToPass = rules.find((rule) => {
    if (rule.conditions === null) return true;

    return evaluateConditions(
      rule.conditions,
      answers,
      answersResolver,
      repeatedInstanceIdentifiers,
      timezone,
      currentDateOverride,
    ).isValid;
  });

  return firstConditionToPass;
}

export function evaluateDefaultValueParams(
  defaultValue: DefaultValue,
  answers: Answers,
  answersResolver: IAnswerResolver,
  repeatedInstanceIdentifiers: InstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): OperatorResult | DefaultValue {
  if (isDefaultValueQuery(defaultValue)) {
    return evaluateQuery(
      defaultValue.fromQuery,
      answers,
      answersResolver,
      repeatedInstanceIdentifiers,
      timezone,
      {},
      currentDateOverride,
    );
  }

  return defaultValue;
}

export function getConditionalDefaultValueForField(
  rules: DefaultIfRule[],
  answers: Answers,
  answersResolver: IAnswerResolver,
  repeatedInstanceIdentifiers: InstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): OperatorResult | DefaultValue | undefined {
  const defaultIfCondition = evaluateDefaultIfRules(
    rules,
    answers,
    answersResolver,
    repeatedInstanceIdentifiers,
    timezone,
    currentDateOverride,
  );

  if (!defaultIfCondition) return;

  const defaultFieldValue = evaluateDefaultValueParams(
    defaultIfCondition.defaultValue,
    answers,
    answersResolver,
    repeatedInstanceIdentifiers,
    timezone,
    currentDateOverride,
  );

  return defaultFieldValue;
}
