import ReactHtmlParser from 'html-react-parser';
import { MouseEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { Box, ClickAwayListener, Fade, List, ListItem, ListItemText } from '@breathelife/mui';

import { getNonEmptyLines, isMultiLine } from '../../helpers/info';
import { BaseModal } from '../BaseModal/BaseModal';
import { Popper, PopperDefaultArrow } from '../Popper/Popper';
import { RunningText } from '../Typography';
import { withResponsive } from '../withResponsive';
import { BareButton, Body, Container, Header, IconButton, Image, Modal, ModalTitle, Pill, PillText } from './Styles';

function DefaultIcon(): ReactElement {
  return (
    <svg width='2' height='9' viewBox='0 0 2 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.00008 3C1.32225 3 1.58341 3.27802 1.58341 3.62097V7.87903C1.58341 8.22198 1.32225 8.5 1.00008 8.5C0.677915 8.5 0.416748 8.22198 0.416748 7.87903V3.62097C0.416748 3.27802 0.677915 3 1.00008 3Z'
        fill='white'
      />
      <path d='M1 0C0.448 0 0 0.448 0 1C0 1.552 0.448 2 1 2C1.552 2 2 1.552 2 1C2 0.448 1.552 0 1 0Z' fill='white' />
    </svg>
  );
}

function DefaultCloseSvgData(): ReactElement {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.22207 7.00079L0.707072 2.48578C0.208229 1.98694 0.208228 1.17816 0.707072 0.679312C1.20592 0.180469 2.0147 0.180469 2.51354 0.679313L7.02855 5.19432L11.5465 0.676375C12.0453 0.177531 12.8541 0.177531 13.353 0.676374C13.8518 1.17522 13.8518 1.984 13.353 2.48285L8.83502 7.00079L13.3524 11.5182C13.8512 12.017 13.8512 12.8258 13.3524 13.3246C12.8535 13.8235 12.0447 13.8235 11.5459 13.3246L7.02855 8.80726L2.51412 13.3217C2.01528 13.8205 1.20649 13.8205 0.70765 13.3217C0.208807 12.8228 0.208807 12.0141 0.707651 11.5152L5.22207 7.00079Z'
        fill='#757575'
      />
    </svg>
  );
}

type Props = {
  title?: string;
  text: string;
  image?: {
    src: string;
    alt?: string;
  };
  closeButtonText?: string;
  isMobile?: boolean;
  modalOptions?: {
    fullSizeOnMobile?: boolean;
    forceModal?: boolean;
  };
  iconNumber?: number;
  usePill?: boolean;
  onClick?: () => void;
  attachToParent?: boolean;
  boldedBorder?: boolean;
};

const modalWidths = {
  full: '100vw',
  desktop: '540px',
  mobile: '280px',
};

function InfoHelper(props: Props): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const { onClick } = props;
  const infoButton = useRef<HTMLButtonElement>(null);
  const infoCloseButton = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (open) {
      if (infoCloseButton && infoCloseButton.current) infoCloseButton.current.focus();
    }
  }, [anchorEl, open, infoCloseButton]);

  const onIconButtonClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(open ? null : event.currentTarget);

      setOpen(!open);
      onClick?.();
    },
    [open, onClick],
  );

  const onClickAway = useCallback(() => {
    if (!props.isMobile && open) {
      setOpen(false);
      setAnchorEl(null);
    }
  }, [props.isMobile, open]);

  const infoContent = (
    <Body p={2}>
      {isMultiLine(props.text) ? (
        <List dense component='dl'>
          {getNonEmptyLines(props.text).map((text) => (
            <ListItem disableGutters key={text}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      ) : (
        <RunningText>{ReactHtmlParser(props.text)}</RunningText>
      )}
      {props.image && (
        <Box mt={2}>
          <Image src={props.image.src} alt={props.image.alt ?? ''} />
        </Box>
      )}
    </Body>
  );

  let iconButton = (
    <IconButton type='button' onClick={onIconButtonClick} aria-label='See details' ref={infoButton}>
      <DefaultIcon />
    </IconButton>
  );

  if (props.usePill && props.iconNumber && props.title) {
    iconButton = (
      <Pill type='button' onClick={onIconButtonClick} aria-label='See details'>
        <PillText>{props.iconNumber}</PillText>
        <PillText>&nbsp;-&nbsp;</PillText>
        {props.title ? <PillText>{ReactHtmlParser(props.title)}</PillText> : null}
      </Pill>
    );
  }

  let content: ReactElement | null;

  const showAsModal = (props.isMobile || props.modalOptions?.forceModal) && open;

  if (showAsModal) {
    const showAsFullSizeModal = props.isMobile && props.modalOptions?.fullSizeOnMobile;
    if (showAsFullSizeModal) {
      content = (
        <BaseModal
          width='100vw'
          isMobile={!!props.isMobile}
          isFullSizeMobile
          labelledBy='modal-title'
          closeButtonTitle={props.closeButtonText}
          onClose={() => setOpen(false)}
        >
          {infoContent}
        </BaseModal>
      );
    } else {
      let modalWidth = modalWidths.desktop;

      if (props.isMobile) {
        if (props.modalOptions?.fullSizeOnMobile) {
          modalWidth = modalWidths.full;
        } else {
          modalWidth = modalWidths.mobile;
        }
      }

      content = (
        <Modal
          width={modalWidth}
          isMobile={!!props.isMobile}
          labelledBy='modal-title'
          onClose={() => setOpen(false)}
          hideHeader={true}
          styles={{
            modal: {
              borderRadius: '4px',
            },
          }}
        >
          <Container isMobile={props.isMobile}>
            <Header p={2} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
              {props.title && <ModalTitle>{ReactHtmlParser(props.title)}</ModalTitle>}
              <BareButton onClick={() => setOpen(false)}>
                <DefaultCloseSvgData />
              </BareButton>
            </Header>
            {infoContent}
          </Container>
        </Modal>
      );
    }
  } else {
    content = anchorEl ? (
      <Popper
        isOpen={open}
        anchorEl={anchorEl}
        placement='right'
        style={{ zIndex: 1300 }}
        transition
        transitionFunction={({ transitionProps, arrowElementProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...transitionProps} timeout={350}>
              <Container isPopper>
                <Header p={2} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                  {props.title ? <ModalTitle>{ReactHtmlParser(props.title)}</ModalTitle> : <div />}
                  <BareButton onClick={() => onClickAway()} type='button' ref={infoCloseButton}>
                    <DefaultCloseSvgData />
                  </BareButton>
                </Header>
                {infoContent}
                <PopperDefaultArrow {...arrowElementProps} />
              </Container>
            </Fade>
          </ClickAwayListener>
        )}
      ></Popper>
    ) : null;
  }

  return (
    <div>
      {content}
      {iconButton}
    </div>
  );
}

export const InfoSupplement = withResponsive(InfoHelper);
