import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, List, ListItemText, Button } from '@breathelife/mui';
import { ApplicationSupportESignatureCeremony, ESignCeremonyStatus } from '@breathelife/types';

import { Icon } from '../../../../../Components/Icons';
import Typography from '../../../../../Components/Typography';
import styled from '../../../../../Styles/themed-styled-components';

enum IconPosition {
  right = 'right',
  left = 'left',
}

type IconProps = {
  position: IconPosition;
};

const IconWrapper = styled.div<IconProps>`
  margin-left: ${(props) => props.position === IconPosition.right && '10px'};
  margin-right: ${(props) => props.position === IconPosition.left && '10px'};
`;

const StyledIconTextButton = styled(Button)<IconProps & { isActive: boolean }>`
  margin: 0;
  width: 100%;
  & span {
    justify-content: left;
  }
  &&& {
    padding: 2px 4px 0 4px !important;
    height: auto;
    background-color: ${(props) => (props.isActive ? `${props.theme.colors.grey['20']}` : 'transparent')};
  }

  display: flex;
  padding: 0 !important;
  flex-direction: ${(props) => (props.position === IconPosition.right ? 'row' : 'row-reverse')};
`;

const activeCeremonyStatuses = [
  ESignCeremonyStatus.DRAFT,
  ESignCeremonyStatus.IN_PROGRESS,
  ESignCeremonyStatus.READY,
  ESignCeremonyStatus.COMPLETED,
];

const isActiveCeremony = (ceremony: ApplicationSupportESignatureCeremony): boolean => {
  return activeCeremonyStatuses.includes(ceremony.status);
};

export function CeremonyList(props: {
  ceremonies: ApplicationSupportESignatureCeremony[];
  loadCeremonyData: (id: string) => void;
}): ReactElement {
  const { t } = useTranslation();
  const { ceremonies, loadCeremonyData } = props;
  const [selectedCeremonyId, setSelectedCeremonyId] = useState<string>();

  useEffect(() => {
    if (ceremonies.length) {
      setSelectedCeremonyId(ceremonies[0].id);
    }
  }, [ceremonies]);

  if (!ceremonies.length) {
    return <Box mt={3}>{t('applicationSupport.eSignature.noESignCeremonyAvailable')}</Box>;
  }

  const handleCeremonyChange = (ceremonyId: string): void => {
    setSelectedCeremonyId(ceremonyId);
    loadCeremonyData(ceremonyId);
  };

  return (
    <Box>
      <List dense={true}>
        {ceremonies.map((ceremony, index) => (
          <ListItemText key={ceremony.id}>
            <StyledIconTextButton
              variant='text'
              isActive={selectedCeremonyId === ceremony.id}
              onClick={() => {
                handleCeremonyChange(ceremony.id);
              }}
              position={IconPosition.left}
            >
              <IconWrapper position={IconPosition.left}>{<Icon name='files' />}</IconWrapper>
              <Typography variant='body5'>
                {t('applicationSupport.eSignature.eSignCeremony', { ceremonyNumber: (index + 1).toString() })}
                {isActiveCeremony(ceremony) ? ` ${t('applicationSupport.eSignature.activeCeremony')}` : ''}
              </Typography>
            </StyledIconTextButton>
          </ListItemText>
        ))}
      </List>
    </Box>
  );
}
