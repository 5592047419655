import { Application, LineOfBusinessName } from '@breathelife/types';
import { PlatformFeatures } from '../../Models/PlatformFeatures';

type ApplicationCopyEligibility = {
  eligibility: boolean;
  reason?: string;
};

export function isApplicationEligibleForCopy(
  application: Application,
  features: PlatformFeatures,
): ApplicationCopyEligibility {
  if (!features.applicationCopy?.enabled || !application?.createdAt) {
    return {
      eligibility: false,
    };
  }
  if ([LineOfBusinessName.legacyAnnuity].includes(application.lineOfBusiness)) {
    return {
      eligibility: false,
      reason: 'notifications.copyApplication.copyApplicationDisabledAnnuityLineOfBusiness',
    };
  }
  if (application.submitted) {
    return {
      eligibility: false,
      reason: 'notifications.copyApplication.copyApplicationDisabledAlreadySubmitted',
    };
  }
  if (!features.applicationCopy?.applicationCopyThresholdInDays) {
    return {
      eligibility: false,
      reason: 'notifications.copyApplication.copyApplicationMissingConfigThresholdInDays',
    };
  }
  if (!features.applicationCopy?.disabledForLineOfBusiness) {
    return {
      eligibility: false,
      reason: 'notifications.copyApplication.copyApplicationIsDisabledForLineOfBusiness',
    };
  }
  if (!features.applicationCopy?.startDate) {
    return {
      eligibility: false,
      reason: 'notifications.copyApplication.copyApplicationMissingConfigStartDate',
    };
  }

  // Verify starting date for application copy
  const startDate = new Date(features.applicationCopy.startDate).getTime();
  const creationDate = new Date(application.createdAt).getTime();
  if (creationDate < startDate) {
    return {
      eligibility: false,
      reason: 'notifications.copyApplication.copyApplicationDisabledStartDate',
    };
  }

  // Verify threshold in days for application copy
  const applicationCopyThresholdInDays = features.applicationCopy.applicationCopyThresholdInDays;
  const todayDate = new Date().getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const daysDifference = Math.round((todayDate - creationDate) / oneDay);
  if (applicationCopyThresholdInDays < daysDifference) {
    return {
      eligibility: false,
      reason: 'notifications.copyApplication.copyApplicationDisabledThresholdInDays',
    };
  }

  return {
    eligibility: true,
  };
}
