import { createContext } from 'react';

import { OnAnswerChange, OnBulkAnswerClear } from '@breathelife/questionnaire-engine';
import { LineOfBusinessName, ProductsWidgetFeatureType } from '@breathelife/types';

import { SectionGroupId } from '../Helpers/questionnaireAnswers';

export type ProposedInsuredTab = {
  name: string;
  index: number;
  surrogateId: string;
  numberOfErrors: number;
  completed: boolean;
};

export type AssistedApplicationContextProps = {
  // Proposed insured
  currentSectionGroupId: SectionGroupId;
  onSwitchSectionGroupTab: (id: SectionGroupId) => void;
  proposedInsuredTabs: ProposedInsuredTab[];
  currentProposedInsuredIndex: number;
  onSelectProposedInsured: (index: number) => void;
  onAddProposedInsured: () => void;
  onRemoveProposedInsured: (surrogateId: string) => void;
  activeSectionIndex: number;
  onSetActiveSectionIndex: (newActiveSectionIndex: number) => void;

  // Form
  areAllFieldsDisabled: boolean;
  onAnswerChange: OnAnswerChange;
  onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any, repeatedQuestionIndex?: number) => void;
  onBulkAnswerClear: OnBulkAnswerClear;

  // Application
  applicationLineOfBusiness: LineOfBusinessName;
  productWidgetType?: ProductsWidgetFeatureType;
};

export const AssistedApplicationContext = createContext<AssistedApplicationContextProps>(
  {} as AssistedApplicationContextProps,
);
