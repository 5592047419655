import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactElement, Fragment, useContext, useState } from 'react';

import config from '@breathelife/config/frontend';
import { InfoVariants } from '@breathelife/questionnaire-engine';
import { IconName, QuotedInsuranceProduct } from '@breathelife/types';
import { Information, StatCard, Statistic, Text, Icon } from '@breathelife/ui-components';

import { Button } from '../../Components/Button';
import { ProductDetailsModal } from '../../Components/Modals/ProductDetailsModal/ProductDetailsModal';
import { ProductCard } from '../../Components/ProductCard/ProductCard';
import { Slider } from '../../Components/Slider/Slider';
import { CarrierContext } from '../../Context/CarrierContext';
import { CenteredLayout } from '../../Layouts/Centered/Layout';
import { shortLocale, text, toCurrency } from '../../Localization/Localizer';
import { ApplicationAssignee } from '../../Models/ApplicationAssignee';
import { ApplicationState } from '../../Models/ApplicationState';

export type Props = {
  title?: string;
  subtitle?: string;
  application?: ApplicationState;
  applicationAssignee?: ApplicationAssignee;
  quotedProducts: QuotedInsuranceProduct[];
  recommendedCoverage: number | undefined;
  isSelfServeBlocked: boolean;
  isLoadingQuotes: boolean;
  isSimpleQuoter?: boolean;
  showLoadingPage: boolean;
  selectedCoverageAmount: number;
  coverageAmountOptions: {
    min: number;
    max: number;
  };
  setSelectedCoverageAmount(value: number): void;
  fetchQuotes(coverageAmount: number): void;
  onBack: () => void;
  onApply: (product: QuotedInsuranceProduct) => void;
  onApplyThroughAdvisor: (productId?: string) => void;
  onCoverageAmountChange: (answer: number) => void;
  onUseNeedsAnalysis: (useNeedsAnalysis: boolean) => void;
};

export function QuoterView(props: Props): ReactElement {
  const { features } = useContext(CarrierContext);

  const {
    title: titleProp,
    subtitle: subtitleProp,
    recommendedCoverage,
    quotedProducts,
    isLoadingQuotes,
    isSimpleQuoter,
    onApply,
    fetchQuotes,
    coverageAmountOptions,
    selectedCoverageAmount,
    setSelectedCoverageAmount,
    onCoverageAmountChange,
    onUseNeedsAnalysis,
  } = props;

  const showLoadingPage = isSimpleQuoter ? false : props.showLoadingPage;

  const title = titleProp || (isSimpleQuoter && text('product.simpleQuoterTitle')) || text('product.title');
  const subtitle = subtitleProp || (isSimpleQuoter && text('product.simpleQuoterSubtitle')) || text('product.subtitle');
  const sliderAmountText = isSimpleQuoter
    ? text('product.selectedCoverage.simpleQuoterSliderAmountText')
    : text('product.selectedCoverage.sliderAmountText');

  const sliderStep = (features.productPage.enabled && features.productPage.quoter?.sliderStep) || 10_000;

  const [selectedProduct, setSelectedProduct] = useState<QuotedInsuranceProduct>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeProductDetailsModal = (): void => {
    setIsModalOpen(false);
  };

  const openDetails = (product: QuotedInsuranceProduct): void => {
    setIsModalOpen(true);
    setSelectedProduct(product);
  };

  const hasQuoteProducts = quotedProducts.length > 0;

  const maximumCoverageAllowed = selectedProduct?.coverageRange?.max ?? coverageAmountOptions.max;
  const recommendedCoverageOverMaximum = recommendedCoverage ? recommendedCoverage > maximumCoverageAllowed : false;
  const displaySelectedCoverageDiffersFromRecommendedCoverage =
    !recommendedCoverageOverMaximum && recommendedCoverage !== selectedCoverageAmount;

  return (
    <Fragment>
      <ProductDetailsModal
        isOpen={isModalOpen}
        closeModal={closeProductDetailsModal}
        product={selectedProduct}
        coverageAmount={selectedCoverageAmount}
        carrierInfo={{
          companyName: config.get(`carrier.companyName.${shortLocale()}`),
        }}
        policyDetailsUrl={
          selectedProduct && 'policyDetailsUrl' in selectedProduct ? selectedProduct?.policyDetailsUrl : ''
        }
        productLogo={
          selectedProduct && 'logoUrl' in selectedProduct
            ? (selectedProduct as QuotedInsuranceProduct).logoUrl
            : undefined
        }
        isSelfServeBlocked={props.isSelfServeBlocked}
        onApply={onApply}
        onApplyThroughAdvisor={props.onApplyThroughAdvisor}
      />
      <CenteredLayout
        hideProgress
        isLoading={showLoadingPage}
        loadingContent={{
          title: text('transitionLoadingPage.title'),
          subtitle: text('transitionLoadingPage.subtitle'),
          image: <Icon name={IconName.loadingCalculator} size='66px' />,
        }}
        offsetContent={<Icon name={IconName.coverage} size='60px'></Icon>}
        title={title}
        subtitle={subtitle}
        headerVariant='default'
      >
        {!isSimpleQuoter && (
          <Box mt={3}>
            <StatCard
              data-testid='recommended-coverage'
              title={text('product.recommendedCoverage.label')}
              subtitle={recommendedCoverage?.toString() ?? ''}
              isCurrency={true}
              info={{
                title: text('product.recommendedCoverage.infoTitle'),
                text: text('product.recommendedCoverage.infoText'),
              }}
              locale={shortLocale()}
            />
          </Box>
        )}

        {hasQuoteProducts && (
          <Fragment>
            <Box mt={3} px={2}>
              <Text>{sliderAmountText}</Text>
            </Box>

            <Box mt={3}>
              {recommendedCoverageOverMaximum && (
                <Information
                  title={text('product.warning.warningTitle')}
                  text={text('product.warning.coverageOverLimitOfProduct')}
                  variant={InfoVariants.warning}
                />
              )}
              {!isSimpleQuoter && displaySelectedCoverageDiffersFromRecommendedCoverage && (
                <Information
                  title={text('product.warning.warningTitle')}
                  text={text('product.warning.recommendedCoverageChange')}
                  variant={InfoVariants.warning}
                />
              )}
            </Box>

            <Box mt={3} display='flex' flexWrap='wrap' alignItems='center' px={2}>
              <Text noSpacing variant='legal' grey={80}>
                {text('product.selectedCoverage.label')}
              </Text>
              <Box m={1}>
                <Statistic value={toCurrency(selectedCoverageAmount, { precision: 0 })} />
              </Box>
            </Box>
            <Box px={2}>
              <Slider
                labels={[coverageAmountOptions.min, coverageAmountOptions.max].map((value) =>
                  toCurrency(value, { precision: 0 }),
                )}
                min={coverageAmountOptions.min}
                max={coverageAmountOptions.max}
                step={sliderStep}
                value={selectedCoverageAmount}
                onChange={(value) => {
                  fetchQuotes(value);
                  onCoverageAmountChange(value);
                }}
                onDrag={setSelectedCoverageAmount}
              />
            </Box>

            {isSimpleQuoter && (
              <Box my={3} px={2} display='flex' flexWrap='wrap' alignItems='center' justifyContent='center'>
                <Text align='center'>{text('product.simpleQuoterUseCalculator')}</Text>
                <Button
                  data-testid='simpleQuoterUseCalculator'
                  variant='outlined'
                  color='primary'
                  size='large'
                  fullWidth
                  onClick={() => onUseNeedsAnalysis(true)}
                >
                  {text('product.simpleQuoterCalculate')}
                </Button>
              </Box>
            )}

            <Box my={3}>
              {quotedProducts.map((product, index) => (
                <ProductCard
                  key={`${product.id}-${index}`}
                  product={product}
                  isLoadingQuotes={isLoadingQuotes}
                  isSelfServeBlocked={props.isSelfServeBlocked}
                  onApply={onApply}
                  onOpenDetails={openDetails}
                  onApplyThroughAdvisor={props.onApplyThroughAdvisor}
                />
              ))}
            </Box>
          </Fragment>
        )}

        <Box my={3} px={2}>
          <Text>{text('product.otherProducts')}</Text>
        </Box>

        {!props.isSelfServeBlocked && (
          <Box my={3}>
            <Button
              data-testid='apply-through-advisor'
              variant='outlined'
              color='primary'
              size='large'
              fullWidth
              onClick={() => props.onApplyThroughAdvisor()}
            >
              {text('product.applyThroughAdvisorButtonLabel')}
            </Button>
          </Box>
        )}

        <Text variant='legal' align='center' grey={80}>
          {text('product.premiumDisclosure')}
        </Text>
      </CenteredLayout>
    </Fragment>
  );
}
