import { ReactElement } from 'react';
import styled from '../../Styles/themed-styled-components';
import Typography from '../Typography';

type Position = {
  top?: string;
  right?: string;
};
type CounterPosition = {
  small: Position;
  large: Position;
};

export const ErrorCounterPositionAbsoluteWrapper = styled('div')<{
  large: boolean;
  position?: CounterPosition;
}>`
  top: ${({ large, position }) => (large ? position?.large.top || '-4px' : position?.small.top || '-2px')};
  right: ${({ large, position }) => (large ? position?.large.right || '-13px' : position?.small.right || '-7px')};
  position: absolute;
`;

export const ErrorCounterBadge = styled('div')`
  min-width: 15px;
  height: 15px;
  border-radius: 15px;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.red[60]};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    line-height: 10px;
    margin-top: -3px;
  }
`;

export function ErrorCounter({
  count,
  position,
  withPlacementWrapper,
  max,
}: {
  count: number;
  withPlacementWrapper?: boolean;
  position?: CounterPosition;
  max?: number;
}): ReactElement {
  const errorLength = count.toString().length;
  const displayedCount = max && count > max ? `${max}+` : count;
  const badge = (
    <ErrorCounterBadge>
      <Typography variant='small3' color='#FFF'>
        {displayedCount}
      </Typography>
    </ErrorCounterBadge>
  );

  return withPlacementWrapper ? (
    <ErrorCounterPositionAbsoluteWrapper position={position} large={errorLength > 2}>
      {badge}
    </ErrorCounterPositionAbsoluteWrapper>
  ) : (
    badge
  );
}
