import styled from '../../Styles/themed-styled-components';

import { Button } from '../../Components/Button/Button';
import { Image } from '../../Components/Images/Image';

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UserPicture = styled(Image)`
  display: flex;
`;

export const StyledButton = styled(Button)`
  && {
    padding-top: 0;
    padding-bottom: 0;
    @media (max-width: ${(props) => props.theme.moduleBreakpoints.md}) {
      display: none;
    }
  }
`;
