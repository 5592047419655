import { ComponentType, Fragment, ReactElement, ReactNode } from 'react';
import { Container, HeaderContent, ProgressLine, ProgressText } from './Styles';
import { ChildrenWrapper, FlexWrapper } from '../../Centered/CenteredLayoutHeader/Styles';

export type HeaderProps = {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  mobileContent?: ReactNode;
};

type Props = {
  className?: string;
  children?: ReactNode;
} & HeaderProps;

export function PlanFinderHeader(props: Props): ReactElement {
  let contents = (
    <Fragment>
      {(props.leftContent || props.rightContent) && <FlexWrapper>{props.leftContent}</FlexWrapper>}
      <ChildrenWrapper>{props.children}</ChildrenWrapper>
      {(props.leftContent || props.rightContent) && <FlexWrapper>{props.rightContent}</FlexWrapper>}
    </Fragment>
  );

  if (!props.children) {
    contents = (
      <Fragment>
        {props.leftContent}
        {props.rightContent}
      </Fragment>
    );
  }

  return (
    <Container role='banner' className={props.className}>
      <HeaderContent>{contents}</HeaderContent>
      {props.mobileContent}
    </Container>
  );
}

type ProgressHeaderProps = {
  headerComponent: ComponentType<Props>;
  progress: number;
  progressText: string;
} & HeaderProps;

export function ProgressHeader({ progressText, progress, ...props }: ProgressHeaderProps): ReactElement {
  const Header = props.headerComponent;

  return (
    <Header {...props}>
      <ProgressLine progress={progress} aria-labelledby='progress_completed' />
      <ProgressText id='progress_completed'>{progressText}</ProgressText>
    </Header>
  );
}
