import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { ButtonProps } from '@breathelife/mui';
import { RunningText } from '@breathelife/ui-components';

const disabledButtonStyle = css`
  color: ${(props) => props.theme.colors.white};
  cursor: not-allowed;
  opacity: 0.3;
  box-shadow: none;
  transform: translateY(5px);
`;

const buttonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: ${(props) => props.theme.metrics.borders.button};
  border-color: ${(props) => props.theme.colors.button.base.border.default};
  border-radius: ${(props) => props.theme.metrics.button.borderRadius};
  font-size: ${(props) => props.theme.fonts.size.button.submit};
  font-family: ${(props) => props.theme.fonts.family.button};
  font-weight: ${(props) => props.theme.fonts.weight.button};
  text-align: center;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.colors.button.base.background.default};
  color: ${(props) => props.theme.colors.button.base.text.default};
  transition: all ${(props) => props.theme.animation.speed.medium} ${(props) => props.theme.animation.transition};
  box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25);
  min-width: 180px;
  min-height: 54px;
  line-height: 1.5em;
  text-transform: uppercase;
  margin: 0 auto;
  span {
    /*
      The text is not centered otherwise since
      the Eina01 font we are using might have too much whitespace
      at the bottom, making the text seem elevated
     */
    transform: translateY(2px);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
    margin: 0;
  }
  &:not(:disabled) {
    ${(props: ButtonProps) =>
      !props.disabled &&
      css`
        &:focus {
          outline: 0;
          border-color: ${(props) => props.theme.colors.button.base.border.focus} !important;
          background-color: ${(props) => props.theme.colors.button.base.background.focus};
        }
        &:hover {
          outline: 0;
          &:not(:focus) {
            border-color: ${(props) => props.theme.colors.button.base.border.hover};
          }
          background-color: ${(props) => props.theme.colors.button.base.background.hover};
        }
        &:active {
          outline: 0;
          &:not(:focus) {
            border-color: ${(props) => props.theme.colors.button.base.border.active};
          }
          background-color: ${(props) => props.theme.colors.button.base.background.active};
        }
      `}
  }
  ${(props: ButtonProps) => props.disabled && disabledButtonStyle}
  &:disabled {
    ${disabledButtonStyle}
  }
`;

export const Link = styled.a`
  ${buttonStyle}
  display: inline-flex;

  font-size: '15px';
  font-family: inherit;

  &:hover,
  &:active,
  &:focus {
    color: '#ffffff';
  }
`;

function LinkButton(props: any): ReactElement {
  const handleClick = (e: any): void => {
    if (props.disabled) {
      return e.preventDefault();
    }
    props.onClick && props.onClick(e);
  };

  return (
    <Link {...props} href={props.href} onClick={handleClick}>
      {props.children}
    </Link>
  );
}

const Container = styled(LinkButton)<{ fullSize?: boolean }>`
  ${(props) =>
    props.fullSize &&
    css`
      width: 100%;
    `}
  padding: 0;
  text-decoration: none;
`;

const PhoneText = styled(RunningText)`
  margin-left: 10px;
  color: white;
  font-weight: 500;
`;

const renderPhoneIcon = (): ReactElement => (
  <svg fill='none' height='16' viewBox='0 0 12 12' width='16' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m2.92536 5.17839c1.23257 2.10043 2.0543 2.94066 4.10861 4.20089l1.64345-1.68035c.83927.21923 1.89878.52015 2.74918.5166.2834-.00231.5658.28752.5734.56438l-.0191 2.62149c-.0012.2899-.2858.5809-.5746.5986-7.08123.0127-11.17481-4.17287-11.40493769-11.4271-.02350391-.280298.26112469-.57132264.54458069-.57252264h2.808067c.31005-.01224616.59237.27640764.65965.45271264-.07188.98314.33583 2.18237.55515 3.04549z'
      fill='#fff'
    />
  </svg>
);

type PhoneButtonProps = {
  fullSize?: boolean;
  alt: string;
  phoneNumber?: string;
  img?: string;
} & ButtonProps;

export function PhoneButton({ phoneNumber, ...props }: PhoneButtonProps): ReactElement {
  return (
    <Container href={`tel:${phoneNumber}`} {...props}>
      {renderPhoneIcon()}
      <PhoneText>{phoneNumber}</PhoneText>
    </Container>
  );
}
