import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactElement, Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { hash } from '@breathelife/hash';
import { ButtonName, TypewriterTracking } from '@breathelife/frontend-tracking';
import { IconName } from '@breathelife/types';
import { Icon, Image, ResponsiveProps, withResponsive } from '@breathelife/ui-components';

import { Spinner } from '../../Components/Spinner';
import { CarrierContext } from '../../Context/CarrierContext';
import { assigneeIsInterlocutor } from '../../Helpers/interlocutorHelper';
import { useCxSelector } from '../../Hooks/useCxSelector';
import { CenteredLayout } from '../../Layouts/Centered/Layout';
import { getOtherLocales, text } from '../../Localization/Localizer';
import { ApplicationAssignee } from '../../Models/ApplicationAssignee';
import { switchLanguage } from '../../Redux/SwitchLanguage/SwitchLanguageOperations';
import {
  AdvisorInfoPanel,
  ButtonsContainer,
  LanguageButton,
  StartButton,
  StyledBody,
  StyledBox,
  StyledRunningText,
  Title,
} from './Styles';
import { useGetAdvisorTitleFallback } from '../../Hooks/useGetAdvisorTitleFallback';
import { GhostLinkButton } from '../../Components/GhostLinkButton';

export type HomePageProps = {
  onCtaClick: () => void;
  isLoading: boolean;
  applicationAssignee?: ApplicationAssignee;
};

type Props = HomePageProps & ResponsiveProps;

function LanguageSwitcher(): ReactElement | null {
  const dispatch = useDispatch();
  const otherLanguages = getOtherLocales();
  const appId = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication?.id) ?? null;

  const languageSwitcherButtons = otherLanguages.map((language) => (
    <LanguageButton
      key={language}
      onClick={async () => {
        TypewriterTracking.clickedButton({
          buttonName: ButtonName.switchedLanguage,
          hashedId: hash(appId),
        });
        await dispatch(switchLanguage(appId, language));
      }}
    >
      {text(`languagesCompact.${language}`)}
    </LanguageButton>
  ));

  if (!languageSwitcherButtons.length) return null;

  return <StyledBox>{languageSwitcherButtons}</StyledBox>;
}

function WelcomeTitle(props: { applicationAssignee?: ApplicationAssignee; isInterlocutor: boolean }): ReactElement {
  const { applicationAssignee, isInterlocutor } = props;
  const { displayFullAdvisorNameOnAdvisorGreetingMessage } = useContext(CarrierContext);

  const interlocutorName = applicationAssignee?.group?.name;

  let advisorGreeting = '';
  if (isInterlocutor && interlocutorName) {
    advisorGreeting += text('home.interlocutorGreeting', { interlocutorName });
  } else if (applicationAssignee?.name) {
    const advisorName = displayFullAdvisorNameOnAdvisorGreetingMessage
      ? applicationAssignee.name
      : applicationAssignee.preferredName || applicationAssignee.firstName;
    advisorGreeting += text('home.advisorGreeting', { advisorName });
  }

  const welcomeText = text(`home.life.welcomeTextTitle`);

  return (
    <Box mb={{ xs: 4 }}>
      {!!advisorGreeting && (
        <Fragment>
          <Title>{advisorGreeting}</Title>
          <br />
        </Fragment>
      )}
      <Title id='home-title' level={1}>
        {welcomeText}
      </Title>
    </Box>
  );
}

function WelcomeSubtitle(props: { isMobile: boolean; isPlural: boolean }): ReactElement {
  const { isMobile, isPlural } = props;
  const pluralOrSingle = isPlural ? 'plural' : 'single';

  const welcomeTextSubtitleKey = `home.life.welcomeTextSubtitle.${pluralOrSingle}`;
  return (
    <Box mb={{ xs: 4, md: 7 }}>
      <StyledRunningText center={isMobile}>{text(welcomeTextSubtitleKey)}</StyledRunningText>
    </Box>
  );
}

function HomePageComponent(props: Props): ReactElement {
  const { cloudinaryConfig, interlocutorIds } = useContext(CarrierContext);
  const [isQuestionLoading, setIsQuestionLoading] = useState(false);
  const { isMobile, isLoading, applicationAssignee, onCtaClick } = props;
  const isInterlocutor = assigneeIsInterlocutor(interlocutorIds, applicationAssignee);
  const advisorTitleFallback = useGetAdvisorTitleFallback();

  useEffect(() => {
    // This is needed since there's no logic to set back isQuestionLoading to false because the `onCtaClick` event
    // triggered when starting the application is an async operation coming from the props. If something goes wrong, the
    // isLoading props will be set back to false, at which point we can also reset the isQuestionLoading value
    if (!isLoading) setIsQuestionLoading(false);
  }, [isLoading]);

  return (
    <Fragment>
      {isLoading ? (
        <Spinner />
      ) : (
        <CenteredLayout
          title=''
          isLoading={false}
          headerVariant={isMobile ? 'placeholder' : 'default'}
          hideScheduleCall={true}
          hideProgress
        >
          {isMobile && <LanguageSwitcher />}
          <StyledBody>
            {applicationAssignee?.picture && (
              <Box mb={{ xs: 2, sm: 2, md: 7, lg: 7 }}>
                <Image
                  src={applicationAssignee.picture}
                  width={isMobile ? 72 : 60}
                  height={isMobile ? 72 : 60}
                  cloudinary={{
                    cloudName: cloudinaryConfig?.cloudName ?? '',
                    folder: cloudinaryConfig?.folder ?? '',
                  }}
                />
              </Box>
            )}
            {applicationAssignee?.name && isMobile && (
              <Box mb={5}>
                <AdvisorInfoPanel
                  advisorInfo={{
                    name:
                      applicationAssignee.name ?? `${applicationAssignee.firstName} ${applicationAssignee.lastName}`,
                    title:
                      applicationAssignee.subgroup?.name || applicationAssignee.group?.name || advisorTitleFallback,
                  }}
                  alignment='vertical'
                />
              </Box>
            )}
            <WelcomeTitle applicationAssignee={applicationAssignee} isInterlocutor={isInterlocutor} />
            <WelcomeSubtitle isMobile={!!isMobile} isPlural={isInterlocutor} />

            <ButtonsContainer>
              <StartButton
                loading={isQuestionLoading || isLoading}
                data-testid='start-application'
                disabled={isQuestionLoading || isLoading}
                onClick={() => {
                  setIsQuestionLoading(true);
                  onCtaClick();
                }}
              >
                <span>{text('home.startFlow')}</span>
              </StartButton>

              {!_.isEmpty(applicationAssignee) && (
                <GhostLinkButton href={`tel:${applicationAssignee?.phone}`}>
                  <Icon name={IconName.phone} size='12' />
                  {applicationAssignee?.phone}
                </GhostLinkButton>
              )}
            </ButtonsContainer>
          </StyledBody>
        </CenteredLayout>
      )}
    </Fragment>
  );
}

export const HomePage = withResponsive(HomePageComponent);
