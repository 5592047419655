import { Box } from '@breathelife/mui';
import ReactHtmlParser from 'html-react-parser';
import { ReactElement } from 'react';

import { CheckboxCard, FieldProps, formatRequiredFieldTitle, WarningText } from '@breathelife/ui-components';

import { translate } from '../../../Localization/Localizer';
import { StyleVariant } from '../../FieldGenerator/FieldGenerator';
import { QuestionText } from '../Question/Styles';

type Props = FieldProps & {
  id: string;
  value?: boolean;
  title: string;
  consentText: string;
  styleVariant: StyleVariant;
  showError?: boolean;
  required?: boolean;
  boldedBorder?: boolean;
};

export function CheckboxAgree(props: Props): ReactElement {
  const {
    consentText,
    disabled,
    id,
    label,
    onAnswerChange,
    optionalText,
    required,
    showError,
    styleVariant,
    title,
    validationError,
    value,
    boldedBorder,
  } = props;

  const labelValue = formatRequiredFieldTitle(required, title, label, optionalText);

  return (
    <Box>
      <QuestionText styleVariant={styleVariant}>{ReactHtmlParser(consentText)}</QuestionText>
      <CheckboxCard
        marginless
        optionId={id}
        groupName='agree-group'
        onChange={(optionId: string, checked: boolean) => onAnswerChange(checked)}
        checked={!!value}
        showErrorBorder={showError}
        disabled={disabled}
        boldedBorder={boldedBorder}
      >
        {<b>{ReactHtmlParser(labelValue) || translate('agree.checkbox')}</b>}
      </CheckboxCard>
      <Box component={WarningText} mt={2} role='alert' id={`${id}-error`}>
        {showError && (validationError?.message || translate('validation.agreeField'))}
      </Box>
    </Box>
  );
}
