import { Box } from '@breathelife/mui';
import { Fragment, ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Permission, StoredFileWithExistsProperty, IconName } from '@breathelife/types';
import { ImageTypes, Loader, Icon as IconFromUIComponents } from '@breathelife/ui-components';

import { IconButton } from '../../../../../../Components/Controls';
import { AttachmentIcon, Icon } from '../../../../../../Components/Icons';
import { RestrictedToUserPermissions } from '../../../../../Restricted/RestrictedToUserPermissions';
import { formatDate, guessTimezone } from '../../../../../../Helpers/datetime';
import { printFileSize } from '../../../../../../Helpers/fileSize';
import { useCarrierName, useGetApplicationFile } from '../../../../../../Hooks';
import { Application } from '../../../../../../Models/Application';

import { useTheme } from '../../../../../../Styles/themed-styled-components';
import { BasicStoredFile } from '../BasicStoredFile';

type Props = {
  application: Application;
  storedFile: StoredFileWithExistsProperty;
  onFileDelete: (fileId: string, isRequired: boolean) => void;
};

export function OtherFileItem(props: Props): ReactElement {
  const { application, storedFile, onFileDelete } = props;
  const carrierName = useCarrierName();
  const { t } = useTranslation();
  const theme = useTheme();

  const { fetchFile, fileToView } = useGetApplicationFile(
    application.id,
    storedFile.id,
    useCallback(({ signedUrl }) => {
      window.open(signedUrl, '_blank')?.focus();
    }, []),
  );

  const iconName = useMemo(() => {
    switch (storedFile.mimeType) {
      case 'application/pdf':
        return 'pdf';

      default:
        return null;
    }
  }, [storedFile.mimeType]);

  const sizeLabel = useMemo(() => {
    if (storedFile.sizeBytes) {
      return printFileSize(storedFile.sizeBytes, 1);
    }

    return '';
  }, [storedFile.sizeBytes]);

  const dateLabel = useMemo(() => {
    const formattedDate = formatDate(storedFile.createdAt, 'D/M/YYYY', guessTimezone());
    return t('modals.assistedApplication.fileAttachment.uploadedOn', { date: formattedDate });
  }, [storedFile.createdAt, t]);

  const onDeleteStoredFile = useCallback(async () => {
    onFileDelete(storedFile.id, false);
  }, [storedFile.id, onFileDelete]);

  const isLoading: boolean = fileToView.isLoading;

  return (
    <Fragment>
      <BasicStoredFile
        title={storedFile.displayName}
        subtitle={`${sizeLabel} ${dateLabel}`}
        warning={
          !isLoading && !storedFile.exists
            ? application.submitted
              ? t('modals.assistedApplication.fileAttachment.fileDeletedByRetentionPolicy', { carrierName })
              : t('modals.assistedApplication.fileAttachment.fileNotFoundUploadAgain', { carrierName })
            : undefined
        }
        icon={
          <Fragment>
            {isLoading && <Loader size='24' />}
            {!isLoading && !storedFile.exists && (
              <IconFromUIComponents
                name={IconName.doDisturbAlt}
                color={{ primary: theme.colors.red[60] }}
                size='24px'
              />
            )}
            {!isLoading && iconName && storedFile.exists && <AttachmentIcon name={iconName} size='medium' />}
          </Fragment>
        }
        rightCtas={
          <Fragment>
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
              {storedFile.exists && (
                <Box mr={application.submitted ? 0 : 1}>
                  <IconButton
                    icon={
                      <Icon
                        name='eye'
                        imageType={ImageTypes.meaningful}
                        ariaLabel={t('modals.assistedApplication.fileAttachment.openAttachment')}
                      />
                    }
                    disabled={isLoading}
                    onClick={fetchFile}
                  />
                </Box>
              )}

              {!application.submitted && (
                <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationFileRemove]}>
                  <Box>
                    <IconButton
                      icon={
                        <Icon
                          name='delete'
                          imageType={ImageTypes.meaningful}
                          ariaLabel={t('modals.assistedApplication.fileAttachment.deleteAttachment')}
                        />
                      }
                      disabled={isLoading}
                      onClick={onDeleteStoredFile}
                    />
                  </Box>
                </RestrictedToUserPermissions>
              )}
            </Box>
          </Fragment>
        }
      />
    </Fragment>
  );
}
