import { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Permission } from '@breathelife/types';

import { ApplicationMarketingDataExtractButton } from '../../../Components/Button/ApplicationMarketingDataExtractButton';
import { Icon } from '../../../Components/Icons';

import { ListFilterContainer } from '../../../Components/ListFilterContainer/ListFilterContainer';
import { RestrictedToUserPermissions } from '../../../Components/Restricted/RestrictedToUserPermissions';
import { ModalPayload } from '../../../ReduxStore/Layout/LayoutSlice';
import { Filters, ListFilter } from './ListFilter';
import { useCarrierContext } from '../../../Hooks';
import { CreateButton, CreateButtonActionType } from '../../../Components/CreateButton/CreateButton';
import { LeadSignatureStatusesColumnData, LeadStatusesColumnData } from '../../../Models/Lead';
import styled from '../../../Styles/themed-styled-components';

type Props = {
  onFiltersChanged: (filters?: Filters) => void;
  onSearchChange: (searchString: string) => void;
  onClickMarketingDataExtractButton: () => void;
  setModalState: (state: ModalPayload) => void;
  selectedUserId: string;
  searchTerm?: string;
  usedStatuses: LeadStatusesColumnData;
  currentFilters: Filters;
  usedSignatureStatuses: LeadSignatureStatusesColumnData;
};

const ButtonsContainer = styled.div`
  display: block;

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.md}) {
    display: none;
  }
`;

export function ListHeader({
  onFiltersChanged: propOnFiltersChanged,
  onSearchChange,
  onClickMarketingDataExtractButton,
  setModalState,
  selectedUserId,
  searchTerm,
  usedStatuses,
  usedSignatureStatuses,
  currentFilters,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { features } = useCarrierContext();

  const isApplicationMarketingDateExtractEnabled = features.applicationMarketingDataExtract?.enabled;
  const isLeadCreationEnabled = features.leadCreation.enabled;
  const hideCreateButton = isLeadCreationEnabled && features.leadCreation?.hideCreateButton;

  const onFiltersChanged = (filters?: Filters): void => {
    propOnFiltersChanged(filters);
  };

  const buttons = (
    <Fragment>
      {isApplicationMarketingDateExtractEnabled && (
        <RestrictedToUserPermissions requiredPermissions={[Permission.ApplicationMarketingDataExtract]}>
          <ApplicationMarketingDataExtractButton
            data-testid='applicationMarketingDataExtractButton'
            color='primary'
            variant='outlined'
            startIcon={<Icon name='cloudDownload' />}
            onClick={onClickMarketingDataExtractButton}
          >
            {t('cta.extractAllLeadData')}
          </ApplicationMarketingDataExtractButton>
        </RestrictedToUserPermissions>
      )}

      {!hideCreateButton && (
        <CreateButton
          idSuffix='list-header'
          action={
            isLeadCreationEnabled
              ? CreateButtonActionType.createLead
              : CreateButtonActionType.createApplicationWithLeadAndLaunch
          }
        />
      )}
    </Fragment>
  );

  return (
    <ListFilterContainer>
      <ListFilter
        buttons={buttons}
        selectedUserId={selectedUserId}
        searchTerm={searchTerm}
        setModalState={setModalState}
        onFiltersChanged={onFiltersChanged}
        onSearchChange={onSearchChange}
        usedStatuses={usedStatuses}
        usedSignatureStatuses={usedSignatureStatuses}
        currentFilters={currentFilters}
      />

      <ButtonsContainer>{buttons}</ButtonsContainer>
    </ListFilterContainer>
  );
}
