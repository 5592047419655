import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  deserializeNodeIdToAnswerPathMap,
  getAllSubsections,
  Localized,
  NodeIdToAnswerPathMap,
  QuestionnaireDefinition,
  QuestionnaireEngine,
  RenderingQuestionnaire,
  SerializedNodeIdToAnswerPathMap,
} from '@breathelife/questionnaire-engine';
import {
  DEFAULT_TIMEZONE,
  QuestionnaireBlueprint,
  RenderingType,
  Timezone,
  VersionedAnswers,
} from '@breathelife/types';
import { DebugToolbarModal, Loader } from '@breathelife/ui-components';

import { useCxSelector } from '../../Hooks/useCxSelector';
import { shortLocale, text } from '../../Localization/Localizer';
import { updateAnswersUntil } from '../../Redux/DebugToolbar/DebugToolbarOperations';
import { notificationSlice } from '../../Redux/Notification/NotificationSlice';
import ApiService from '../../Services/ApiService';
import { navigationSlice } from '@breathelife/redux';
import { generateQuestionWithStepIdUrl } from '../../Navigation/Urls';

export type QuestionnaireResponse = {
  version: string;
  questionnaire: Localized<QuestionnaireDefinition>;
  nodeIdToAnswerPath?: SerializedNodeIdToAnswerPathMap;
};

export enum DebugView {
  stepList = 'stepList',
  stepFill = 'stepFill',
}

type Props = {
  debugView: DebugView;
  onClose: () => void;
};

export function DebugToolbarModalContainer(props: Props): ReactElement {
  const { debugView, onClose } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [questionnaire, setQuestionnaire] = useState<Localized<QuestionnaireDefinition>>();
  const [renderingQuestionnaire, setRenderingQuestionnaire] = useState<RenderingQuestionnaire>();
  const [currentQuestionnaireApplicationId, setCurrentQuestionnaireApplicationId] = useState<string>();
  const [nodeIdToAnswerPathMap, setNodeIdToAnswerPathMap] = useState<NodeIdToAnswerPathMap>();
  const [isFetchingQuestionnaire, setIsFetchingQuestionnaire] = useState(false);
  const applicationId = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication?.id);
  const blueprint = useCxSelector((store) => store.consumerFlow.insuranceApplication.insuranceApplication?.blueprint);

  useEffect(() => {
    const isSameApplicationId = currentQuestionnaireApplicationId === applicationId;
    if (isSameApplicationId || isFetchingQuestionnaire) return;

    async function fetchQuestionnaire(): Promise<void> {
      if (!blueprint || !applicationId) return;

      setIsFetchingQuestionnaire(true);

      try {
        const response = await ApiService.getCarrierQuestionnaire<QuestionnaireResponse>(applicationId);
        const { questionnaire, nodeIdToAnswerPath: serializedNodeIdToAnswerPathMap } = response.data;
        if (!questionnaire || !serializedNodeIdToAnswerPathMap) {
          throw new Error('Missing questionnaire or nodeIdToAnswerPath in the response');
        }

        setCurrentQuestionnaireApplicationId(applicationId);
        setQuestionnaire(questionnaire);
        setNodeIdToAnswerPathMap(deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap));

        const nodeIdToAnswerPathMap = deserializeNodeIdToAnswerPathMap(serializedNodeIdToAnswerPathMap);

        const timezone = Timezone.from(Intl.DateTimeFormat().resolvedOptions().timeZone).withDefault(DEFAULT_TIMEZONE);

        setRenderingQuestionnaire(
          buildRenderingQuestionnaire(questionnaire, blueprint, nodeIdToAnswerPathMap, timezone, null),
        );
      } catch (err: any) {
        // No need to translate since this is only for internal use.
        dispatch(
          notificationSlice.actions.setError({
            message: 'An error occurred while fetching the questionnaire for the debug toolbar',
          }),
        );
      } finally {
        setIsFetchingQuestionnaire(false);
      }
    }
    void fetchQuestionnaire();
  }, [
    dispatch,
    questionnaire,
    nodeIdToAnswerPathMap,
    isFetchingQuestionnaire,
    applicationId,
    currentQuestionnaireApplicationId,
    blueprint,
  ]);

  const steps = useMemo(() => {
    if (!renderingQuestionnaire) return;
    // TODO: Handle steps that are not visible
    const subsectionsById = getAllSubsections(renderingQuestionnaire).map((subsection) => subsection.id);
    return subsectionsById;
  }, [renderingQuestionnaire]);

  const onNavigate = useCallback(
    (stepId: string) => {
      dispatch(navigationSlice.actions.setLoadingPage({ isVisible: false }));
      const url = generateQuestionWithStepIdUrl(stepId);
      navigate(url);
    },
    [navigate, dispatch],
  );

  const onPrefillApplication = useCallback(
    (stepId: string) => {
      if (!applicationId) return;
      dispatch(updateAnswersUntil(applicationId, stepId, navigate));
      onClose();
    },
    [applicationId, dispatch, onClose, navigate],
  );

  const onStepSelect = useCallback(
    (stepId: string) => {
      debugView === DebugView.stepFill ? onPrefillApplication(stepId) : onNavigate(stepId);
      onClose();
    },
    [debugView, onPrefillApplication, onNavigate, onClose],
  );

  if (isFetchingQuestionnaire) return <Loader />;

  return (
    <DebugToolbarModal
      onClose={onClose}
      steps={steps ?? []}
      onStepSelect={onStepSelect}
      title={debugView === DebugView.stepFill ? 'Prefill the application' : 'Jump to step'}
    />
  );
}

function buildRenderingQuestionnaire(
  questionnaire: Localized<QuestionnaireDefinition>,
  blueprint: QuestionnaireBlueprint,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
  timezone: Timezone,
  currentDateOverride: string | null,
): RenderingQuestionnaire {
  const language = shortLocale();

  const questionnaireEngine = new QuestionnaireEngine({
    questionnaire,
    nodeIdToAnswerPathMap,
    blueprint,
    timezone,
    currentDateOverride,
  });

  const renderingQuestionnaire = questionnaireEngine.generateRenderingQuestionnaire(
    new VersionedAnswers({ v1: {}, v2: {} }),
    language,
    text,
    {
      // TODO: Remove filter
      renderingType: RenderingType.consumerSummary,
      shouldValidateAllAnswers: false,
    },
  );

  return renderingQuestionnaire;
}
