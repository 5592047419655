import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';

import { FetchAllNotificationsResponse } from '@breathelife/types';

import { fetchAllNotifications } from '../../Services/NotificationCentreService';
import { QueryId } from '../common/common.types';

export function useFetchAllNotificationsQuery(
  cursorDate: Date | undefined,
  options?: UseQueryOptions<FetchAllNotificationsResponse>,
): UseQueryResult<FetchAllNotificationsResponse> {
  return useQuery<FetchAllNotificationsResponse>(
    [QueryId.notifications],
    async () => {
      return await fetchAllNotifications(cursorDate);
    },
    {
      ...options,
    },
  );
}
