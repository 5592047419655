import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@breathelife/mui';
import { Language, Permission } from '@breathelife/types';
import { TextAnswer } from '@breathelife/ui-components';
import { TypewriterTracking } from '@breathelife/frontend-tracking';

import { useUpdateLeadMutation } from '../../../../../ReactQuery/Lead/lead.mutations';
import { RestrictedToUserPermissions } from '../../../../../Components/Restricted/RestrictedToUserPermissions';
import { US_STATES } from '../../../../../Helpers/locations';
import { useCarrierContext, useDispatch } from '../../../../../Hooks';
import { DetailViewType } from '../../../../../Models/Layout';
import { Lead } from '../../../../../Models/Lead';
import { layoutSlice } from '../../../../../ReduxStore/Layout/LayoutSlice';
import { ContentHeading } from '../Styles';
import { BestTimeToReach } from './BestTimeToReach';
import { DateText } from './DateText';
import { CtaButton, LongWordBreak, LanguageDropdown } from './Styles';
import { SubscriptionToolLink } from './SubscriptionToolLink';

export type Props = {
  lead: Lead;
  showCommunicationLanguage: boolean;
};

export function PersonalInfoReadView(props: Props): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { countryCode, unsubToolUrl, languageSettings } = useCarrierContext();
  const { lead, showCommunicationLanguage } = props;
  const isEditable = lead?.status === 'new' || lead?.status === 'invited';
  const communications = lead?.['leads-communication'];
  const communicationLanguageInputId = 'personalInfoReadView-communicationLanguage-dropdown';

  const updateLeadMutation = useUpdateLeadMutation({
    onMutate: () => {
      TypewriterTracking.completedField({ fieldId: communicationLanguageInputId, hashedId: null });
    },
  });

  function onEditClick(): void {
    dispatch(layoutSlice.actions.updateRightPanelState({ type: DetailViewType.edit }));
  }

  function onChangeCommunicationLanguage(selectedLanguage: Language): void {
    if (!lead) return;

    updateLeadMutation.mutate({
      id: lead.id,
      communicationLanguage: selectedLanguage,
    });
  }

  return (
    <Fragment>
      <ContentHeading py={2} px={2.5}>
        <Box display='flex' flexDirection='column'>
          <div>
            <DateText lead={lead} />
          </div>
          {showCommunicationLanguage && (
            <Box mt={2} width='100%'>
              <RestrictedToUserPermissions requiredPermissions={[Permission.LeadCreate]}>
                <LanguageDropdown
                  required={false} // false to remove the little * next to it. The field remain filled and required anyway
                  data-testid={communicationLanguageInputId}
                  value={lead.communicationLanguage}
                  id={communicationLanguageInputId}
                  label={t('inputs.language')}
                  onChange={onChangeCommunicationLanguage}
                  enabledLanguages={languageSettings.enabledLanguages}
                  disabled={updateLeadMutation.isLoading}
                />
              </RestrictedToUserPermissions>
            </Box>
          )}
        </Box>

        {isEditable && (
          <RestrictedToUserPermissions requiredPermissions={[Permission.LeadCreate]}>
            <CtaButton variant='outlined' onClick={onEditClick} size='small'>
              {t('cta.edit')}
            </CtaButton>
          </RestrictedToUserPermissions>
        )}
      </ContentHeading>

      <Box p={3}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextAnswer
              data-testid='personalInfoView-fname'
              label={t('inputs.firstName')}
              value={lead?.firstName || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextAnswer
              data-testid='personalInfoView-lname'
              label={t('inputs.lastName')}
              value={lead?.lastName || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextAnswer
              label={t('inputs.language')}
              value={lead && lead.communicationLanguage ? t(`language.${lead.communicationLanguage}`) : '--'}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LongWordBreak>
                  <TextAnswer label={t('inputs.email')} value={lead?.email || ''} />
                </LongWordBreak>
              </Grid>
              <Grid item xs={6}>
                {unsubToolUrl && <SubscriptionToolLink url={unsubToolUrl} />}
              </Grid>
            </Grid>
          </Grid>

          {lead?.phoneNumber && (
            <Fragment>
              <Grid item xs={6}>
                <TextAnswer label={t('inputs.phoneNumber')} value={lead.phoneNumber || ''} />
              </Grid>
              <Grid item xs={6} />
            </Fragment>
          )}

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {lead?.postalCode && (
                <Grid item xs={6}>
                  <TextAnswer
                    label={countryCode === 'US' ? t('inputs.zipCode') : t('inputs.postalCode')}
                    value={lead.postalCode || ''}
                  />
                </Grid>
              )}
              {lead?.region && (
                <Grid item xs={6}>
                  <TextAnswer
                    label={countryCode === 'US' ? t('inputs.state') : t('inputs.province')}
                    value={
                      countryCode === 'US'
                        ? US_STATES[lead.region as keyof typeof US_STATES]
                        : t(`canadianProvinces.${lead.region}`) || ''
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {communications?.['leads-communication-schedules'] && (
        <Box p={3}>
          <BestTimeToReach
            preferredMethods={communications.preferredMethods}
            communicationSchedules={communications['leads-communication-schedules']}
          />
        </Box>
      )}
    </Fragment>
  );
}
