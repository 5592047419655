import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';

import { SelectedProductInformation } from '@breathelife/types';

import { CurrencyText } from '../../../Components/CurrencyText/CurrencyText';
import { text } from '../../../Localization/Localizer';
import { CarrierName, ContentContainer, LogoContainer, ProductText } from './Styles';

type Props = {
  productInformation: SelectedProductInformation;
};

export function ProductSummary(props: Props): ReactElement | null {
  const product = props.productInformation as SelectedProductInformation;
  if (!product.premium || !product.coverageAmount) return null;
  return (
    <ContentContainer>
      <Box display='flex' mr={2}>
        <LogoContainer mr={2} alignItems='center' justifyContent='center'>
          <img alt='Company Logo' src={product.logoUrl} width='auto' height='36' />
        </LogoContainer>
        <Box display='flex' flexDirection='column' width='100%'>
          <CarrierName>{product.carrierName}</CarrierName>
          <ProductText>{product.name}</ProductText>
        </Box>
      </Box>
      <Box display='flex' alignItems='flex-end'>
        {<CurrencyText amount={product.premium} suffix={text('product.perMonth')} responsive />}
        <Box ml={2} overflow='hidden'>
          <CurrencyText amount={product.coverageAmount} suffix={text('product.coverage')} precision={0} responsive />
        </Box>
      </Box>
    </ContentContainer>
  );
}
