import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { ExpandableFileTemplateRule, StoredFileWithExistsProperty, StoredFileWithSignedUrl } from '@breathelife/types';

import { Application } from '../../Models/Application';
import { QueryId } from '../../ReactQuery/common/common.types';
import { fetchApplicationFiles, getApplicationFile } from '../../Services/ApplicationFileService';
import { getApplication } from '../../Services/ApplicationsService';
import { findFileTemplateRules } from '../../Services/FileTemplateRulesService';
import { useDispatch } from '../../Hooks';
import { useTranslation } from 'react-i18next';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { MessageType } from '@breathelife/ui-components';

export function useFindApplicationFilesQuery(
  applicationIsSubmitted: boolean,
  applicationId?: string,
  options?: UseQueryOptions<StoredFileWithExistsProperty[], Error>,
): UseQueryResult<StoredFileWithExistsProperty[], Error> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<StoredFileWithExistsProperty[], Error>(
    [QueryId.storedFiles, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('Application id is missing.'));
      }
      return await fetchApplicationFiles(applicationId);
    },
    {
      onSuccess: (data) => {
        const hasMissingFiles = data.some((file) => !file.exists);
        if (hasMissingFiles) {
          dispatch(
            notificationSlice.actions.setNotification({
              type: MessageType.error,
              title: applicationIsSubmitted
                ? t('notifications.storedFilesDoNotExist.applicationSubmitted.title')
                : t('notifications.storedFilesDoNotExist.applicationNotSubmitted.title'),
              message: applicationIsSubmitted
                ? t('notifications.storedFilesDoNotExist.applicationSubmitted.message')
                : t('notifications.storedFilesDoNotExist.applicationNotSubmitted.message'),
            }),
          );
        }
      },
      ...options,
    },
  );
}

export function useGetApplicationFileQuery(
  applicationId: string,
  fileId: string,
  options?: UseQueryOptions<StoredFileWithSignedUrl, Error>,
): UseQueryResult<StoredFileWithSignedUrl, Error> {
  return useQuery<StoredFileWithSignedUrl, Error>(
    [QueryId.storedFiles, applicationId, fileId],
    async () => await getApplicationFile(applicationId, fileId, { download: false, validFor: 10 }),
    options,
  );
}

export function useFileTemplateRulesQuery(
  {
    questionnaireVersionId,
    includeFileTemplate,
    includeFileTemplateRecipient,
  }: {
    questionnaireVersionId: string | undefined;
    includeFileTemplate?: boolean;
    includeFileTemplateRecipient?: boolean;
  },
  options?: UseQueryOptions<ExpandableFileTemplateRule[], Error>,
): UseQueryResult<ExpandableFileTemplateRule[], Error> {
  return useQuery<ExpandableFileTemplateRule[], Error>(
    [QueryId.fileTemplateRules, questionnaireVersionId],
    async () => {
      if (!questionnaireVersionId) {
        throw new Error('Questionnaire version is not defined for this application.');
      }
      return await findFileTemplateRules(questionnaireVersionId, {
        includeFileTemplate: includeFileTemplate || false,
        includeFileTemplateRecipient: includeFileTemplateRecipient || false,
      });
    },
    options,
  );
}

export function useGetApplicationQuery(
  applicationId?: string,
  options?: UseQueryOptions<Application, Error>,
): UseQueryResult<Application, Error> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return useQuery<Application, Error>(
    [QueryId.application, applicationId],
    async () => {
      if (!applicationId) {
        return Promise.reject(new Error('Application id is missing.'));
      }
      return await getApplication(applicationId);
    },
    {
      ...options,
      enabled: !!applicationId,
      onError: async (error: any) => {
        if ('response' in error && 'data' in error.response) {
          const { code } = error.response.data;
          switch (code) {
            case 404:
              dispatch(
                notificationSlice.actions.setError({
                  message: t('notifications.applicationNotFound', {
                    applicationId: applicationId,
                  }),
                }),
              );
              break;
            default:
              dispatch(
                notificationSlice.actions.setError({
                  message: t('notifications.unexpectedErrorTryAgain'),
                }),
              );
          }
        }

        if (options?.onError) {
          await options.onError(error);
        }
      },
    },
  );
}
