import { Pluralizable } from './types';

type Fields = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  phonePlaceholder: string;
};

type Validation = {
  required: string;
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
  bestMoments: string;
};

type ContactMethod = {
  preference: string;
  phone: string;
  email: string;
};

export type LocalizedStrings = {
  subtitle: Pluralizable<{
    callYou: string;
    callUs: string;
  }>;
  submit: string;
  contactMethod: ContactMethod;
  fields: Fields;
  validation: Validation;
  selectTimeSlots: string;
  legal: string;
};

export type LocalizationStrings = {
  en: LocalizedStrings;
  fr: LocalizedStrings;
};

export const localizationStrings: LocalizationStrings = {
  en: {
    subtitle: {
      single: {
        callYou: 'Please provide me with your contact information and I’ll be in touch with you soon.',
        callUs: 'Reach me via the actions at the bottom of this page.',
      },
      plural: {
        callYou: 'Please provide us your contact information and we’ll be in touch with you soon.',
        callUs: 'Reach us via the actions at the bottom of this page.',
      },
    },
    submit: "Let's get in touch",
    contactMethod: {
      preference: 'How would you prefer to be contacted?',
      phone: 'Phone',
      email: 'Email',
    },
    fields: {
      firstName: 'First name*',
      lastName: 'Last name*',
      phone: 'Phone number*',
      email: 'Email*',
      phonePlaceholder: '(123) 456-7890',
    },
    validation: {
      required: 'Required field',
      phone: 'Invalid phone number',
      email: 'Invalid email',
      firstName: 'Invalid first name',
      lastName: 'Invalid last name',
      bestMoments: 'One or more time slots must be selected',
    },
    selectTimeSlots: 'Select one or more time slots below',
    legal: '',
  },
  fr: {
    subtitle: {
      single: {
        callYou: 'Veuillez me fournir vos coordonnées et je vous contacterai bientôt.',
        callUs: 'Rejoignez-moi par e-mail ou par téléphone via les actions en bas de cette page.',
      },
      plural: {
        callYou: 'Veuillez nous fournir vos coordonnées et nous vous contacterons bientôt.',
        callUs: 'Rejoignez-nous via les actions en bas de cette page.',
      },
    },
    submit: 'Soumettre',
    contactMethod: {
      preference: 'Comment préférez-vous être contacté ?',
      phone: 'Téléphone',
      email: 'Courriel',
    },
    fields: {
      firstName: 'Prénom*',
      lastName: 'Nom*',
      phone: 'Téléphone*',
      email: 'Adresse courriel*',
      phonePlaceholder: '(123) 456-7890',
    },
    validation: {
      required: 'Champ requis',
      phone: 'Téléphone invalide',
      email: 'Courriel invalide',
      firstName: 'Prénom invalide',
      lastName: 'Nom invalide',
      bestMoments: 'Une ou plusieurs plages horaires doivent être sélectionnées',
    },
    selectTimeSlots: 'Sélectionnez une ou plusieurs plages horaires ci-dessous',
    legal: '',
  },
};
