import { ReactElement } from 'react';
import styled from 'styled-components';

import { FormControlLabel, FormControlLabelProps, FormHelperText, MenuItem, Select, TextField } from '@breathelife/mui';

function FormControlLabelWithRest(
  props: FormControlLabelProps & {
    showError: boolean;
  },
): ReactElement {
  const { showError: __showError, ...rest } = props;
  return <FormControlLabel {...rest} />;
}

export const StyledFormControlLabel = styled(FormControlLabelWithRest)`
  &&&&& {
    background-color: unset;
    margin: unset;
    align-items: unset;
    .MuiFormControlLabel-label {
      padding-bottom: ${(props) => (props.label ? '8px' : '0')};
      color: ${(props) => (props.showError ? props.theme.colors.text.error : `unset`)};
      padding-left: unset;
      font-size: ${(props) => props.theme.fonts.size.input};
      line-height: 18px;
      letter-spacing: 0px;
      color: ${(props) => props.theme.colors.grey[90]};
    }
    &&:hover {
      background-color: unset;
    }
  }
`;

export const ReadonlyFormControlLabel = styled(StyledFormControlLabel)`
  && {
    cursor: default;
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  &&& {
    color: ${(props) => props.theme.colors.text.error};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &&:focus:not(:active) {
    border: ${(props) => `2px solid ${props.theme.colors.tertiary.default} !important`};
  }
`;

export const StyledSelect = styled(Select)<{ boldedBorder?: boolean }>`
  & .MuiOutlinedInput-notchedOutline {
    border-width: ${({ boldedBorder }) => (boldedBorder ? 2 : 1)}px;
  }
  background-color: ${(props) => props.theme.colors.white};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}) {
    .MuiSelect-select {
      white-space: initial !important;
    }
  }
`;

export const StyledTextField = styled(TextField)<{ highlighted?: boolean; boldedBorder?: boolean }>`
  background-color: ${(props) => (props.highlighted ? props.theme.colors.yellow[30] : props.theme.colors.white)};

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      // Positions the autofill content border within the MUI TextField's inner border
      box-sizing: border-box;
      border-radius: 0;

      margin: 2px 3px 3px;
      height: 51px;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border-width: ${({ boldedBorder }) => (boldedBorder ? 2 : 1)}px;
  }
`;
