import { ReactElement, Fragment } from 'react';
import styled from 'styled-components';

import { Box } from '@breathelife/mui';
import { IconName, Language } from '@breathelife/types';
import { Icon, RunningText, Statistic } from '@breathelife/ui-components';

import { LocalizationStrings } from './Localization';
import { OpeningHours } from './OpeningHours';
import { ContactPersonInfo, OpeningHoursType } from './types';
import { StringGrammarNumber } from '../../ContactForm';

export const LinkText = styled.a`
  color: ${(props) => props.theme.colors.base.primary};
  text-decoration: none;
  font-weight: ${(props) => props.theme.fonts.weight.heavy};
`;

type Props = {
  language: Language;
  localizationStrings: LocalizationStrings;
  customerServicePhoneNumber?: string;
  customerServiceEmail?: string;
  contactPersonInfo?: ContactPersonInfo;
  securityDisclosure?: string;
  openingHours?: OpeningHoursType;
  referenceNumber?: string;
  isPlural?: boolean;
};

export function AdvisorContactInfo(props: Props): ReactElement {
  const {
    language,
    localizationStrings,
    customerServicePhoneNumber,
    customerServiceEmail,
    contactPersonInfo,
    openingHours,
    referenceNumber,
    isPlural: isPluralProps = false,
  } = props;

  const textStrings = localizationStrings[language];
  const isPlural = !contactPersonInfo || isPluralProps;
  const noun = isPlural ? StringGrammarNumber.plural : StringGrammarNumber.single;

  return (
    <Fragment>
      <Box mt={2} mb={4}>
        <RunningText>{textStrings.subtitle[noun].callUs}</RunningText>
      </Box>
      {referenceNumber && (
        <Box my={3}>
          <Statistic value={referenceNumber} title={textStrings.referenceNumber} />
        </Box>
      )}

      {openingHours && (
        <span>
          <OpeningHours label={textStrings.openingHours[noun]} language={language} openingHours={openingHours} />
        </span>
      )}

      {customerServicePhoneNumber && (
        <LinkText href={`tel:${customerServicePhoneNumber}`}>
          <Icon name={IconName.phone} size='12' />
          <Box component='span' ml={1}>
            {customerServicePhoneNumber}
          </Box>
        </LinkText>
      )}

      {customerServiceEmail && (
        <Box my={1}>
          <LinkText href={`mailto:${customerServiceEmail}`}>
            <Icon name={IconName.mail} size='12' />
            <Box component='span' ml={1}>
              {textStrings.emailUs[noun]}
            </Box>
          </LinkText>
        </Box>
      )}
    </Fragment>
  );
}
