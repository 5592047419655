import { ReactElement, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';

export function CheckIcon(): ReactElement {
  const theme = useContext(ThemeContext);

  return (
    <svg width='12' height='10' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.4873 0.252427C13.2198 0.0486841 12.8848 -0.0368557 12.5563 0.0146416C12.2277 0.0661388 11.9324 0.250451 11.7354 0.526995L5.79489 8.8573L2.01739 5.73447C1.47563 5.30568 0.701501 5.40301 0.27508 5.95354C-0.151341 6.50407 -0.073607 7.30581 0.450034 7.75799L5.24636 11.7221C5.5141 11.9385 5.85478 12.0346 6.19179 11.9888C6.5288 11.943 6.83385 11.7592 7.03834 11.4787L13.757 2.06354C13.9538 1.78647 14.036 1.43992 13.9854 1.10022C13.9348 0.760526 13.7556 0.455538 13.4873 0.252427Z'
        fill={theme.components.RadioCard.bulletIconColor}
      />
    </svg>
  );
}

export const Bullet = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  box-shadow: 0 0 0 1px ${(props) => props.theme.colors.grey[60]};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  flex: none;
  background: ${(props) => props.theme.components.RadioCard.bulletBackgroundColor};
`;

export const Content = styled.div`
  p {
    color: ${(props) => props.theme.components.RadioCard.color};
  }
`;

export const RadioLabel = styled.label<{
  $showErrorBorder?: boolean;
  disabled?: boolean;
  boldedBorder?: boolean;
}>`
  display: block;
  height: 100%;
  background: ${(props) => props.theme.components.RadioCard.backgroundColor};
  border-radius: ${(props) => props.theme.components.RadioCard.borderRadius};
  min-height: ${(props) => props.theme.metrics.input.height};
  padding: 16px;
  box-sizing: border-box;
  border: ${({ boldedBorder }) => (boldedBorder ? 2 : 1)} solid
    ${(props) =>
      props.$showErrorBorder ? props.theme.colors.text.error : props.theme.components.RadioCard.borderColor};

  * {
    opacity: ${(props) => (props.disabled ? 0.8 : 1)};
  }

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${(props) => props.theme.colors.selection.background.hover};
        border: 1px solid
          ${(props) =>
            props.$showErrorBorder
              ? props.theme.colors.text.error
              : props.theme.components.RadioCard.hover.borderColor};
        ${Content} {
          & p {
            color: ${(props) => props.theme.components.RadioCard.hover.color};
          }
        }
        ${Bullet} {
          box-shadow: 0 0 0 1px ${(props) => props.theme.colors.grey[80]};
        }
      }
    `}
`;

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 1;
  height: 1px;
  z-index: -1;

  &:focus + ${RadioLabel} {
    outline: none;
    border-color: ${(props) => props.theme.colors.selection.border.focus};
    box-shadow: 0 0 0 2px ${(props) => props.theme.colors.selection.border.focus};

    & ${Content}, ${Content} * {
      color: ${(props) => props.theme.components.RadioCard.focus.color};
    }

    ${Bullet} {
      box-shadow: 0 0 0 3px ${(props) => props.theme.colors.selection.border.focus};
    }
  }

  &:checked + ${RadioLabel} {
    background: ${(props) => props.theme.components.RadioCard.selected.backgroundColor};
    border: 1px solid ${(props) => props.theme.components.RadioCard.selected.borderColor};
    & ${Content}, ${Content} * {
      color: ${(props) => props.theme.components.RadioCard.selected.color};
    }

    ${Bullet} {
      box-shadow: none;
      background: ${(props) => props.theme.components.RadioCard.selected.bulletBackgroundColor};
    }
  }
`;

export const Container = styled.div<{ marginless?: boolean }>`
  position: relative;
  margin-bottom: ${(props) => (props.marginless ? 0 : '12px')};
  text-align: left;

  &.inline {
    min-width: 134px;
    display: inline-block;
    margin-right: 10px;
  }
`;
