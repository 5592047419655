import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { IconName } from '@breathelife/types';
import { Box, Button } from '@breathelife/mui';

import { Icon } from '../Icon/Icon';
import { DraggableToolbar } from './DraggableToolbar';

const nodeWidth = 210;

type Props = {
  children?: ReactNode;
  onCreateNewApplication?: () => void;
  onLoadPrefillSteps?: () => void;
  onLoadNavigateSteps?: () => void;
  commitSha?: string;
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: rgba(170, 105, 255, 0.25);
  border: 3px solid rgba(170, 105, 255, 0.75);
  border-radius: 4px;
  width: ${nodeWidth}px;
  align-items: center;
`;

const StyledButton = styled(Button)`
  &&& {
    display: flex;
    width: 100%;
    margin: 5px 0;
    justify-content: center;
  }
`;

export const TextButton = styled.button.attrs({
  type: 'button',
  role: 'button',
})`
  background: none;
  border: none;
  padding: 0;
  color: ${(props) => props.theme.colors.base.primary};
`;

export function DebugToolbar(props: Props): ReactElement {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [parentComponentWidth, setParentComponentWidth] = useState(window.innerWidth);
  const [isDefaultPosition, setIsDefaultPosition] = useState(true);

  // Center component based on parent's width
  // Uses window width as default
  const [position, setPosition] = useState({
    x: (parentComponentWidth - nodeWidth) / 2,
    y: 5,
  });

  const onHackThePlanet = (): void => {
    const url = 'https://youtu.be/u3CKgkyc7Qo?t=18';
    window.open(url, '_blank');
  };

  const onRedirectToGithubCommit = (): void => {
    const url = `https://github.com/getbreathelife/bliss/commit/${props.commitSha}`;
    window.open(url, '_blank');
  };

  // Will only activate dragging when moved by 5 or more pixels
  // Otherwise works as a button
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5,
    },
  });

  // Updates position parameters which are then applied to the component's styling
  function handleDragEnd(event: any): void {
    setPosition((prevState) => {
      return {
        x: (prevState.x += event.delta.x),
        y: (prevState.y += event.delta.y),
      };
    });
    setIsDefaultPosition(false);
  }

  // Recenters component when parent component dimension changes
  // Only applies if component has not been manually dragged
  useEffect(() => {
    if (isDefaultPosition) {
      setPosition((prevState) => {
        return {
          x: (parentComponentWidth - nodeWidth) / 2,
          y: prevState.y,
        };
      });
    }
  }, [parentComponentWidth, isDefaultPosition]);

  return (
    // restrictToWindowEdges prevents moving component outside of view
    <DndContext modifiers={[restrictToWindowEdges]} sensors={useSensors(mouseSensor)} onDragEnd={handleDragEnd}>
      <DraggableToolbar
        styles={{
          left: `${position.x}px`,
          top: `${position.y}px`,
          cursor: 'grab',
        }}
        setParentComponentWidth={setParentComponentWidth}
      >
        <Container p={1}>
          <TextButton
            className={'debug-toolbar-handle'}
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            <strong>
              <span role='img' aria-label='laptop'>
                💻
              </span>
              {isMenuOpen ? 'Hide debug tools' : 'Show debug tools'}
            </strong>
          </TextButton>
          {isMenuOpen && (
            <Box my={1}>
              {!!props.commitSha && (
                <StyledButton
                  onClick={() => onRedirectToGithubCommit()}
                  variant='outlined'
                  color='primary'
                  startIcon={<Icon name={IconName.github} size='25px' />}
                >
                  {`Commit: ${props.commitSha.substring(0, 7)}`}
                </StyledButton>
              )}
              {props.onCreateNewApplication && (
                <StyledButton
                  onClick={props.onCreateNewApplication}
                  variant='outlined'
                  color='primary'
                  startIcon={
                    <span role='img' aria-label='gem'>
                      💎
                    </span>
                  }
                >
                  Create new application
                </StyledButton>
              )}
              {props.onLoadPrefillSteps && (
                <StyledButton
                  onClick={props.onLoadPrefillSteps}
                  variant='outlined'
                  color='primary'
                  startIcon={
                    <span role='img' aria-label='wand'>
                      🪄
                    </span>
                  }
                >
                  Pre-fill application
                </StyledButton>
              )}
              {props.onLoadNavigateSteps && (
                <StyledButton
                  onClick={props.onLoadNavigateSteps}
                  variant='outlined'
                  color='primary'
                  startIcon={
                    <span role='img' aria-label='helicopter'>
                      🚁
                    </span>
                  }
                >
                  Skip to step
                </StyledButton>
              )}
              <StyledButton
                onClick={onHackThePlanet}
                variant='outlined'
                color='primary'
                startIcon={
                  <span role='img' aria-label='planet'>
                    🌎
                  </span>
                }
              >
                Hack the planet
              </StyledButton>
              {props.children}
            </Box>
          )}
        </Container>
      </DraggableToolbar>
    </DndContext>
  );
}
