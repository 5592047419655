import { ReactElement, MouseEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../Styles/themed-styled-components';

import { AddIcon, Box } from '@breathelife/mui';

import Typography from '../../../../Components/Typography';
import { useAssistedApplicationContext } from '../../../../Hooks/useAssistedApplicationContext';
import { LeadPlatformTheme } from '../../../../Styles/Types';
import { AddInsuredButton, RemoveButton, RemoveIcon, TabBar, Tab } from './Styles';
import { ErrorCounter } from '../../../Badge/ErrorCounter';
import { CarrierContext } from '../../../../Context/CarrierContext';
import { Icon } from '../../../Icons';
import { ImageTypes } from '@breathelife/ui-components';

type Props = {
  maxTabs: number;
  onOpenDeleteProposedInsuredModal: (index: number) => void;
  showCompletionIcons: boolean;
};

export function ProposedInsuredTabBar(props: Props): ReactElement | null {
  const { maxTabs, onOpenDeleteProposedInsuredModal } = props;
  const { t } = useTranslation();
  const theme = useTheme() as LeadPlatformTheme;
  const {
    areAllFieldsDisabled,
    currentProposedInsuredIndex,
    proposedInsuredTabs,
    onSelectProposedInsured,
    onAddProposedInsured,
  } = useAssistedApplicationContext();

  const { features } = useContext(CarrierContext);

  if (proposedInsuredTabs.length === 0) return null;

  const selectedProposedInsuredAdjustedIndex =
    currentProposedInsuredIndex < 0 ||
    currentProposedInsuredIndex >= proposedInsuredTabs.length ||
    currentProposedInsuredIndex >= maxTabs
      ? 0
      : currentProposedInsuredIndex;

  const showAddButton = maxTabs > proposedInsuredTabs.length;

  const onRemoveProposedInsuredClick = (event: MouseEvent, index: number): void => {
    event.stopPropagation();
    onOpenDeleteProposedInsuredModal(index);
  };

  return (
    <TabBar>
      {proposedInsuredTabs.map(
        (tabData) =>
          tabData.index < maxTabs && (
            <Tab
              key={tabData.index}
              isActive={selectedProposedInsuredAdjustedIndex === tabData.index}
              onClick={() => onSelectProposedInsured(tabData.index)}
            >
              <Box display='flex' alignItems='center' justifyContent='center'>
                <Typography variant='h4'>
                  {tabData.name === '' ? t('assistedApplication.multiInsuredTabs.newProposedInsured') : tabData.name}
                </Typography>
                {tabData.completed && props.showCompletionIcons ? (
                  <Box ml={1} display='flex' justifyContent='center' alignItems='center'>
                    <Icon
                      width='15px'
                      height='15px'
                      name='circledCheckMark'
                      variant='green'
                      inverted
                      stroke='none'
                      imageType={ImageTypes.meaningful}
                      ariaLabel={t('assistedApplication.sectionComplete')}
                    />
                  </Box>
                ) : undefined}
                {!tabData.completed &&
                tabData.numberOfErrors &&
                features.incompleteFieldsSupport.enabled &&
                props.showCompletionIcons ? (
                  <Box ml={1}>
                    <ErrorCounter count={tabData.numberOfErrors} max={9} />
                  </Box>
                ) : undefined}
              </Box>
              {proposedInsuredTabs.length > 1 && (
                <RemoveButton
                  aria-label={t('assistedApplication.multiInsuredTabs.deleteProposedInsured', {
                    name: tabData.name,
                  })}
                  onClick={(e) => onRemoveProposedInsuredClick(e, tabData.index)}
                  disabled={areAllFieldsDisabled}
                >
                  <RemoveIcon />
                </RemoveButton>
              )}
            </Tab>
          ),
      )}
      {showAddButton && (
        <AddInsuredButton
          onClick={() => {
            onAddProposedInsured();
          }}
          disabled={areAllFieldsDisabled}
          aria-label={t('assistedApplication.multiInsuredTabs.addNewProposedInsured')}
        >
          <AddIcon htmlColor={theme.colors.primary.default} fontSize='small' />
        </AddInsuredButton>
      )}
    </TabBar>
  );
}
