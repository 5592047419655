import { useMemo } from 'react';
import _ from 'lodash';
import { Cloudinary } from 'cloudinary-core';

import { AttributeName, LinkAttributes } from './useSetTags';

type CloudinaryProps = {
  cloudName: string;
  folder: string;
};

type FaviconProps = {
  type: string;
  size: number;
  href: string;
};

enum FaviconTypes {
  icon = 'shortcut icon',
  appleTouchIcon = 'apple-touch-icon',
}

const faviconOptions: Omit<FaviconProps, 'href'>[] = [
  {
    type: FaviconTypes.icon,
    size: 32,
  },
  {
    type: FaviconTypes.icon,
    size: 192,
  },
  {
    type: FaviconTypes.icon,
    size: 196,
  },
  {
    type: FaviconTypes.appleTouchIcon,
    size: 120,
  },
  {
    type: FaviconTypes.appleTouchIcon,
    size: 152,
  },
  {
    type: FaviconTypes.appleTouchIcon,
    size: 167,
  },
  {
    type: FaviconTypes.appleTouchIcon,
    size: 180,
  },
];

type LinkProps = {
  cloudinaryConfig?: CloudinaryProps;
  compactLogoUrl?: string;
};

// Hook to convert cloudinary specific props into generic link attributes
export function useGetCloudinaryLinkAttributes(linkPropsArray?: LinkProps[]): LinkAttributes[] | undefined {
  return useMemo(() => {
    if (!_.isArray(linkPropsArray) || linkPropsArray.length === 0) {
      return undefined;
    }
    const linkAttributeArray: LinkAttributes[] = [];
    linkPropsArray.forEach((linkProps) => {
      const { cloudinaryConfig, compactLogoUrl } = linkProps;
      if (!cloudinaryConfig || !compactLogoUrl) {
        return;
      }
      const publicId = retrieveCloudinaryPublicIdFromUrl(compactLogoUrl, cloudinaryConfig.folder || '');

      if (!isCloudinarySrc(compactLogoUrl) || !publicId) {
        return;
      }

      faviconOptions.forEach((favicon: Omit<FaviconProps, 'href'>) => {
        const cloudinaryUrl = transformCloudinaryUrl(cloudinaryConfig, publicId, favicon.size);

        if (favicon.type === FaviconTypes.appleTouchIcon) {
          linkAttributeArray.push({
            [AttributeName.href]: cloudinaryUrl,
            [AttributeName.rel]: favicon.type,
            [AttributeName.sizes]: `${favicon.size}x${favicon.size}`,
          });
        } else {
          linkAttributeArray.push({
            [AttributeName.href]: cloudinaryUrl,
            [AttributeName.rel]: favicon.type,
            [AttributeName.type]: 'image/png',
          });
        }
      });
    });
    return linkAttributeArray.length > 0 ? linkAttributeArray : undefined;
  }, []);
}

function retrieveCloudinaryPublicIdFromUrl(src: string, folder: string): string {
  // Saved cloudinary picture url example in auth0:
  // "https://res.cloudinary.com/breathelife/image/upload/v1582923345/px/breathelife/filename.jpg"
  // First, splitting on '.' and getting the two last item in the array. Then we retrieve the first position of that two last item.
  // We end up having 'com/breathelife/image/upload/v1582923345/px/breathelife/filename' , so we split on the '/' and retrieve the last position
  // to get the filename
  const fileName = _.last(src.split('.').slice(-2)[0].split('/'));

  return `${folder}/${fileName}`;
}

function isCloudinarySrc(src: string): boolean {
  try {
    const url = new URL(src);
    return url.hostname.includes('cloudinary');
  } catch (e: any) {
    return false;
  }
}

function transformCloudinaryUrl(cloudinaryConfig: CloudinaryProps, publicId: string, size: number): string {
  const cloudinary = new Cloudinary({ cloud_name: cloudinaryConfig.cloudName });
  const transformedUrl = cloudinary.url(publicId, {
    width: size,
    height: size,
    crop: 'fill',
  });

  return transformedUrl;
}
