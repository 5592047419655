import HtmlReactParser from 'html-react-parser';
import { ReactElement, Fragment } from 'react';

import { PlanFinderFooter as PlanFinderBaseFooter } from '../../Components/Footer/PlanFinderFooter';

import { text } from '../../Localization/Localizer';

function getLegalItems(scope?: string): string[] {
  const legalItemDefinitions = text('legal.footer');
  const legalItemKeys = Object.keys(text('legal.footer'));

  return legalItemKeys.map((itemKey: string) => {
    const legalItem = legalItemDefinitions[itemKey as any];

    if (typeof legalItem === 'object') {
      if (scope && Object.keys(legalItem).includes(scope)) {
        return legalItem[scope];
      }

      return '';
    }

    return text(`legal.footer.${itemKey}`);
  });
}

export function PlanFinderFooter(): ReactElement {
  const legalItems = getLegalItems('life');

  return (
    <PlanFinderBaseFooter>
      {legalItems.map((item, index) => (
        <Fragment key={index}>{HtmlReactParser(item)}</Fragment>
      ))}
    </PlanFinderBaseFooter>
  );
}
