import { ReactElement } from 'react';
import { WidgetCardContainer, WidgetCardHeader } from './Styles';
import { useViewNextIncompleteFieldContext } from '../ViewNextIncompleteFieldContext';
import { Button } from '../../../Button/Button';
import { useTranslation } from 'react-i18next';
import Typography from '../../../Typography';
import { useTheme } from '../../../../Styles/themed-styled-components';
import { Box, Tooltip } from '@breathelife/mui';
import { Icon } from '../../../Icons';

export function ViewNextIncompleteFieldWidget(): ReactElement {
  const { onClickViewNextIncompleteFieldButton, applicationHasIncompleteFields } = useViewNextIncompleteFieldContext();
  const { t } = useTranslation();
  const theme = useTheme();
  return applicationHasIncompleteFields ? (
    <WidgetCardContainer>
      <WidgetCardHeader>
        <Typography variant='h4' component='h2'>
          {t('assistedApplication.viewNextIncompleteFieldWidget.title')}
        </Typography>
        <Tooltip title={t('assistedApplication.viewNextIncompleteFieldWidget.instructions')}>
          <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: -14, left: -6 }}>
            <Icon name='info' width='12px' height='12px' stroke={theme.colors.primary[40]} />
          </Box>
        </Tooltip>
        <Button variant='contained' onClick={onClickViewNextIncompleteFieldButton}>
          {t('assistedApplication.viewNextIncompleteFieldWidget.buttonLabel')}
        </Button>
      </WidgetCardHeader>
    </WidgetCardContainer>
  ) : (
    <div />
  );
}
