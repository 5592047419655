import { useEffect } from 'react';
import _ from 'lodash';

import { Language } from '@breathelife/types';

export enum AttributeName {
  content = 'content',
  href = 'href',
  lang = 'lang',
  name = 'name',
  rel = 'rel',
  sizes = 'sizes',
  text = 'text',
  title = 'title',
  type = 'type',
}

export type HelmetProps = {
  defaultCarrierLanguage?: Language;
  linkAttributes?: LinkAttributes[];
  metaAttributes?: MetaAttributes[];
  titleAttributes: TitleAttributes;
};

export type LinkAttributes = {
  href: string;
  rel: string;
  sizes?: string;
  type?: string;
};

type MetaAttributes = {
  content?: string;
  name: string;
};

enum TagName {
  head = 'head',
  html = 'html',
  link = 'link',
  meta = 'meta',
  title = 'title',
}

type TitleAttributes = {
  text?: string;
  title: string;
};

export function useSetTags(props: HelmetProps): void {
  const { defaultCarrierLanguage, linkAttributes, metaAttributes, titleAttributes } = props;
  return useEffect(() => {
    setTag([getLanguageAttribute(defaultCarrierLanguage)], TagName.html, [AttributeName.lang]);
    setTag([buildTitle(titleAttributes)], TagName.title);
    setMetaTags(metaAttributes);
    setLinkTags(linkAttributes);
  }, [defaultCarrierLanguage, linkAttributes, metaAttributes, titleAttributes]);
}

function setTag(values: string[], tagName: TagName, attributeNames?: AttributeName[], identifier?: string): void {
  const element = document.getElementsByTagName(TagName.html)[0];
  if (!element) {
    return;
  }

  switch (tagName) {
    case TagName.html:
      if (!attributeNames || attributeNames.length === 0) {
        break;
      }
      attributeNames.forEach((attributeName, index) => {
        if (values[index]) {
          element.setAttribute(attributeName, values[index]);
        }
      });
      break;
    case TagName.link:
      if (!attributeNames || attributeNames.length === 0) {
        break;
      }

      const linkTags = document.getElementsByTagName(TagName.link);
      let linkTag = Object.values(linkTags).find((linkTag) => {
        return linkTag.getAttribute(AttributeName.href) === identifier;
      });

      if (!linkTag) {
        linkTag = document.createElement(TagName.link);
        document.getElementsByTagName(TagName.head)[0].appendChild(linkTag);
      }

      attributeNames.forEach((attributeName, index) => {
        if (values[index]) {
          linkTag?.setAttribute(attributeName, values[index]);
        }
      });
      break;
    case TagName.meta:
      if (!attributeNames || attributeNames.length === 0) {
        break;
      }

      const metaTags = document.getElementsByTagName(TagName.meta);
      let metaTag = Object.values(metaTags).find((metaTag) => {
        return metaTag.getAttribute(AttributeName.name) === identifier;
      });

      if (!metaTag) {
        metaTag = document.createElement(TagName.meta);
        document.getElementsByTagName(TagName.head)[0].appendChild(metaTag);
      }

      attributeNames.forEach((attributeName, index) => {
        if (values[index]) {
          metaTag?.setAttribute(attributeName, values[index]);
        }
      });
      break;
    case TagName.title:
      if (values[0]) {
        document.title = values[0];
      }
      break;
    default:
  }
}

function getLanguageAttribute(defaultCarrierLanguage?: Language): Language {
  const currentLanguage = localStorage.getItem('locale') as Language | undefined;
  const fallbackLanguage = defaultCarrierLanguage ?? Language.en;

  if (!currentLanguage || !Object.values(Language).includes(currentLanguage)) return fallbackLanguage;

  return currentLanguage;
}

function buildTitle(titleAttributes: TitleAttributes): string {
  const { text = '', title } = titleAttributes;
  const newText = titleAttributes?.text ? `${text} - ` : '';
  return `${newText}${title}`;
}

function setMetaTags(metaAttributesArray?: MetaAttributes[]): void {
  if (!_.isArray(metaAttributesArray) || metaAttributesArray.length === 0) {
    return;
  }
  metaAttributesArray.forEach((metaAttributes) => {
    setTag(
      Object.values(metaAttributes),
      TagName.meta,
      Object.keys(metaAttributes) as AttributeName[],
      metaAttributes.name,
    );
  });
}

function setLinkTags(linkAttributesArray?: LinkAttributes[]): void {
  if (!_.isArray(linkAttributesArray) || linkAttributesArray.length === 0) {
    return;
  }
  linkAttributesArray.forEach((linkAttributes) => {
    setTag(
      Object.values(linkAttributes),
      TagName.link,
      Object.keys(linkAttributes) as AttributeName[],
      linkAttributes.href,
    );
  });
}
