import { logger } from '@breathelife/monitoring-frontend';
import { PaymentServiceProvider, PaymentTransaction } from '@breathelife/types';

/**
 * This interface is designed to allow consuming components to perform vendor agnostic payment operations and validation
 * within the context of the lead platform's assisted application
 */
export interface PaymentProviderOperations {
  /**
   * Validates if the payment transaction is ready to be submitted within the context of the assisted application
   *
   * @param paymentTransaction A payment transaction record
   */
  validatePaymentTransactionCompletion: (paymentTransaction: PaymentTransaction) => boolean;
}

const PaymentProviderOperationsLookup: { [key in PaymentServiceProvider]: PaymentProviderOperations | undefined } = {
  [PaymentServiceProvider.STRIPE]: {
    validatePaymentTransactionCompletion(paymentTransaction) {
      if (!paymentTransaction) return false;

      const { paymentServiceProvider, paymentToken } = paymentTransaction;

      if (paymentServiceProvider !== PaymentServiceProvider.STRIPE) {
        logger.error(`Attempting to perform Stripe payment validation on a ${paymentServiceProvider} transaction`);
        return false;
      }

      return paymentToken?.status === 'succeeded';
    },
  },
  [PaymentServiceProvider.CLOVER]: undefined,
};

/**
 * Returns the passed payment service provider's payment operations object. This function is designed to allow
 * its consumer to initiate payment operations without knowing which payment provider is active
 *
 * @param paymentProvider A valid payment service provider
 * @returns The payment service provider's payment operations
 */
export function getPaymentProviderOperations(
  paymentProvider: PaymentServiceProvider,
): PaymentProviderOperations | undefined {
  const provider = PaymentProviderOperationsLookup[paymentProvider];

  if (!provider) {
    logger.error(`Payment provider ${paymentProvider} is not supported`);
  }

  return provider;
}
