import { createContext } from 'react';

import { CloudinaryConfig, ConsumerFlow } from '@breathelife/types';
import { Features } from '../Models/Features';
import { ConsumerFlowTheme, ConsumerFlowImages } from '../types';

export type CarrierInfo = {
  companyName: string;
  logo: string;
  logoCompact?: string;
  indexPageTitle: string;
  productTransitionLoadingDuration?: { life: number };
  useAdvisorTitleFallback: boolean;
  contactPhoneNumber?: string;
  contactEmail?: string;
  vendorCodeNodeId?: string; // TODO: FIXME: remove me
};

export type ContextProps = {
  features: Features;
  layout: ConsumerFlow.Layout;
  carrierInfo: CarrierInfo;
  cloudinaryConfig?: CloudinaryConfig;
  enableLoadCarrierNamesFromDb?: boolean;
  enableSalesDecisionRules: boolean;
  images: ConsumerFlowImages;
  iconMap: Record<string, string>;
  enableHeaderProductSummary: boolean;
  pdfFetchDuration?: number;
  theme?: ConsumerFlowTheme;
  interlocutorIds?: string[];
  enableQuestionnaireDebugTools?: boolean;
  displayFullAdvisorNameOnAdvisorGreetingMessage: boolean;
  git: {
    commitSha: string;
  };
};

export const defaultFeatures: Features = {
  productPage: {
    enabled: false,
  },
  selfServeApplication: {
    enabled: false,
  },
  signature: {
    enabled: false,
  },
  payments: {
    enabled: false,
  },
};

export const CarrierContext = createContext({} as ContextProps);
