import dayjs from 'dayjs';
import { ReactElement, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { ApplicationSupportAssociatedFile } from '@breathelife/types';

import { Table } from '../../../../Components/Table/Table';
import { printFileSize } from '../../../../Helpers/fileSize';
import { AST_DATE_FORMAT } from '../ApplicationOverview/ApplicationOverviewContainer';

type AssociatedFilesTableProps = {
  associatedFiles: ApplicationSupportAssociatedFile[];
};

export function AssociatedFilesTable(props: AssociatedFilesTableProps): ReactElement {
  const { associatedFiles } = props;
  const { t } = useTranslation();
  const columns = useMemo<
    {
      Header: string;
      accessor: keyof ApplicationSupportAssociatedFile;
      Cell?: (props: CellProps<ApplicationSupportAssociatedFile>) => ReactElement;
      disableSortBy?: boolean;
    }[]
  >(
    () => [
      {
        Header: t('applicationSupport.associatedFiles.fileType'),
        accessor: 'fileType',
      },
      {
        Header: t('applicationSupport.associatedFiles.fileName'),
        accessor: 'fileName',
      },
      {
        Header: t('applicationSupport.associatedFiles.fileSize'),
        accessor: 'fileSize',
        Cell: function ({ cell: { value } }: { cell: { value: string } }): ReactElement {
          return <Fragment>{printFileSize(Number(value), 2)}</Fragment>;
        },
      },
      {
        Header: t('applicationSupport.associatedFiles.createdAt'),
        accessor: 'createdAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): ReactElement {
          return <Fragment>{value ? dayjs(value).format(AST_DATE_FORMAT) : ''}</Fragment>;
        },
      },
      {
        Header: t('applicationSupport.associatedFiles.mimeType'),
        accessor: 'mimeType',
      },
    ],
    [t],
  );

  return <Table<ApplicationSupportAssociatedFile> columns={columns} data={associatedFiles} />;
}
