import { ReactElement, useEffect, useCallback, useState } from 'react';
import { NumericFormat, NumberFormatValues } from 'react-number-format';

import { TextFieldProps, InputAdornment } from '@breathelife/mui';
import { NumericalDataType } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { FieldProps, formatRequiredFieldTitle, InputVariant } from './Helpers/FieldHelper';
import { Input } from './TextInput';

type Props = TextFieldProps &
  Omit<FieldProps, 'onAnswerChange'> & {
    boldedBorder?: boolean;
    value?: number;
    onAnswerChange?: (answer: string) => void;
    onAnswerComplete?: (fieldId: string, answer: string, previousAnswer: string) => void;
    inputVariant?: InputVariant;
    locale?: Language;
    isCurrency?: boolean;
    numericalDataType?: NumericalDataType;
    allowTextWrap?: boolean;
  };

function stringifyValue(value?: any): string {
  if (value == null) return '';
  if (typeof value === 'number') return JSON.stringify(value);
  return value;
}

function getNumericalDataType(isCurrency: boolean, numericalDataType?: NumericalDataType): NumericalDataType {
  if (numericalDataType) return numericalDataType;
  if (isCurrency) return 'float';
  return 'integer';
}

export function NumberInput(props: Props): ReactElement {
  const {
    allowTextWrap,
    disabled,
    id,
    inputVariant,
    isCurrency,
    label,
    locale,
    name,
    numericalDataType: numericalDataTypeProp,
    onAnswerChange,
    onAnswerComplete,
    optionalText,
    placeholder,
    required,
    subtitle,
    title,
    validationError,
    value: defaultValue,
    boldedBorder,
  } = props;
  const [value, setValue] = useState<string>(stringifyValue(defaultValue));
  const [valueOnPreviousBlur, setValueOnPreviousBlur] = useState<string>(stringifyValue(defaultValue));

  const isLocaleFrench = locale === Language.fr;

  const numericalDataType = getNumericalDataType(!!isCurrency, numericalDataTypeProp);

  const InputProps: Record<string, any> = {
    inputProps: { inputMode: 'decimal' },
    required,
  };

  if (isCurrency) {
    if (isLocaleFrench) {
      InputProps.endAdornment = <InputAdornment position='end'>$</InputAdornment>;
    } else {
      InputProps.startAdornment = <InputAdornment position='start'>$</InputAdornment>;
    }
  }

  useEffect(() => {
    setValue(stringifyValue(defaultValue));
  }, [defaultValue]);

  const onBlur = (): void => {
    if (value === String(defaultValue)) {
      return;
    }
    if (onAnswerChange && typeof value !== 'undefined') {
      onAnswerChange(value);
    }
    if (onAnswerComplete && typeof value !== 'undefined') {
      onAnswerComplete(name, value, valueOnPreviousBlur);
    }
    setValueOnPreviousBlur(value);
  };

  const updateValue = useCallback((newValue: NumberFormatValues) => {
    setValue(newValue.formattedValue);
  }, []);

  const thousandSeparator = isCurrency && (isLocaleFrench ? ' ' : ',');
  const decimalSeparator = isLocaleFrench ? ',' : '.';
  const formattedValue = isLocaleFrench ? value.replace(/\./, ',') : value;

  const labelValue = formatRequiredFieldTitle(required, title, label, optionalText);

  return (
    <NumericFormat
      id={id}
      data-testid={props['data-testid']}
      name={name}
      error={!!validationError}
      validationError={validationError}
      label={labelValue}
      placeholder={placeholder}
      customInput={Input}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      allowNegative={false}
      allowedDecimalSeparators={isLocaleFrench ? [',', '.'] : ['.']}
      decimalScale={numericalDataType === 'integer' ? 0 : 2}
      displayType='input'
      value={formattedValue}
      onValueChange={updateValue}
      onBlur={onBlur}
      boldedBorder={boldedBorder}
      // TextField props
      inputVariant={inputVariant}
      InputProps={InputProps}
      subtitle={labelValue ? subtitle : formatRequiredFieldTitle(required, subtitle, '')}
      allowTextWrap={allowTextWrap}
      disabled={disabled}
    />
  );
}
