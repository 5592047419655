import _ from 'lodash';

import { InsuranceProduct, Language } from '@breathelife/types';

import { LeadPlatformStore } from '../../ReduxStore/types';
import { logger } from '@breathelife/monitoring-frontend';

type AvailableProductsAndRiders = {
  availableParents: InsuranceProduct[];
  availableRiders: InsuranceProduct[];
};

export const getLinkableProductsForTheSelectedProduct =
  (language: Language) =>
  (store: LeadPlatformStore): AvailableProductsAndRiders => {
    const availableRiders: InsuranceProduct[] = [];
    const availableParents: InsuranceProduct[] = [];

    const products = store.leadPlatform.productManagement.products;
    const selectedProduct = store.leadPlatform.productManagement.selectedProduct;

    for (const product of products) {
      if (selectedProduct && selectedProduct.id === product.id) {
        continue;
      }

      if (product.isRider) {
        availableRiders.push(product);
      } else {
        availableParents.push(product);
      }
    }

    const alphabetically = (a: InsuranceProduct, b: InsuranceProduct): number => {
      const nameA = a.name[language];
      const nameB = b.name[language];

      if (typeof nameA === 'undefined' || typeof nameB === 'undefined') {
        logger.error('Could not compare product names');
        return -1;
      }

      return nameA.localeCompare(nameB, language, {
        sensitivity: 'base',
      });
    };

    return {
      availableParents: availableParents.sort(alphabetically),
      availableRiders: availableRiders.sort(alphabetically),
    };
  };
