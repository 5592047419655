import ReactHtmlParser from 'html-react-parser';
import { ReactNode, ReactElement, Fragment, useCallback } from 'react';

import { Box, Grid } from '@breathelife/mui';
import { OptionWidth, RenderingFieldOption, RenderingInfoSupplement } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';
import {
  FieldProps,
  formatRequiredFieldTitle,
  Icon,
  InfoSupplement,
  Label,
  RadioCard,
  SimpleRadio,
  SimpleRadioGroup,
  WarningText,
} from '@breathelife/ui-components';

import { translate } from '../../../Localization/Localizer';
import { StyleVariant } from '../../FieldGenerator/FieldGenerator';
import { TextWithSuperscriptHighlight, Title } from '../Checkbox/Styles';
import {
  ContentContainer,
  Extra,
  FullWidthLabel,
  OptionContainer,
  StyledGridItem,
  StyledRadioCardContainer,
} from './Styles';

type RadioGroupProps = Omit<FieldProps, 'onAnswerChange'> & {
  id: string;
  options: RenderingFieldOption[];
  required?: boolean;
  value?: string;
  optionWidth?: OptionWidth;
  onAnswerChange?: (answer: string) => void;
  onAnswerComplete?: (fieldId: string, answer: string, previousAnswer?: string) => void;
  styleVariant: StyleVariant;
  displayOnlySelected?: boolean;
  iconMap?: Record<string, string>;
  onInfoIconClick?: () => void;
  locale?: Language;
  renderingInfoSupplement?: RenderingInfoSupplement;
  infoSupplementImage?: { src: string; alt?: string };
  boldedBorder?: boolean;
};

function isOptionChecked(answer: string, optionId: string): boolean {
  return answer === optionId;
}

export function RadioGroup(props: RadioGroupProps): ReactElement {
  const {
    disabled,
    iconMap,
    id,
    infoSupplementImage,
    label,
    locale,
    name,
    onAnswerChange,
    onAnswerComplete,
    onInfoIconClick,
    optionalText,
    options,
    optionWidth,
    renderingInfoSupplement,
    required,
    styleVariant,
    subtitle,
    title,
    validationError,
    value,
    boldedBorder,
  } = props;
  const showError = !!validationError;
  const validationMessageId = `${id}-error`;

  const labelValue = formatRequiredFieldTitle(required, title, label, optionalText);

  const onChange = useCallback(
    (value: string) => {
      onAnswerChange?.(value);
      onAnswerComplete?.(name, value);
    },
    [name, onAnswerChange, onAnswerComplete],
  );

  if (styleVariant === StyleVariant.consumer) {
    const optionCards: ReactNode[] = [];
    const infoSupplements: ReactNode[] = [];
    options.forEach((option) => {
      const isChecked = isOptionChecked(value as string, option.id);
      optionCards.push(
        <RadioCard
          key={option.id}
          optionId={option.id}
          ariaLabel={option.text}
          groupName={id}
          checked={isChecked}
          onChange={onChange}
          suffix={option.iconName ? <Icon name={option.iconName} size='56px' /> : undefined}
          showErrorBorder={showError}
          boldedBorder={boldedBorder}
          disabled={disabled}
          marginless
        >
          {option.title ? <Title>{ReactHtmlParser(option.title)}</Title> : null}
          <TextWithSuperscriptHighlight selected={isChecked}>
            {ReactHtmlParser(option.text)}
          </TextWithSuperscriptHighlight>
        </RadioCard>,
      );

      if (option.info) {
        const { title, text, iconNumber, modalOptions, image } = option.info;

        const imageSrc = image && iconMap?.[image.name];
        const imageInfo = imageSrc
          ? {
              src: imageSrc,
              alt: image?.alt,
            }
          : undefined;

        infoSupplements.push(
          <Box mt={1.5}>
            <InfoSupplement
              text={text}
              title={title}
              image={imageInfo}
              modalOptions={modalOptions}
              iconNumber={iconNumber}
              usePill
              onClick={onInfoIconClick}
            />
          </Box>,
        );
      }
    });

    return (
      <Fragment>
        {labelValue && (
          <Box mb={1}>
            <Label htmlFor={id}>{ReactHtmlParser(labelValue)}</Label>
            {subtitle && (
              <FullWidthLabel htmlFor={id} grey={80}>
                {ReactHtmlParser(subtitle)}
              </FullWidthLabel>
            )}
          </Box>
        )}
        <ContentContainer>
          <OptionContainer>
            <StyledRadioCardContainer
              id={id}
              aria-errormessage={validationMessageId}
              aria-required={required}
              optionWidth={optionWidth}
            >
              {optionCards}
            </StyledRadioCardContainer>
            {infoSupplements}
          </OptionContainer>
          {renderingInfoSupplement && (
            <Extra>
              <InfoSupplement
                title={renderingInfoSupplement.title}
                text={renderingInfoSupplement.text}
                image={infoSupplementImage}
                modalOptions={renderingInfoSupplement.modalOptions}
                onClick={onInfoIconClick}
              />
            </Extra>
          )}
        </ContentContainer>
        <Box component={WarningText} mt={2} role='alert' id={validationMessageId}>
          {showError && (validationError?.message || translate('validation.radioCheckbox', { locale }))}
        </Box>
      </Fragment>
    );
  }

  const fullLine = optionWidth === 'full';

  return (
    <Fragment>
      <SimpleRadioGroup
        id={id}
        required={required}
        label={labelValue}
        subtitle={subtitle}
        name={name}
        onChange={onChange}
        value={value}
      >
        <Grid container spacing={1} direction={fullLine ? 'column' : 'row'}>
          {options.map((option) => {
            return (
              <StyledGridItem item xs={12} sm={fullLine ? 6 : 3} key={option.id}>
                <SimpleRadio
                  id={id}
                  name={name}
                  value={option.id}
                  label={option.text}
                  disabled={disabled}
                  hasError={!!validationError}
                  required={required}
                  boldedBorder={boldedBorder}
                />
              </StyledGridItem>
            );
          })}
        </Grid>
      </SimpleRadioGroup>
      {showError && (
        <Box component={WarningText} mt={2} role='alert' id={validationMessageId}>
          {validationError?.message || translate('validation.radioCheckbox', { locale })}
        </Box>
      )}
    </Fragment>
  );
}
