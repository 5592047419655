import ReactHtmlParser from 'html-react-parser';
import { ReactElement, useCallback } from 'react';
import { useTheme } from 'styled-components';

import { Loader, SharedTheme } from '@breathelife/ui-components';
import { Container, NextButton, PreviousButton } from './Styles';

export type NavigationButtonProps = {
  onPreviousClick: () => void;
  onNextClick: () => void;
  hidePrevious?: boolean;
  hideNext?: boolean;
  disablePrevious?: boolean;
  disableNext?: boolean;
  nextButtonText?: string;
  nextButtonId?: string;
  invertedPreviousArrowColor?: boolean;
  className?: string;
  nextButton?: ReactElement;
  isNextBtnLoading?: boolean;
  isPreviousBtnLoading?: boolean;
};

function BackArrowSvg(): ReactElement {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <path d='M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z' />
    </svg>
  );
}

function ForwardArrowSvg(): ReactElement {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <path d='M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z' />
    </svg>
  );
}

export function NavigationButtons(props: NavigationButtonProps): ReactElement | null {
  const theme = useTheme() as SharedTheme;

  const onButtonClick = useCallback((onClick: () => void): void => {
    // @ts-ignore
    document.activeElement && document.activeElement?.blur && document.activeElement.blur();
    onClick();
  }, []);

  const onNextClick = useCallback(() => {
    if (props.isNextBtnLoading) return;
    onButtonClick(props.onNextClick);
  }, [onButtonClick, props.isNextBtnLoading, props.onNextClick]);

  const onPreviousClick = useCallback(() => {
    if (props.isPreviousBtnLoading) return;
    onButtonClick(props.onPreviousClick);
  }, [onButtonClick, props.isPreviousBtnLoading, props.onPreviousClick]);

  if (props.hideNext && props.hidePrevious) return null;

  const nextButton = props.nextButton ?? (
    <NextButton
      id={props.nextButtonId}
      type='submit'
      className='next-arrow-button'
      disabled={props.disableNext}
      data-testid='next'
      onClick={onNextClick}
    >
      {props.isNextBtnLoading ? (
        <Loader size='24px' color={theme.colors.grey[0]} />
      ) : props.nextButtonText ? (
        ReactHtmlParser(props.nextButtonText)
      ) : (
        <ForwardArrowSvg />
      )}
    </NextButton>
  );

  return (
    <Container className={props.className} hidePrevious={props.hidePrevious}>
      {props.hidePrevious ? (
        <div />
      ) : (
        <PreviousButton
          id='quote-application-back'
          className='previous-arrow-button'
          disabled={props.disablePrevious}
          onClick={onPreviousClick}
          data-testid='previous'
          type='button'
          aria-label={'Go back'}
          invertedPreviousArrowColor={props.invertedPreviousArrowColor}
        >
          {props.isPreviousBtnLoading ? <Loader size='24px' /> : <BackArrowSvg />}
        </PreviousButton>
      )}
      {props.hideNext ? <div /> : nextButton}
    </Container>
  );
}

NavigationButtons.defaultProps = {
  nextButtonId: 'confirm_button',
  hideNext: false,
  hidePrevious: false,
  disableNext: false,
  disablePrevious: false,
  invertedPreviousArrowColor: false,
};
