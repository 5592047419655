import { ReactElement, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import {
  deserializeNodeIdToAnswerPathMap,
  NodeIdToAnswerPathMap,
  QuestionnaireEngine,
  RenderingQuestionnaire,
} from '@breathelife/questionnaire-engine';
import {
  Application,
  RenderingType,
  SignatureType,
  DynamicPdfInsuranceEntities,
  Theme,
  ProductAddon,
  DynamicPdfType,
  ApplicationPointOfSaleDecisions,
  Timezone,
  DEFAULT_TIMEZONE_NAME,
  SignatureFieldType,
  VersionedAnswers,
  LineOfBusinessName,
  AdvisorPdfOptions,
  CarrierPdfOptions,
  InsuredPdfOptions,
  PdfOptions,
  Language,
  DEFAULT_BLUEPRINT,
} from '@breathelife/types';
import { Loader, getColorizedBaseTheme } from '@breathelife/ui-components';

import { PdfContext } from '../Context/PdfContext';
import { fetchDynamicPdfData, fetchTheme } from '../Services/PdfService';
import { defaultPdfFunctions } from '../helpers/defaultPdfFunctions';
import { generateSignees } from '../helpers/generateSignees';
import { CryptoSignatureData, PdfData, SignatureData, Signers } from '../types';
import { AdvisorPdf, CarrierPdf, InsuredPdf } from './PdfTypes';

type DataFetchingContainerProps = {
  applicationId: string;
  pdfType: DynamicPdfType;
  pdfOptions: PdfOptions;
  currency: string;
  language: Language;
  signatureType: SignatureType | undefined;
  eSignatureFieldType: SignatureFieldType | undefined;
};

// TODO: See if it's possible to make text getter optional in QuestionnaireEngine
function text(key: string): string {
  const mapping: { [key: string]: string } = {};
  return mapping[key];
}

export function DataFetchingContainer(props: DataFetchingContainerProps): ReactElement | null {
  const { applicationId, pdfType, language, pdfOptions, currency, signatureType, eSignatureFieldType } = props;
  const [application, setApplication] = useState<Application | null>(null);

  const [renderingQuestionnaire, setRenderingQuestionnaire] = useState<RenderingQuestionnaire | null>(null);
  const [nodeIdToAnswerPathMap, setNodeIdToAnswerPathMap] = useState<NodeIdToAnswerPathMap | null>(null);
  const [signatureData, setSignatureData] = useState<SignatureData>({
    signers: {} as Signers,
    isExternalSignature: true,
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [insuranceEntities, setInsuranceEntities] = useState<DynamicPdfInsuranceEntities>();
  const [addons, setAddons] = useState<ProductAddon[]>();

  const [dbThemeLoaded, setDbThemeLoaded] = useState<boolean>(false);
  const [dbTheme, setDbTheme] = useState<Theme | null>(null);

  const [pointOfSaleDecisions, setPointOfSaleDecisions] = useState<ApplicationPointOfSaleDecisions | null>(null);

  useEffect(() => {
    if (isLoaded) return;
    async function fetchData(): Promise<void> {
      const dynamicPdfData = await fetchDynamicPdfData(applicationId, pdfType, language);
      setIsLoaded(true);
      if (!dbThemeLoaded) {
        const theme = await fetchTheme({ mgaId: dynamicPdfData.application.mgaId });
        setDbTheme(theme);
        setDbThemeLoaded(true);
      }

      setApplication(dynamicPdfData.application);
      setInsuranceEntities(dynamicPdfData.entities);
      setAddons(dynamicPdfData.addons);

      setPointOfSaleDecisions(dynamicPdfData.pointOfSaleDecisions);

      const deserializedNodeIdToAnswerPathMap = deserializeNodeIdToAnswerPathMap(dynamicPdfData.nodeIdToAnswerPathMap);
      setNodeIdToAnswerPathMap(deserializedNodeIdToAnswerPathMap);

      const { answers, answersV2, lang, applicationContext } = dynamicPdfData.application;

      const timezoneResult = Timezone.from(application?.timezone || DEFAULT_TIMEZONE_NAME);

      if (timezoneResult.success === false) {
        throw new Error('Could not determined the timezone to use to render the PDF.');
      }

      const questionnaireEngine = new QuestionnaireEngine({
        questionnaire: dynamicPdfData.questionnaire,
        nodeIdToAnswerPathMap: deserializedNodeIdToAnswerPathMap,
        context: {
          insuranceModules: pdfOptions.insuranceModuleFilter || [],
          platformTypes: pdfOptions.platformTypeFilter || [],
        },
        blueprint: DEFAULT_BLUEPRINT,
        timezone: timezoneResult.value,
        applicationContext,
        currentDateOverride: application?.submissionDate?.toString() || null,
      });

      const renderingQuestionnaire = questionnaireEngine.generateRenderingQuestionnaire(
        new VersionedAnswers({ v1: answers, v2: answersV2 }),
        lang,
        text,
        {
          renderingType: RenderingType.pdf,
          shouldValidateAllAnswers: false,
        },
      );
      setRenderingQuestionnaire(renderingQuestionnaire);

      const isESignatureType = signatureType === SignatureType.eSignature;
      if (dynamicPdfData.eSignSigners && isESignatureType) {
        const signers = await generateSignees(pdfType, dynamicPdfData.eSignSigners);
        setSignatureData({ signers, isExternalSignature: false });
      }

      const isExternalSignature = signatureType === SignatureType.externalSignature;

      if (isExternalSignature) {
        // TODO: DEV-8346 Support external signature type
      }
    }
    void fetchData();
  }, [applicationId, pdfOptions, pdfType, signatureType, isLoaded, dbThemeLoaded, application, language]);

  const cryptoSignatureData = useMemo<CryptoSignatureData | undefined>(() => {
    if (!application) return;
    const isCryptoSignatureType = signatureType === SignatureType.cryptoSignature;
    const isCryptoSignatureDataAvailable = !!application.private?.signedDigest;

    if (isCryptoSignatureType && isCryptoSignatureDataAvailable) {
      const { signedDigest, signatureVerificationTime, payerIdentifier } = application.private;
      return {
        signature: signedDigest,
        date: signatureVerificationTime,
        payerIdentifier,
      };
    }
  }, [application, signatureType]);

  const isSignatureDataLoaded = signatureData || cryptoSignatureData;
  const loadingDbTheme = !dbThemeLoaded;

  const applicationLineOfBusiness = useMemo<LineOfBusinessName | undefined>(() => {
    if (!application) {
      return;
    }
    return application.lineOfBusiness;
  }, [application]);

  if (
    !application ||
    !nodeIdToAnswerPathMap ||
    !renderingQuestionnaire ||
    !isSignatureDataLoaded ||
    loadingDbTheme ||
    !dbTheme ||
    !pointOfSaleDecisions ||
    !applicationLineOfBusiness
  ) {
    return <Loader color='#000000' />;
  }

  const pdfTheme = getColorizedBaseTheme(dbTheme.colorRanges);

  const pdfFunctionOverrides = { ...defaultPdfFunctions, ...pdfOptions.pdfFunctionOverrides };

  let pdfData: PdfData = {
    renderingQuestionnaire,
    nodeIdToAnswerPathMap,
    application,
    currency,
    cryptoSignatureData,
    signatureData: { ...signatureData, eSignatureFieldType },
    proposedInsuredInformation: insuranceEntities?.proposedInsureds,
    productsEntity: insuranceEntities?.products,
    advisors: insuranceEntities?.advisors,
    addons,
    annuitantInformation: undefined,
    applicantInformation: insuranceEntities?.proposedInsureds,
    ownerInformation: insuranceEntities?.owners,
    payorInformation: insuranceEntities?.payers,
    pdfType,
    pointOfSaleDecisions,
    ...pdfFunctionOverrides,
  };

  if ([LineOfBusinessName.legacyAnnuity, LineOfBusinessName.annuity].includes(applicationLineOfBusiness)) {
    pdfData = {
      ...pdfData,
      proposedInsuredInformation: undefined,
      productsEntity: undefined,
      annuitantInformation: insuranceEntities?.annuitant,
      applicantInformation: insuranceEntities?.annuitant,
    };
  }

  const contextValues: PdfData & (CarrierPdfOptions | AdvisorPdfOptions | InsuredPdfOptions) = {
    ...pdfData,
    ...(pdfType === DynamicPdfType.carrier && (pdfOptions as CarrierPdfOptions)),
    ...(pdfType === DynamicPdfType.insured && (pdfOptions as InsuredPdfOptions)),
    ...(pdfType === DynamicPdfType.advisor && (pdfOptions as AdvisorPdfOptions)),
  };

  return (
    <ThemeProvider theme={pdfTheme}>
      <PdfContext.Provider value={contextValues}>
        {pdfType === DynamicPdfType.carrier && <CarrierPdf />}
        {pdfType === DynamicPdfType.insured && <InsuredPdf />}
        {pdfType === DynamicPdfType.advisor && <AdvisorPdf />}
      </PdfContext.Provider>
      {/* At this point the dom is rendered and no further network calls are made. We need to make sure the views are only rendered once and that no API calls are done down the rendering tree. */}
      <div data-pdfgenerator='pdfrendered'></div>
    </ThemeProvider>
  );
}
