import { Language, LanguageRegion } from '@breathelife/types';

const DEFAULT_LANGUAGE_REGION = LanguageRegion.enCa;
const DEFAULT_LOCALE = Language.en;

function getCurrentLocale(fallbackLanguage: Language = DEFAULT_LOCALE): Language {
  return (
    ((window.localStorage.getItem('locale') ||
      window.navigator.userLanguage ||
      window.navigator.language) as Language) || fallbackLanguage
  );
}

export function setLocale(language: Language): void {
  // we are not really saving a locale, but a language. keeping locale as the key because of not having to add fallback on it if we start using a new key
  window.localStorage.setItem('locale', language);
}

export function getLanguage(fallbackLanguage: Language = DEFAULT_LOCALE): Language {
  const locale = getCurrentLocale(fallbackLanguage);
  const [lang] = locale.split('-');
  return lang as Language;
}

const LanguageRegions: { [lang: string]: LanguageRegion } = {
  [Language.fr]: LanguageRegion.frCa,
  [Language.en]: LanguageRegion.enCa,
};

export function getLanguageRegion(): LanguageRegion {
  const language = getLanguage();
  return LanguageRegions[language];
}

type CurrencyOptions = Pick<Intl.NumberFormatOptions, 'minimumFractionDigits'>;

export function toCurrency(amount: number, currency: string, options: CurrencyOptions = {}): string {
  const lang = getLanguage();
  const locale = LanguageRegions[lang] ?? DEFAULT_LANGUAGE_REGION;

  return new Intl.NumberFormat(locale, { currency, style: 'currency', minimumFractionDigits: 2, ...options }).format(
    amount,
  );
}

export type CurrencySymbolPosition = 'before' | 'after';
export type ThousandSeparator = ',' | ' ';

export function getCurrencySymbolPosition(lang?: Language): CurrencySymbolPosition {
  return lang === Language.fr ? 'after' : 'before';
}

export function getThousandSeparator(lang?: Language): ThousandSeparator {
  return lang === Language.fr ? ' ' : ',';
}
