import { ESignCeremonyStatus, LeadDetailTab } from '@breathelife/types';
import _ from 'lodash';
import { ReactElement, Fragment, useEffect, useState, useMemo } from 'react';

import { TabPanel } from '../../../../Components/Tabs/Tabs';
import { useCarrierContext, useSelector } from '../../../../Hooks';
import { DetailViewType } from '../../../../Models/Layout';
import { Lead } from '../../../../Models/Lead';
import { LeadDetailTabs } from '../Tabs/LeadDetailTabs';
import { TabsContainer } from '../Tabs/Styles';
import { ApplicationsViewContainer } from './ApplicationsView/ApplicationsViewContainer';
import { HistoryView } from './HistoryView/HistoryView';
import { NeedsAnalysisView } from './NeedsAnalysisView/NeedsAnalysisView';
import { NotesView } from './NotesView/NotesView';
import { PersonalInfoView } from './PersonalInfoView/PersonalInfoView';

type Props = {
  type: DetailViewType;
  lead?: Lead;
  onSendEmailClick: (lead: Lead) => void;
  onClose: () => void;
};

export function LeadDetailBody(props: Props): ReactElement | null {
  const { enabledTabs, languageSettings, features } = useCarrierContext();
  const { tab } = useSelector((store) => store.leadPlatform.layout.rightPanelState);

  // Don't select anything until lead detail is ready
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>();
  const { lead, onClose, onSendEmailClick, type: detailViewType } = props;

  const personalInfoTab = enabledTabs[LeadDetailTab.personalInfo];
  const needsAnalysisTab = enabledTabs[LeadDetailTab.needsAnalysis];
  const notesTab = enabledTabs[LeadDetailTab.notes];
  const historyTab = enabledTabs[LeadDetailTab.history];
  const applicationsTab = enabledTabs[LeadDetailTab.applications];

  useEffect(() => {
    if (lead && !_.isNumber(selectedTabIndex)) {
      setSelectedTabIndex(0);
    }
  }, [lead, selectedTabIndex]);

  useEffect(() => {
    if (detailViewType === DetailViewType.create) {
      setSelectedTabIndex(0);
    }
  }, [detailViewType]);

  useEffect(() => {
    if (tab && enabledTabs[tab]) {
      setSelectedTabIndex(enabledTabs[tab]?.index);
    }
  }, [enabledTabs, tab]);

  const availableDropdownLanguage = languageSettings.enabledLanguages;
  const isCommunicationLanguageEditable = useMemo(() => {
    if (lead?.status === 'submissionCompleted' || !!lead?.applications[0]?.submitted) return false;
    if (
      lead?.status === 'submissionStarted' &&
      [
        ESignCeremonyStatus.IN_PROGRESS,
        ESignCeremonyStatus.SENT,
        ESignCeremonyStatus.READY,
        ESignCeremonyStatus.COMPLETED,
      ].includes(lead?.applications[0]?.eSignCeremonies[0]?.status)
    ) {
      return false;
    }
    return true;
  }, [lead?.applications, lead?.status]);

  const canEditCommunicationLanguage = isCommunicationLanguageEditable && availableDropdownLanguage.length > 1;
  const isLeadCreationEnabled = features.leadCreation.enabled;

  return (
    <Fragment>
      <TabsContainer px={3} borderBottom={1}>
        <LeadDetailTabs
          type={detailViewType}
          selectedTab={selectedTabIndex}
          // eslint-disable-next-line @typescript-eslint/naming-convention
          onSelectedTabChange={(_event: any, value: number) => setSelectedTabIndex(value)}
          disabled={_.isNil(selectedTabIndex)}
        />
      </TabsContainer>

      {personalInfoTab && (
        <TabPanel selectedIndex={selectedTabIndex} tabIndex={personalInfoTab.index}>
          <PersonalInfoView
            lead={lead}
            detailViewType={detailViewType}
            onClose={onClose}
            showCommunicationLanguage={isLeadCreationEnabled ? canEditCommunicationLanguage : false}
          />
        </TabPanel>
      )}

      {needsAnalysisTab && (
        <TabPanel selectedIndex={selectedTabIndex} tabIndex={needsAnalysisTab.index}>
          <NeedsAnalysisView lead={lead} onSendEmailClick={onSendEmailClick} tabProps={needsAnalysisTab} />
        </TabPanel>
      )}

      {applicationsTab && (
        <TabPanel selectedIndex={selectedTabIndex} tabIndex={applicationsTab.index}>
          <ApplicationsViewContainer
            lead={lead}
            showCommunicationLanguage={!isLeadCreationEnabled ? canEditCommunicationLanguage : false}
          />
        </TabPanel>
      )}

      {notesTab && (
        <TabPanel selectedIndex={selectedTabIndex} tabIndex={notesTab.index}>
          <NotesView lead={lead} />
        </TabPanel>
      )}

      {historyTab && (
        <TabPanel selectedIndex={selectedTabIndex} tabIndex={historyTab.index}>
          <HistoryView lead={lead} />
        </TabPanel>
      )}
    </Fragment>
  );
}
