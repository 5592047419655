import { ReactElement } from 'react';

import { Notification } from '@breathelife/types';

import { NotificationItem } from '../NotificationItem/NotificationItem';
import styled from '../../../../Styles/themed-styled-components';

const NotificationsContainer = styled.div`
  overflow-y: scroll;
`;

export function NotificationList(props: { notifications: Notification[] }): ReactElement {
  const { notifications } = props;

  const notificationsItems = notifications.map((notification) => {
    return <NotificationItem key={notification.id} notification={notification} />;
  });

  return <NotificationsContainer>{notificationsItems}</NotificationsContainer>;
}
