import { ReactElement, useMemo } from 'react';

import { ResponsiveProps } from '@breathelife/ui-components';

import { CarrierLogo } from '../../Components/CarrierLogo/CarrierLogo';
import { text } from '../../Localization/Localizer';
import { TransactionFlowDesktopHeader } from '../../Components/Header/TransactionFlowDesktopHeader';
import { TransactionFlowMobileHeader } from '../../Components/Header/TransactionFlowMobileHeader';
import { LegalLink } from '../../Components/LegalMenu/LegalMenu';

type Props = {
  title: string;
  progress: number;
  legalLinks: LegalLink[];
  collapseMobileHeader: boolean;
  showScheduleCallAction: boolean;
} & ResponsiveProps;

export function TransactionFlowHeader(props: Props): ReactElement {
  const emptyMobileHeaderInfoView = null;
  const emptyHeader = null;
  const MobileHeader = useMemo(
    () => (
      <TransactionFlowMobileHeader
        progress={props.progress}
        legalLinks={props.legalLinks}
        collapseHeader={props.collapseMobileHeader}
        title={props.title}
        // TODO: FIXME: previously, the transaction flow layout required to have Component injected per carrier.
        // We moved toward a single entry point approach for all carrier.
        // If the transactionFlow is going to be reused, this will need to be reimplemented based on new requirements.
        infoView={!!props.title && emptyMobileHeaderInfoView}
        logo={<CarrierLogo />}
      >
        {/* TODO: FIXME: previously, the transaction flow layout required to have Component injected per carrier.
          We moved toward a single entry point approach for all carrier.
          If the transactionFlow is going to be reused, this will need to be reimplemented based on new requirements.
        */}
        {emptyHeader}
      </TransactionFlowMobileHeader>
    ),
    [props.progress, props.legalLinks, props.collapseMobileHeader, props.title],
  );

  const DesktopHeader = useMemo(
    () => (
      <TransactionFlowDesktopHeader
        progress={props.progress}
        progressText={text('header.completion', { progress: props.progress })}
        legalLinks={props.legalLinks}
      >
        {/* TODO: FIXME: previously, the transaction flow layout required to have Component injected per carrier.
          We moved toward a single entry point approach for all carrier.
          If the transactionFlow is going to be reused, this will need to be reimplemented based on new requirements.
        */}
        {emptyHeader}
      </TransactionFlowDesktopHeader>
    ),
    [props.progress, props.legalLinks],
  );

  return props.isMobile ? MobileHeader : DesktopHeader;
}
