import { ReactElement } from 'react';
import { BaseMaskedDateFieldProps, MaskedDateInputField } from './MaskedDateInput';

type Props = BaseMaskedDateFieldProps;

export function YearMonthField(props: Props): ReactElement {
  return (
    <MaskedDateInputField
      id={props.id}
      name={props.name}
      value={props.value}
      locale={props.locale}
      title={props.title}
      subtitle={props.subtitle}
      validationError={props.validationError}
      inputVariant={props.inputVariant}
      disabled={props.disabled}
      label={props.label}
      onAnswerChange={props.onAnswerChange}
      onAnswerComplete={props.onAnswerComplete}
      data-testid={props['data-testid']}
      required={props.required}
      boldedBorder={props.boldedBorder}
    />
  );
}
