import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Helmet } from '@breathelife/ui-components';
import { useGetCloudinaryLinkAttributes } from '@breathelife/react-hooks';

import { useCarrierContext } from '../Hooks';
import { useTheme } from '../Styles/themed-styled-components';

type HelmetContainerProps = {
  text?: string;
};

export function HelmetContainer(props: HelmetContainerProps): ReactElement {
  const { carrierInfo, cloudinaryConfig } = useCarrierContext();
  const { t } = useTranslation();
  const theme = useTheme();

  const linkAttributes = useGetCloudinaryLinkAttributes([
    { cloudinaryConfig, compactLogoUrl: carrierInfo.logoCompact },
  ]);

  const meta = [
    {
      name: 'theme-color',
      content: theme.colors.base.primary,
    },
  ];

  return (
    <Helmet
      metaAttributes={meta}
      linkAttributes={linkAttributes}
      titleAttributes={{ title: `${t('pageTitles.prefix')} - ${carrierInfo.companyName}`, text: props.text }}
    />
  );
}
