import { ReactElement } from 'react';

import config from '@breathelife/config/frontend';
import { ConsumerFlow as ConsumerFlowComponent, Features } from '@breathelife/consumer-flow';
import { CloudinaryConfig, ConsumerFlow, Language, NodeId, SchedulingTimeFrameConfig } from '@breathelife/types';

import { shortLocale } from '@breathelife/ui-components';

import { ImageFilenames } from 'Images/Images';

export function localizedConfig<T>(value: string): T {
  return config.get(`${value}.${shortLocale()}`);
}

const consumerFlowConfig = {
  images: ImageFilenames, // TODO: FIXME: https://breathelife.atlassian.net/browse/HOT-2958, when in frontend core, load the right image through config
  pdfFetchDuration: config.get<number>('carrier.pdfFetchDuration'),
  cloudinary: config.get<CloudinaryConfig>('keys.cloudinary'),
  displayFullAdvisorNameOnAdvisorGreetingMessage: config.get<boolean>(
    'features.consumerFlow.displayFullAdvisorNameOnAdvisorGreetingMessage',
  ),
  git: {
    commitSha: config.get<string>('git.commitSha'),
  },
};

const features: Features = {
  productPage: {
    enabled: config.get<boolean>('features.consumerFlow.productPage.enabled'),
    quoter: {
      enabled: config.get<boolean>('features.consumerFlow.productPage.quoter.enabled'),
      sliderStep: config.get<number>('features.consumerFlow.productPage.quoter.sliderStep'),
    },
  },
  selfServeApplication: {
    enabled: config.get<boolean>('features.consumerFlow.selfServeApplication.enabled'),
  },
  signature: {
    enabled: config.has('features.signatureType.consumerFlow'),
    type: config.get('features.signatureType.consumerFlow'),
  },
  payments: {
    enabled: config.get('features.payments.enabled'),
    serviceProvider: config.get('features.payments.serviceProvider'),
    stripe: {
      publicKey: config.get('features.payments.stripe.publicKey'),
      connectedAccountId: config.get('features.payments.stripe.connectedAccountId'),
    },
  },
  pricing: {
    enabled: config.get<boolean>('features.consumerFlow.pricing.enabled'),
    nodeIdsAffectingPricing: config.get<NodeId[]>('features.consumerFlow.pricing.nodeIdsAffectingPricing'),
  },
};

function ConsumerFlowContainer(props: { enableHeaderProductSummary: boolean }): ReactElement {
  const NEW_APPLICATION_BYPASS = config.get<boolean>('debug.enableNewApplicationRoute');
  const enableQuestionnaireDebugTools = config.get<boolean>('debug.displayQuestionnaireFillerToolbar');
  const carrierTranslations = config.get<Partial<Record<Language, any>>>('localization.global');

  const { enableHeaderProductSummary } = props;
  return (
    <ConsumerFlowComponent
      config={{ ...consumerFlowConfig, enableHeaderProductSummary }}
      layout={config.get<ConsumerFlow.Layout>('features.consumerFlow.layoutType')}
      features={features}
      carrierInfo={{
        companyName: config.get(`carrier.companyName.${shortLocale()}`),
        indexPageTitle: localizedConfig('carrier.indexPage.title'),
        productTransitionLoadingDuration: config.get<{ life: number }>('ui.productLoadingDuration'),
        contactPhoneNumber: config.get('carrier.contactPhoneNumber'),
        contactEmail: config.get('carrier.contactEmail'),
        useAdvisorTitleFallback: config.get<boolean>('features.consumerFlow.useAdvisorTitleFallback'),
      }}
      lastStepsIds={Object.values(config.get<string[]>('carrier.lastStepsIds'))}
      localizedStrings={carrierTranslations}
      enableNewApplicationRoute={NEW_APPLICATION_BYPASS}
      schedulingTimeFrames={config.get<SchedulingTimeFrameConfig>('features.consumerFlow.schedulingTimeFrames')}
      enableQuestionnaireDebugTools={enableQuestionnaireDebugTools}
    />
  );
}

export default ConsumerFlowContainer;
