import _ from 'lodash';
import queryString from 'query-string';
import { ReactElement, memo, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { MuiThemeProvider } from '@breathelife/mui';
import { ConsumerFlow as ConsumerFlowType, DeepPartial, Language, SchedulingTimeFrameConfig } from '@breathelife/types';
import { ErrorBoundary, Loader, PopupProvider } from '@breathelife/ui-components';

import { DebugToolbarContainer } from './Components/DebugToolbar/DebugToolbarContainer';
import { CarrierContext, CarrierInfo, defaultFeatures } from './Context/CarrierContext';
import { useCxSelector } from './Hooks/useCxSelector';
import { getCurrentLocale, initCarrierLanguages, setLocale, setTranslations } from './Localization/Localizer';
import { Dictionaries } from './Localization/index';
import { ErrorPageContainer } from './Pages/ErrorPage/ErrorPageContainer';
import { ReactQueryClientProvider } from './ReactQuery';
import * as SettingsOperations from './Redux/Settings/SettingsOperations';
import * as ThemeOperations from './Redux/Theme/ThemeOperations';
import { Root } from './Root/Root';
import { GlobalStyle } from './Styles/GlobalStyle';
import { createMuiTheme } from './Styles/MuiTheme';
import { colorizeTheme } from './Theme';
import { ConsumerFlowConfig, ConsumerFlowTheme } from './types';
import { Features } from './Models/Features';
import { useDispatch } from 'react-redux';

type ConsumerFlowProps = {
  config: ConsumerFlowConfig;
  carrierInfo: Omit<CarrierInfo, 'logo' | 'logoCompact'>;
  enableNewApplicationRoute: boolean;
  enableQuestionnaireDebugTools: boolean;
  features: Features;
  interlocutorIds?: string[];
  lastStepsIds: string[];
  layout: ConsumerFlowType.Layout;
  localizedStrings: DeepPartial<Dictionaries>;
  schedulingTimeFrames?: SchedulingTimeFrameConfig;
  theme?: ConsumerFlowTheme;
  // Once MGAs are in the db, this property can be removed
  // because the interlocutor status should be directly available
  // from the application assignee
};

function ConsumerFlowComponent(props: ConsumerFlowProps): ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { theme } = useCxSelector((store) => store.consumerFlow.theme);
  const { settings } = useCxSelector((store) => store.consumerFlow.settings);

  const {
    carrierInfo,
    config: propConfig,
    enableNewApplicationRoute,
    enableQuestionnaireDebugTools,
    features: propFeatures,
    lastStepsIds,
    layout,
    localizedStrings,
    schedulingTimeFrames,
    theme: propTheme,
  } = props;

  useEffect(() => {
    dispatch(ThemeOperations.fetchTheme(navigate));
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(SettingsOperations.fetchSettings(navigate));
  }, [dispatch, navigate]);

  const { enabledLanguages, defaultLanguage } = useMemo(
    () => (settings ? settings.data : propConfig),
    [propConfig, settings],
  );

  useEffect(() => {
    setTranslations(localizedStrings);

    if (enabledLanguages?.length) {
      initCarrierLanguages(enabledLanguages);
    } else {
      initCarrierLanguages([Language.en]);
    }

    const { lang } = queryString.parse(location.search) as Record<string, Language | undefined>;
    if (lang) {
      setLocale(lang);
    }

    if (enabledLanguages && !enabledLanguages.includes(getCurrentLocale())) {
      if (!defaultLanguage) {
        throw new Error('Default language not set in carrier config');
      }

      setLocale(defaultLanguage);
    }
  }, [localizedStrings, defaultLanguage, enabledLanguages]);

  const consumerTheme = useMemo(() => theme && colorizeTheme(theme), [theme]);

  const features = propFeatures ?? defaultFeatures;

  if (!consumerTheme || !theme) return <Loader color='#000000' />;

  return (
    <ReactQueryClientProvider>
      <ThemeProvider theme={consumerTheme}>
        <MuiThemeProvider theme={createMuiTheme(consumerTheme)}>
          <PopupProvider>
            <CarrierContext.Provider
              value={{
                theme: propTheme,
                features,
                carrierInfo: {
                  ...carrierInfo,
                  companyName: settings ? settings.data.carrierNames[getCurrentLocale()] : carrierInfo.companyName,
                  logo: theme.logoImgUrl,
                  logoCompact: theme.compactLogoImgUrl,
                },
                enableLoadCarrierNamesFromDb: true,
                enableSalesDecisionRules: true,
                cloudinaryConfig: propConfig.cloudinary,
                pdfFetchDuration: propConfig.pdfFetchDuration,
                layout: layout,
                images: propConfig.images,
                iconMap: {}, // TODO: FIXME: If we want to have a way override the iconMap, it needs to be structured and configurable through tooling
                enableHeaderProductSummary: propConfig.enableHeaderProductSummary ?? false,
                // TODO: FIXME: feature is not used currently and is at risk of not working properly since the last time a carrier used it.
                // We also don't have test that covers the feature which means we should test this fully before having a config setting this value.
                interlocutorIds: [],
                enableQuestionnaireDebugTools: enableQuestionnaireDebugTools,
                displayFullAdvisorNameOnAdvisorGreetingMessage:
                  !!propConfig.displayFullAdvisorNameOnAdvisorGreetingMessage,
                git: {
                  commitSha: propConfig.git.commitSha,
                },
              }}
            >
              <ErrorBoundary renderErrorComponent={() => <ErrorPageContainer />}>
                <DebugToolbarContainer />
                <Root
                  lastStepsIds={lastStepsIds}
                  enableNewApplicationRoute={enableNewApplicationRoute}
                  schedulingTimeFrames={schedulingTimeFrames}
                />
              </ErrorBoundary>
            </CarrierContext.Provider>
          </PopupProvider>
        </MuiThemeProvider>
        <GlobalStyle />
      </ThemeProvider>
    </ReactQueryClientProvider>
  );
}

export const ConsumerFlow = memo(ConsumerFlowComponent, _.isEqual);

export * from './Redux/Store';
export * from './Models/Layout';
export * from './Models/ApplicationAssignee';
export * from './Components/GenericLinkLanding/GenericLinkLanding';
export * from './Layouts/PlanFinder/Footer';
export * from './Layouts/PlanFinder/Header';
export * from './Layouts/Centered/Layout';
export * from './Models/Step';
export * as Urls from './Navigation/Urls';
export { getOtherLocales } from './Localization/Localizer';
export { switchLanguage } from './Redux/SwitchLanguage/SwitchLanguageOperations';
export { createTrackerMiddleware } from './Redux/Middlewares/createTrackerMiddleware';
export { Language } from '@breathelife/types';
export * from './Models/Features';
export { CreateMockApplicationView } from './Pages/CreateMockApplicationPage/CreateMockApplicationView';
export type { ConsumerFlowConfig, ConsumerFlowImages } from './types';
