import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { AgentLicenseStatus, AgentLicenseProperties, LicensesTableRowData } from '@breathelife/types';

import { US_STATES } from './locations';

function jurisdictionToStateOrProvince(jurisdiction: string, t: TFunction): string {
  return US_STATES[jurisdiction as keyof typeof US_STATES]
    ? t(`usStates.${jurisdiction}`)
    : t(`canadianProvinces.${jurisdiction}`);
}

function getAgentLicenseStatus(expirationDate: string): AgentLicenseStatus {
  const isAgentLiceseActive = dayjs(expirationDate).isAfter(dayjs());
  return isAgentLiceseActive ? AgentLicenseStatus.active : AgentLicenseStatus.expired;
}

export function licensesToTableData(licensesData: AgentLicenseProperties[]): LicensesTableRowData[] {
  const { t } = useTranslation();
  const licensesTableDatalicensesData = licensesData.map((license, index) => {
    const { jurisdiction, expirationDate, licenseType } = license;
    return {
      stateOrProvince: jurisdictionToStateOrProvince(jurisdiction, t),
      expirationDate: dayjs(expirationDate).format('DD/MM/YYYY'),
      licenseType: licenseType,
      status: getAgentLicenseStatus(expirationDate),
      id: index,
    };
  });
  return licensesTableDatalicensesData;
}
