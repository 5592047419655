import dayjs from 'dayjs';
import _ from 'lodash';
import { ReactElement, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import styled from '../../../Styles/themed-styled-components';

import { AdminToolResourceName, InsuranceFirmWithProductCount, Localizable } from '@breathelife/types';
import { AddIcon, Box } from '@breathelife/mui';

import { ActionButton } from '../../../Components/Button/ActionButton';
import { LogButton } from '../../../Components/Button/LogButton';
import LoadingView from '../../../Components/LoadingView/LoadingView';
import { Table } from '../../../Components/Table/Table';
import Typography from '../../../Components/Typography';
import { useCarrierContext } from '../../../Hooks';
import { getLanguage } from '../../../Localization/utils';
import { emptyTableHeight } from '../../../Models/Layout';

const EmptyContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: ${() => `calc(100vh - ${emptyTableHeight}px)`};
  min-height: 300px;
`;

type Props = {
  firms: InsuranceFirmWithProductCount[];
  isFetching: boolean;
  onTableRowClick: (id?: string) => void;
  onOpenCreateFirm: () => void;
};

type InsuranceFirmTableData = InsuranceFirmWithProductCount;

export function FirmManagementView({ firms, isFetching, onTableRowClick, onOpenCreateFirm }: Props): ReactElement {
  const { t } = useTranslation();
  const { features } = useCarrierContext();

  const columns = useMemo<
    {
      id?: string;
      Header: string;
      accessor: keyof InsuranceFirmTableData;
      Cell?: (props: CellProps<InsuranceFirmTableData>) => ReactElement;
    }[]
  >(
    () => [
      {
        Header: t('admin.firmManagement.labels.name'),
        accessor: 'name',
        Cell: function ({ cell: { value } }: { cell: { value: Partial<Localizable> } }): ReactElement {
          return <Fragment>{value[getLanguage()]}</Fragment>;
        },
      },
      {
        Header: t('admin.firmManagement.labels.productsCount'),
        accessor: 'productsCount',
        disableSortBy: true,
      },
      {
        Header: t('admin.firmManagement.labels.createdAt'),
        accessor: 'createdAt',
        Cell: function ({ cell: { value } }: { cell: { value: Date } }): ReactElement {
          return <Fragment>{dayjs(value).format('D MMMM YYYY HH:MM A')}</Fragment>;
        },
      },
      {
        id: 'logs',
        Header: t('viewLogs'),
        accessor: 'id',
        Cell: function ({ cell: { value: id } }: { cell: { value: string } }): ReactElement {
          return <LogButton resourceId={id} resourceName={AdminToolResourceName.firm} />;
        },
      },
    ],
    [t],
  );

  const isFirmListEmpty = _.isEmpty(firms);

  return isFetching ? (
    <LoadingView />
  ) : (
    <Fragment>
      <Box display='flex' justifyContent='flex-end' m={2}>
        <ActionButton
          data-testid='addNewFirmButton'
          color='primary'
          variant='contained'
          onClick={onOpenCreateFirm}
          startIcon={<AddIcon htmlColor='white' />}
        >
          {t('cta.addNew')}
        </ActionButton>
      </Box>
      {isFirmListEmpty ? (
        <EmptyContainer>
          <Box mb={1.5}>
            <Typography variant='h2' grey={70}>
              {t('admin.firmManagement.noFirmsFoundTitle')}
            </Typography>
          </Box>
          <Box maxWidth={300}>
            <Typography variant='body1' grey={60}>
              {t('admin.firmManagement.noFirmsFoundText')}
            </Typography>
          </Box>
        </EmptyContainer>
      ) : (
        <Box m={2}>
          <Table<InsuranceFirmTableData>
            columns={columns}
            data={firms}
            onRowClick={onTableRowClick}
            hiddenColumns={features.auditLogs?.enabled ? [] : ['logs']}
          />
        </Box>
      )}
    </Fragment>
  );
}
