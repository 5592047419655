import { NodeIds } from '@breathelife/insurance-form-builder';
import {
  deserializeNodeIdToAnswerPathMap,
  NodeIdAnswersResolver,
  SerializedNodeIdToAnswerPathMap,
} from '@breathelife/questionnaire-engine';
import { LocalizedInsuranceProduct, SelectedProductInformation, Answers } from '@breathelife/types';

import { ConsumerFlowStore } from '../Store';

export const getSelectedProductInformation = (
  state: ConsumerFlowStore,
  options?: { useApplicationMonthlyPremium?: boolean },
): SelectedProductInformation | null => {
  const { insuranceApplication, products = [] } = state.consumerFlow.insuranceApplication;

  if (options?.useApplicationMonthlyPremium) {
    // Not all carriers use the `products` array and/or the NodeIds.productId / NodeIds.coverageAmount nodeIds.
    return null;
  }

  const answersDataStructure = insuranceApplication?.answersDataStructure;
  const answers = insuranceApplication?.answers as Answers;

  const premium = insuranceApplication?.monthlyPremium;

  const nodeIdToAnswerPathMap = deserializeNodeIdToAnswerPathMap(
    answersDataStructure as SerializedNodeIdToAnswerPathMap,
  );

  const answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);

  const productId = nodeIdToAnswerPathMap.size && answersResolver.getAnswer(answers, NodeIds.productId, {});
  const coverageAmount = nodeIdToAnswerPathMap.size && answersResolver.getAnswer(answers, NodeIds.coverageAmount, {});

  const product = (products as Array<LocalizedInsuranceProduct>).find((product) => {
    return product.productId === productId && product.isEligible;
  });

  return premium && coverageAmount && product ? { ...product, premium, coverageAmount } : null;
};

export const getMonthlyPremiumFromApplication = (state: ConsumerFlowStore): number | undefined => {
  const { insuranceApplication } = state.consumerFlow.insuranceApplication;
  return insuranceApplication?.monthlyPremium;
};
