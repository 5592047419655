import { ReactNode, ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { OptionWidth } from '@breathelife/questionnaire-engine';

import { Aside } from '../Aside';
import { Bullet, CheckIcon, Container, Content, Input, RadioLabel } from './Styles';

type Props = {
  optionId: string;
  children: ReactNode;
  checked: boolean;
  ariaLabel?: string;
  groupName: string;
  suffix?: ReactNode;
  iconless?: boolean;
  marginless?: boolean;
  showErrorBorder?: boolean;
  disabled?: boolean;
  onChange: (optionId: string) => void;
  boldedBorder?: boolean;
};

export function RadioCard(props: Props): ReactElement {
  const { optionId, children, checked, ariaLabel, groupName, onChange, suffix, iconless = false, disabled } = props;

  return (
    <Container marginless={props.marginless}>
      <Input
        type='radio'
        id={`${groupName}_${optionId}`}
        value={optionId}
        aria-label={ariaLabel}
        name={groupName}
        checked={checked}
        disabled={disabled}
        onChange={() => onChange(optionId)}
      />

      <RadioLabel
        htmlFor={`${groupName}_${optionId}`}
        data-testid={`radio-${groupName}-${optionId}`}
        aria-checked={checked}
        $showErrorBorder={props.showErrorBorder}
        boldedBorder={props.boldedBorder}
        disabled={disabled}
      >
        <Aside>
          <div>{iconless === false && <Bullet>{checked && <CheckIcon />}</Bullet>}</div>
          <Content>{children}</Content>
          {suffix && <div>{suffix}</div>}
        </Aside>
      </RadioLabel>
    </Container>
  );
}

export const RadioCardContainer = styled.div.attrs({
  role: 'radiogroup',
})<{ optionWidth?: OptionWidth }>`
  ${(props) => {
    if (props.optionWidth) {
      let columns = '1fr';
      switch (props.optionWidth) {
        case 'half':
          columns = '1fr 1fr';
          break;
        case 'oneThird':
          columns = '1fr 1fr 1fr';
          break;
      }
      return css`
        display: grid;
        grid-auto-rows: auto;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        grid-template-columns: ${columns};
        ${Container} {
          max-width: 100%;
        }
      `;
    }
  }}

  padding: 0;
  border: none;
`;
