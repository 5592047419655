import { IconButton } from '@breathelife/mui';
import styled from '../../../../Styles/themed-styled-components';
import { Icon } from '../../../../Components/Icons';

export const NotificationItemContainer = styled.div`
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[50]};
`;

export const StyledLink = styled.span`
  display: flex;
  color: inherit;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey[40]};
    color: inherit;
  }
`;
export const InformationContainer = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-right: 5%;
`;

export const InformationContent = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const DotIcon = styled(Icon)`
  margin-right: 5px;
`;

export const TextContainer = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 10px;
`;

export const MainTextContent = styled.div`
  height: 60px;
  display: flex;
  align-items: flex-start;
`;

export const CollapseContentContainer = styled.div`
  display: flex;
`;

export const CollapseTextContent = styled.div`
  display: flex;
`;

export const ActionsContainer = styled.div`
  width: 5%;
  height: 100%;
  margin-left: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ActionsContent = styled.div`
  height: 75px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const ExpandButton = styled(IconButton)`
  display: flex;
  align-items: center;
`;

export const EyeIcon = styled(Icon)`
  svg {
    fill: ${(props) => props.theme.colors.grey[60]};
    &:hover {
      fill: ${(props) => props.theme.colors.blue[60]};
    }
  }
`;
