import _ from 'lodash';

import { evaluateConditions } from '@breathelife/condition-engine';
import { BooleanOperator, InstanceScope, NodeInstance, Answers, Timezone, IAnswerResolver } from '@breathelife/types';

import { getDynamicOptions } from '../../renderingTransforms/populateDynamicOptions';
import {
  DynamicOptionsDetails,
  UnderRepeatableParents,
  VisibilityConditionsWithNodeIdsToClear,
  VisibilityDependencyMap,
} from './dependencyMap';

type HaveHandledRepeatableParent = boolean;

type Context = {
  updatedNodeId: string;
  dependencyMap: VisibilityDependencyMap;
  answersResolver: IAnswerResolver;
  answers: Answers;
  scope: InstanceScope;
  nodeInstancesToClear: NodeInstance[];
  optionAnswerUnsetInstances: NodeInstance[];
  timezone: Timezone;
  currentDateOverride: string | null;
};

export function filterVisibleAnswers(
  updatedNodeId: string,
  dependencyMap: VisibilityDependencyMap,
  answersResolver: IAnswerResolver,
  answers: Answers,
  scope: InstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): Answers {
  const dependantConditions = dependencyMap.getDependantConditions(updatedNodeId);

  if (!dependantConditions) {
    // Changing this value cannot affect visibility.
    return answers;
  }

  const context: Context = {
    updatedNodeId,
    dependencyMap,
    answersResolver,
    answers,
    scope,
    nodeInstancesToClear: [],
    optionAnswerUnsetInstances: [],
    timezone,
    currentDateOverride,
  };

  const determineWhichNodeIdsToClear = iterateAndDetermineWhichNodeIdsToClear(context);
  dependantConditions.forEach((dependantCondition) => determineWhichNodeIdsToClear(dependantCondition));

  // When a condition associated with a node is false, clear it if it's not already cleared (unsetAnswer returns the values that were not already cleared);
  const answerUnsetInstances = answersResolver.unsetAnswers(answers, context.nodeInstancesToClear);

  // After unsetting some nodeIds, check for all collections that have changed and remove the undefined.
  // Note: We let the "undefined" during the removal process to NOT change the collectionInstanceIdentifierIndex.
  // EX:   [ {...}, {...}, undefined, {...}]   collectionInstanceIdentifier = 3 still points to the last.
  //    vs [ {...}, {...}, {...}]              collectionInstanceIdentifier = 3 is outside the answers array.
  const updatedNodeIds = context.nodeInstancesToClear.map((nodeInstance) => nodeInstance.id);
  _.uniq(updatedNodeIds).forEach((nodeId) => {
    answersResolver.removeUndefinedAnswersFromCollection(nodeId, answers, scope);
  });

  const modifiedNodeInstances = answerUnsetInstances.concat(context.optionAnswerUnsetInstances);
  modifiedNodeInstances.forEach(
    (instance) =>
      // For each change made check if there are higher-order effects (Now that X is not visible Y is not visible, now that Y is not visible Z is visible, and so on).
      (answers = filterVisibleAnswers(
        instance.id,
        dependencyMap,
        answersResolver,
        answers,
        instance.scope,
        timezone,
        currentDateOverride,
      )),
  );

  return answers;
}

function iterateAndDetermineWhichNodeIdsToClear(context: Context) {
  const {
    answers,
    answersResolver,
    dependencyMap,
    nodeInstancesToClear,
    optionAnswerUnsetInstances,
    scope,
    timezone,
    currentDateOverride,
  } = context;
  return (visibilityConditionsWithNodeIdsToClear: VisibilityConditionsWithNodeIdsToClear): void => {
    const { visibleIfConditions, dynamicOptionsDetails, nodeIdsToClear, optionsToClear } =
      visibilityConditionsWithNodeIdsToClear;

    const determineNodeIdsToClearIfParentIsRepeatableOn = determineNodeIdsToClearIfParentIsRepeatable(
      context,
      visibilityConditionsWithNodeIdsToClear,
    );
    if (determineNodeIdsToClearIfParentIsRepeatableOn('sectionGroup')) return;
    if (determineNodeIdsToClearIfParentIsRepeatableOn('question')) return;
    if (determineNodeIdsToClearIfSelfRepeatable(context, visibilityConditionsWithNodeIdsToClear)) return;

    const { clearDynamicOptionFieldNodeId, clearDynamicOptions } = filterDynamicOptionAnswers(
      answers,
      answersResolver,
      scope,
      currentDateOverride,
      dynamicOptionsDetails,
    );
    const hasChangeFromDynamicOptions = clearDynamicOptionFieldNodeId || clearDynamicOptions.length;

    // Run dependant conditions (unless we are evaluating dynamicOptions).
    const isVisible =
      dynamicOptionsDetails ||
      evaluateConditions(visibleIfConditions, answers, answersResolver, scope, timezone, currentDateOverride).isValid;

    if (isVisible && !hasChangeFromDynamicOptions) {
      // nodeId update did not make a difference for this condition (or dynamic options).
      return;
    }

    const currentNodeIdsToClear = clearDynamicOptionFieldNodeId
      ? [...nodeIdsToClear, clearDynamicOptionFieldNodeId]
      : nodeIdsToClear;

    const filteredNodeInstancesToClear = currentNodeIdsToClear
      .filter((nodeId: string) =>
        // Don't clear answers that are visible via another field.
        isNodeInstanceInvisibleEverywhere(nodeId, scope, answers, answersResolver, dependencyMap, currentDateOverride),
      )
      .map((nodeIdToClear: string) => {
        return { id: nodeIdToClear, scope };
      });
    nodeInstancesToClear.push(...filteredNodeInstancesToClear);

    const unsetOptionInstances = unsetOptions(
      optionsToClear || [],
      clearDynamicOptions,
      scope,
      answers,
      answersResolver,
      dependencyMap,
    );
    optionAnswerUnsetInstances.push(...unsetOptionInstances);
  };

  function determineNodeIdsToClearIfSelfRepeatable(
    context: Context,
    conditionsWithNodeIdsToClear: VisibilityConditionsWithNodeIdsToClear,
  ) {
    const { answers, answersResolver, scope } = context;
    const { selfRepeatable } = conditionsWithNodeIdsToClear;

    if (!selfRepeatable) {
      return false;
    }

    // Check the numbers of answers available for the nodeId that has the condition
    const numberOfRepeatableParentAnswers = answersResolver.getRepetitionCount(answers, selfRepeatable, scope);

    if (!numberOfRepeatableParentAnswers) {
      return false;
    }

    for (let index = 0; index < numberOfRepeatableParentAnswers; index++) {
      // For each answer and using the appropriate collectionInstanceIdentifier,
      // go evaluate if we need to clear the node-id at this index.
      // 1. Remove parent from list of node that we are under.
      // 2. Add the parent and its index to the collectionIdentifiers so that we keep the context.
      iterateAndDetermineWhichNodeIdsToClear({
        ...context,
        scope: { ...scope, [selfRepeatable]: index },
      })({
        ...conditionsWithNodeIdsToClear,
        selfRepeatable: undefined,
      });
    }
    return true;
  }

  function determineNodeIdsToClearIfParentIsRepeatable(
    context: Context,
    conditionsWithNodeIdsToClear: VisibilityConditionsWithNodeIdsToClear,
  ) {
    const { answers, answersResolver, scope } = context;
    return (key: keyof UnderRepeatableParents): HaveHandledRepeatableParent => {
      const { underRepeatableParents } = conditionsWithNodeIdsToClear;

      const repeatableParentNodeId = underRepeatableParents?.[key];
      if (!repeatableParentNodeId) {
        return false;
      }

      // Check the numbers of answers associated with a condition on a field within something repeatable.
      const numberOfRepeatableParentAnswers = answersResolver.getRepetitionCount(
        answers,
        repeatableParentNodeId,
        scope,
      );

      if (!numberOfRepeatableParentAnswers) {
        return false;
      }
      for (let index = 0; index < numberOfRepeatableParentAnswers; index++) {
        // For each answer and using the appropriate collectionInstanceIdentifier,
        // go evaluate if we need to clear the node-id at this index.
        // 1. Remove parent from list of node that we are under.
        // 2. Add the parent and its index to the collectionIdentifiers so that we keep the context.
        const newContextForLeaf = {
          ...context,
          scope: { ...scope, [repeatableParentNodeId]: index },
        };
        const newParamsWithoutTheCurrentRepeatableParent = {
          ...conditionsWithNodeIdsToClear,
          underRepeatableParents: { ...underRepeatableParents, [key]: undefined },
        };

        iterateAndDetermineWhichNodeIdsToClear(newContextForLeaf)(newParamsWithoutTheCurrentRepeatableParent);
      }
      return true;
    };
  }

  function isNodeInstanceInvisibleEverywhere(
    nodeIdToClear: string,
    scope: InstanceScope,
    answers: Answers,
    answersResolver: IAnswerResolver,
    dependencyMap: VisibilityDependencyMap,
    currentDateOverride: string | null,
  ): boolean {
    if (!dependencyMap.hasMultiNodeVisibilityConditions(nodeIdToClear)) {
      // nodeId is not used in multiple places. Safe to clear.
      return true;
    }

    const multiNodeVisibility = dependencyMap.getVisibilityConditions(nodeIdToClear);
    if (!multiNodeVisibility) {
      return true; // Should never be called since `hasMultiNodeVisibilityConditions` implies visibility conditions exist.
    }

    // nodeId is used multiple places, if it's visible in any then keep the value in answers.
    const isAnyFieldVisible = evaluateConditions(
      { conditions: multiNodeVisibility.field, operator: BooleanOperator.or },
      answers,
      answersResolver,
      scope,
      timezone,
      currentDateOverride,
    ).isValid;
    return !isAnyFieldVisible;
  }

  function isSelectOptionInstanceInvisibleEverywhere(
    optionData: { nodeId: string; optionId: string },
    scope: InstanceScope,
    answers: Answers,
    answersResolver: IAnswerResolver,
    dependencyMap: VisibilityDependencyMap,
  ): boolean {
    if (!dependencyMap.hasMultiNodeVisibilityConditions(optionData.nodeId)) {
      // This nodeId is not used in multiple places. Safe to clear.
      return true;
    }

    const multiNodeVisibility = dependencyMap.getVisibilityConditions(optionData.nodeId);
    if (!multiNodeVisibility) {
      return true; // Should never be called since `hasMultiNodeVisibilityConditions` implies visibility conditions exist.
    }

    const optionVisibility = multiNodeVisibility.selectOptions.get(optionData.optionId);
    if (!optionVisibility) {
      return true;
    }

    const isAnyOptionVisible = evaluateConditions(
      { conditions: optionVisibility, operator: BooleanOperator.or },
      answers,
      answersResolver,
      scope,
      timezone,
      currentDateOverride,
    ).isValid;
    return !isAnyOptionVisible;
  }

  function unsetOptions(
    clearOptions: { nodeId: string; optionId: string }[],
    clearDynamicOptions: { nodeId: string; optionId: string }[],
    scope: InstanceScope,
    answers: Answers,
    answersResolver: IAnswerResolver,
    dependencyMap: VisibilityDependencyMap,
  ): NodeInstance[] {
    const optionIdChangedUnderNodeInstance: NodeInstance[] = [];
    clearOptions
      .concat(clearDynamicOptions)
      .filter((optionData) =>
        isSelectOptionInstanceInvisibleEverywhere(optionData, scope, answers, answersResolver, dependencyMap),
      )
      .forEach((optionToClear) => {
        const didUnsetOption = answersResolver.unsetAnswerSelectOptionId(
          answers,
          optionToClear.nodeId,
          optionToClear.optionId,
          scope,
        );

        if (didUnsetOption) {
          // If an option changed consider the entire field modified.
          optionIdChangedUnderNodeInstance.push({ id: optionToClear.nodeId, scope });
        }
      });

    return optionIdChangedUnderNodeInstance;
  }

  function filterDynamicOptionAnswers(
    answers: Answers,
    answersResolver: IAnswerResolver,
    scope: InstanceScope,
    currentDateOverride: string | null,
    dynamicOptionsDetails?: DynamicOptionsDetails,
  ): { clearDynamicOptionFieldNodeId?: string; clearDynamicOptions: { nodeId: string; optionId: string }[] } {
    if (!dynamicOptionsDetails) {
      return { clearDynamicOptions: [] };
    }

    const { nonDynamicIds, fieldNodeId } = dynamicOptionsDetails;

    // Compute dynamic options with our updated answers.
    const dynamicOptions = getDynamicOptions(dynamicOptionsDetails.dynamicOptions, answers, scope, answersResolver);

    // Determine which options are still visible.
    const visibleDynamicOptions = dynamicOptions.filter((dynamicOption) => {
      return (
        !dynamicOption.visibleIf ||
        evaluateConditions(dynamicOption.visibleIf, answers, answersResolver, scope, timezone, currentDateOverride)
          .isValid
      );
    });

    const allCurrentOptionIds = new Set<string>([
      ...visibleDynamicOptions.map((option) => option.id),
      ...nonDynamicIds,
    ]);
    const fieldAnswer = answersResolver.getAnswer(answers, fieldNodeId, scope);

    let clearNodeId: string | undefined = undefined;
    const clearDynamicOptions: { nodeId: string; optionId: string }[] = [];

    // Compare field answer to currently available options.
    if (Array.isArray(fieldAnswer)) {
      // Multi-select.

      fieldAnswer.forEach((answerOptionId) => {
        if (nonDynamicIds.includes(answerOptionId)) {
          return;
        }

        if (!visibleDynamicOptions.includes(answerOptionId)) {
          // This is a dynamic option that no longer exists.
          clearDynamicOptions.push({ optionId: answerOptionId, nodeId: fieldNodeId });
        }
      });
    } else if (!allCurrentOptionIds.has(fieldAnswer)) {
      // Single select answer no longer exists.
      clearNodeId = fieldNodeId;
    }

    return { clearDynamicOptionFieldNodeId: clearNodeId, clearDynamicOptions };
  }
}
