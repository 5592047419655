import { ReactElement, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getRepeatedQuestionIndex,
  isRenderingRepeatedQuestion,
  RenderingQuestion,
} from '@breathelife/questionnaire-engine';
import { InstanceScope } from '@breathelife/types';
import { ImageTypes } from '@breathelife/ui-components';

import { FieldGroupCardModal } from '../../../../../Components/AssistedApplication/Modals/FieldGroupCard/FieldGroupCardModal';
import { Icon } from '../../../../../Components/Icons';
import { buildAttributeIdWithIndex } from '../../../../../Helpers/assistedApplication/attributeIds';
import { useModalState } from '../../../../../Hooks';
import { useAssistedApplicationContext } from '../../../../../Hooks/useAssistedApplicationContext';
import { FieldGroupCardPreview } from './FieldGroupCardPreview';
import { AddButton, DeleteButton } from './Styles';

type FieldGroupCardContainerProps = {
  question: RenderingQuestion;
  focusedIncompleteFieldId: string | undefined;
};

export function FieldGroupCardContainer(props: FieldGroupCardContainerProps): ReactElement {
  const { question, focusedIncompleteFieldId } = props;
  const { t } = useTranslation();
  const [isModalOpen, onOpenModal, onCloseModal] = useModalState();
  const { onAnswerChange, onBulkAnswerClear } = useAssistedApplicationContext();

  const newRepeatedInstanceIdentifierContext: InstanceScope | undefined = useMemo(() => {
    if (isRenderingRepeatedQuestion(question) && question.showAddQuestionButton) {
      const questionNodeId = Object.keys(question.metadata.repeatedInstanceIdentifierContext).find(
        (nodeId) => nodeId === question.nodeId,
      );
      if (questionNodeId) {
        return {
          ...question.metadata.repeatedInstanceIdentifierContext,
          [questionNodeId]: getRepeatedQuestionIndex(question) + 1,
        };
      }
    }
    return question.metadata?.repeatedInstanceIdentifierContext;
  }, [question]);

  const hasFocusedIncompleteField = question.fields.some((field) => field.blueprintId === focusedIncompleteFieldId);

  return (
    <Fragment>
      <FieldGroupCardPreview question={question} onEdit={onOpenModal} boldedBorder={hasFocusedIncompleteField} />

      {isRenderingRepeatedQuestion(question) && question.showAddQuestionButton && (
        <AddButton
          variant='text'
          color='primary'
          startIcon={
            <Icon
              name='add'
              variant='primary'
              imageType={ImageTypes.decorative}
              id={buildAttributeIdWithIndex(question.addQuestionButtonText, question.metadata.repetitionIndex + 1)}
            />
          }
          onClick={() =>
            onAnswerChange([
              {
                id: question.nodeId,
                value: {},
                effects: question.effects,
                repeatedIndices: newRepeatedInstanceIdentifierContext,
              },
            ])
          }
        >
          {question.addQuestionButtonText}
        </AddButton>
      )}

      <FieldGroupCardModal
        isOpen={isModalOpen}
        onCloseModal={onCloseModal}
        secondaryAction={
          isRenderingRepeatedQuestion(question) && question.showRemoveQuestionButton ? (
            <DeleteButton
              variant='text'
              startIcon={
                <Icon
                  name='delete'
                  variant='red'
                  imageType={ImageTypes.decorative}
                  id={buildAttributeIdWithIndex(question.removeQuestionButtonText, question.metadata.repetitionIndex)}
                />
              }
              onClick={() => {
                onCloseModal();
                onAnswerChange([
                  {
                    id: question.nodeId,
                    value: undefined,
                    effects: question.effects,
                    repeatedIndices: question.metadata.repeatedInstanceIdentifierContext,
                  },
                ]);
              }}
            >
              {question.removeQuestionButtonText}
            </DeleteButton>
          ) : (
            <DeleteButton
              variant='text'
              startIcon={<Icon imageType={ImageTypes.decorative} name='delete' variant='red' id='clear' />}
              onClick={() => {
                onCloseModal();
                void onBulkAnswerClear(
                  question.fields,
                  undefined,
                  question.metadata?.repeatedInstanceIdentifierContext,
                );
              }}
            >
              {t('cta.clear')}
            </DeleteButton>
          )
        }
        question={question}
      />
    </Fragment>
  );
}
