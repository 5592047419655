import { Box } from '@breathelife/mui';

import { ReactNode, ReactElement, Fragment } from 'react';
import _ from 'lodash';

import { LIST_FILTER_HEIGHT } from '../../Models/Layout';
import styled from '../../Styles/themed-styled-components';

type ListFilterContainerProps = {
  children: ReactNode;
};

const StyledListFilterContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: ${LIST_FILTER_HEIGHT};

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.md}) {
    display: none;
  }
`;

const MobileStyledListFilterContainer = styled(Box)`
  display: none;

  @media (max-width: ${(props) => props.theme.moduleBreakpoints.md}) {
    display: flex;
    flex-direction: column;

    button {
      margin: 10px;
    }
  }
`;

export function ListFilterContainer(props: ListFilterContainerProps): ReactElement {
  return (
    <Fragment>
      <StyledListFilterContainer>{props.children}</StyledListFilterContainer>
      <MobileStyledListFilterContainer>{props.children}</MobileStyledListFilterContainer>
    </Fragment>
  );
}
