import { ReactNode, ReactElement, Fragment } from 'react';
import styled from 'styled-components';

import { Box, IconButton, Snackbar, SnackbarOrigin } from '@breathelife/mui';
import { IconName } from '@breathelife/types';

import { Icon } from '../Icon/Icon';
import { Text } from '../Typography';
import { StyledSnackbarContent } from './Styles';
import { coreGreys } from '../../Theme/CoreColors';

const variantIcon = {
  success: <Icon name={IconName.success} size='20px' color={{ primary: '#1D8046' }} />,
  warning: <Icon name={IconName.warning} size='20px' color={{ primary: '#D9A810' }} />,
  error: <Icon name={IconName.error} size='20px' color={{ primary: '#D31919' }} />,
  info: <Icon name={IconName.information} size='20px' color={{ primary: '#115BC0' }} />,
};

export enum MessageType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

type Props = {
  type: MessageType;
  position: SnackbarOrigin;
  autoHideDuration?: number;
  title?: ReactNode;
  message?: ReactNode;
  display: boolean;
  onClose: () => void;
  dataTestId?: string;
};

const StyledSnackbar = styled(Snackbar)`
  && {
    top: 0;
    right: auto;
    left: auto;
    width: 100%;
    padding: 16px;

    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      top: 16px;
      right: 16px;
      width: auto;
      padding: 0;
    }
  }
`;

export function Notification(props: Props): ReactElement {
  const TypeIcon = variantIcon[props.type];

  return (
    <StyledSnackbar
      data-testid={props.dataTestId}
      anchorOrigin={props.position}
      open={props.display}
      autoHideDuration={props.autoHideDuration}
      onClose={props.onClose}
    >
      <StyledSnackbarContent
        aria-describedby='client-snackbar'
        message={
          <Box id='client-snackbar' display='flex' alignItems={props.title ? 'start' : 'center'}>
            {TypeIcon}
            <Box display='flex' flexDirection='column' justifyContent='center' pl={2}>
              {props.title ? (
                <Fragment>
                  <Box mb={1} pr={1}>
                    <Text component='span' variant='title' grey={90}>
                      {props.title}
                    </Text>
                  </Box>
                  <Box>
                    <Text component='span' variant='small-copy' grey={70}>
                      {props.message}
                    </Text>
                  </Box>
                </Fragment>
              ) : (
                // If no title is given, the message automatically becomes the title
                <Box pr={1}>
                  <Text component='span' variant='title' grey={90}>
                    {props.message}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        }
        action={[
          <IconButton key='close' aria-label='Close' color='inherit' size='small' onClick={props.onClose}>
            <Icon color={{ primary: coreGreys[70] }} size='20px' name={IconName.close2} />
          </IconButton>,
        ]}
      />
    </StyledSnackbar>
  );
}

Notification.defaultProps = {
  position: {
    vertical: 'top',
    horizontal: 'right',
  } as SnackbarOrigin,
};
