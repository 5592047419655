import { ReactComponentElement, ReactElement, SyntheticEvent, useContext } from 'react';

import { Box, Tab, Tabs as MuiTabs } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';
import { ErrorCounter } from '../Badge/ErrorCounter';
import { CarrierContext } from '../../Context/CarrierContext';
import { Icon } from '../Icons';
import { ImageTypes } from '@breathelife/ui-components';
import { useTranslation } from 'react-i18next';

const StyledTab = styled(Tab)`
  &&& {
    padding: 0;
    min-width: unset;
    margin-left: 20px;

    &:first-child {
      margin-left: unset;
    }
  }
`;

export type TabType = {
  label: string;
  numberOfErrors?: number;
  completed?: boolean;
  hidden?: boolean;
  disabled?: boolean;
};

export type Props = {
  tabs: TabType[];
  value: number | false;
  onChange: (event: SyntheticEvent, value: number) => void;
};

type TabPanelProps = {
  selectedIndex: number | undefined;
  tabIndex: number;
  children: ReactComponentElement<any> | null;
};

export function TabPanel(props: TabPanelProps): ReactElement | null {
  return props.selectedIndex === props.tabIndex ? props.children : null;
}
export function Tabs(props: Props): ReactElement {
  const { features } = useContext(CarrierContext);
  const { t } = useTranslation();
  return (
    <MuiTabs variant='scrollable' indicatorColor='primary' textColor='primary' {...props}>
      {props.tabs.map((tab, index) => (
        <StyledTab
          key={`rightPanelTab-${index}`}
          iconPosition='start'
          label={
            <Box display='flex' justifyContent='center' alignItems='center'>
              {tab.label}
              {tab.completed ? (
                <Box ml={1} display='flex' justifyContent='center' alignItems='center'>
                  <Icon
                    width='15px'
                    height='15px'
                    name='circledCheckMark'
                    variant='green'
                    inverted
                    stroke='none'
                    imageType={ImageTypes.meaningful}
                    ariaLabel={t('assistedApplication.sectionComplete')}
                  />
                </Box>
              ) : undefined}
              {tab.numberOfErrors && features.incompleteFieldsSupport.enabled ? (
                <Box ml={1}>
                  <ErrorCounter count={tab.numberOfErrors} max={9} />
                </Box>
              ) : undefined}
            </Box>
          }
          data-testid={`tab-${tab.label.toLowerCase().replace(' ', '-')}`}
          hidden={tab.hidden}
          disabled={tab.disabled}
        />
      ))}
    </MuiTabs>
  );
}

export const TabsContainer = styled(Box)`
  && {
    border-color: ${(props) => props.theme.colors.grey[40]};
    box-shadow: 0 1px 5px rgba(9, 30, 66, 0.05);
  }
`;
