import { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import { StandardTextFieldProps } from '@breathelife/mui';
import { ConditionValidationData } from '@breathelife/types';

import { FieldProps, formatRequiredFieldTitle, InputVariant } from './Helpers/FieldHelper';
import { Input } from './TextInput';

type DatePickerProps = Omit<FieldProps, 'onAnswerChange'> & {
  inputVariant?: InputVariant;
  onAnswerChange?: (answer: string) => void;
  onAnswerComplete?: (fieldId: string, answer: string | number, previousAnswer?: string | number) => void;
  required?: boolean;
  validationData?: ConditionValidationData;
  value?: string;
  boldedBorder?: boolean;
};

type Props = StandardTextFieldProps & DatePickerProps;

export function DatePicker(props: Props): ReactElement {
  const {
    disabled,
    id,
    inputVariant,
    label,
    name,
    onAnswerChange,
    onAnswerComplete,
    optionalText,
    required,
    subtitle,
    title,
    validationData,
    validationError,
    value: valueFromProp,
    boldedBorder,
  } = props;
  const [value, setValue] = useState(valueFromProp);
  const [valueOnPreviousBlur, setValueOnPreviousBlur] = useState(valueFromProp);
  const labelValue = formatRequiredFieldTitle(required, title, label, optionalText);

  useEffect(() => {
    // If the data changes outside of user interaction (eg: update UI instance on focus), we need to update the value
    setValue(valueFromProp);
  }, [valueFromProp]);

  const onChange = useCallback((answer: string): void => setValue(answer), []);

  const onBlur = (): void => {
    if (onAnswerChange && typeof value !== 'undefined') {
      onAnswerChange(value);
    }
    if (onAnswerComplete && typeof value !== 'undefined') {
      onAnswerComplete(name, value, valueOnPreviousBlur);
    }
    setValueOnPreviousBlur(value);
  };

  return (
    <Input
      boldedBorder={boldedBorder}
      id={id}
      data-testid={props['data-testid']}
      name={name}
      error={!!validationError}
      label={labelValue}
      subtitle={labelValue ? subtitle : formatRequiredFieldTitle(required, subtitle, '')}
      validationError={validationError}
      inputVariant={inputVariant}
      disabled={disabled}
      type='date'
      value={value}
      onBlur={onBlur}
      required={required}
      inputProps={{
        max: validationData?.dateMax ?? '9999-12-31',
        min: validationData?.dateMin ?? '1900-01-01',
        /**
         * Native HTML5 date input doesn't support assistive technologies for required property. As a simple workaround, will add required attribute to the label.
         */
        ['aria-label']: required ? 'required' : '',
      }}
      onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
    />
  );
}
