import _ from 'lodash';
import { ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../Styles/themed-styled-components';

import { Permission, User } from '@breathelife/types';

import { Icon } from '../../../../Components/Icons';
import { Image } from '../../../../Components/Images/Image';
import { CarrierContext } from '../../../../Context/CarrierContext';
import { userHasPermission } from '../../../../Helpers/user';
import { useSelector } from '../../../../Hooks';
import { Lead } from '../../../../Models/Lead';
import { DefaultLeadsListFilterIds } from '../../../../Pages/Home/Modals/UserListModal/UserListModal';
import { useAssignLeadsMutation } from '../../../../ReactQuery/Lead/lead.mutations';
import { GetUsersOptions } from '../../../../Services/Users/UsersService';
import { LeadPlatformTheme } from '../../../../Styles/Types';
import { UserListModal, UserListModalItem } from './UserListModal';
import { useUsers } from '../../../../ReactQuery/Admin/Users/users.queries';

type LeadsAssignModalContainerProps = {
  lead: Lead;
  isOpen: boolean;
  closeModal: () => void;
  submitLabel: string;
};

export function LeadsAssignModalContainer(props: LeadsAssignModalContainerProps): ReactElement {
  const { t } = useTranslation();
  const { features } = useContext(CarrierContext);
  const theme = useTheme() as LeadPlatformTheme;

  const [fetchUsersOptions, setFetchUsersOptions] = useState<GetUsersOptions>({});

  const { data: users, isLoading } = useUsers(fetchUsersOptions);
  const currentUser = useSelector((store) => store.leadPlatform.authentication.user) as User;

  const { lead, closeModal } = props;

  // @TODO We will need to support multiple leads being assigned/unassigned in the future.
  const selectedLeads = useMemo(() => [lead], [lead]);

  // If assignedToId is null, this means it is unassigned. If the length is greater than one, we will default to currentUser for now until we support multiple lead slections
  const defaultSelectedUserId =
    selectedLeads.length === 1
      ? selectedLeads[0].assignedToId || DefaultLeadsListFilterIds.Unassigned
      : currentUser.auth0Id;

  const isSearchEnabled = useMemo(
    () => userHasPermission(currentUser.permissions, [Permission.LeadAssignGroup, Permission.LeadAssignAll]),
    [currentUser.permissions],
  );

  const assignLeadsMutation = useAssignLeadsMutation({
    onSuccess: () => {
      closeModal();
    },
  });

  const onModalSubmit = useCallback(
    (userId: string): void => {
      const leadIds = _.map(selectedLeads, 'id');
      assignLeadsMutation.mutate({ leadIds, userId });
    },
    [assignLeadsMutation, selectedLeads],
  );

  const filteredUsers = useMemo(
    () => users?.data.filter((user) => user.auth0Id !== currentUser?.auth0Id),
    [users, currentUser],
  );

  const title =
    selectedLeads.length === 1
      ? t(
          features.leadCreation.enabled
            ? 'modals.leadsAssign.assignSingleLeadTitle'
            : 'modals.leadsAssign.assignSingleApplicationTitle',
        )
      : t(
          features.leadCreation.enabled
            ? 'modals.leadsAssign.assingMultipleLeadsTitle'
            : 'modals.leadsAssign.assignMultipleApplicationsTitle',
          { numberOfLeads: selectedLeads.length },
        );

  const canUnassign = useMemo(() => {
    const canUnassignMe = userHasPermission(currentUser.permissions, [Permission.LeadUnassignMe]);
    const canUnassignAll = userHasPermission(currentUser.permissions, [Permission.LeadUnassignAll]);
    if (currentUser.auth0Id === defaultSelectedUserId) {
      return canUnassignMe;
    }
    if (canUnassignAll) return true;

    const areEveryLeadsAssignedToUser = selectedLeads.every((lead) => lead.assignedToId === currentUser.auth0Id);
    const areEveryLeadsUnassigned = selectedLeads.every((lead) => !lead.assignedToId);

    return areEveryLeadsAssignedToUser || areEveryLeadsUnassigned;
  }, [currentUser.permissions, defaultSelectedUserId, selectedLeads, currentUser.auth0Id]);

  const canAssignMe = useMemo(
    () => userHasPermission(currentUser.permissions, Permission.LeadAssignMe),
    [currentUser.permissions],
  );

  const staticItems: UserListModalItem[] = [];
  if (canAssignMe) {
    staticItems.push({
      title: currentUser.firstName + ' ' + currentUser.lastName,
      subtitle: t('modals.leadsAssign.assignToMe'),
      icon: currentUser.picture ? <Image src={currentUser.picture} width={30} height={30} /> : null,
      id: currentUser.auth0Id,
    });
  }

  if (canUnassign) {
    staticItems.push({
      title: t('modals.leadsAssign.unassignedTitle'),
      subtitle: t(
        features.leadCreation.enabled
          ? 'modals.leadsAssign.unassignedLeadCardSubtitle'
          : 'modals.leadsAssign.unassignedApplicationCardSubtitle',
      ),
      id: DefaultLeadsListFilterIds.Unassigned,
      icon: (
        <Icon
          name='unassignedProfilePicture'
          width='30'
          height='30'
          fill={theme.colors.grey[0]}
          stroke={theme.colors.grey[50]}
        />
      ),
    });
  }

  return (
    <UserListModal
      {...props}
      users={filteredUsers || []}
      staticItems={staticItems}
      defaultSelectedUserId={defaultSelectedUserId}
      isSearchEnabled={isSearchEnabled}
      title={title}
      setFetchUsersOptions={setFetchUsersOptions}
      isLoading={isLoading}
      isAssigningLeads={assignLeadsMutation.isLoading}
      onModalSubmit={onModalSubmit}
    />
  );
}
