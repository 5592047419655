import { ReactNode, UIEvent, ReactElement } from 'react';

import { Box } from '@breathelife/mui';

import { Container, Content, HeaderContainer, LeftPanelContainer, MainContainer } from './Styles';

export type TransactionFlowLayoutProps = {
  id?: string;
  leftPanel?: ReactNode;
  header: ReactNode;
  mainContent: ReactNode;
  popup?: ReactNode;
  hideHeaderOnDesktop?: boolean;
  infoView?: ReactNode;
  onMainContentScroll?: (event: UIEvent<HTMLDivElement>) => void;
  isAgentSession?: boolean;
};

export function TransactionFlowBaseLayout(props: TransactionFlowLayoutProps): ReactElement {
  return (
    <Container id={props.id} $isAgentSession={Boolean(props.isAgentSession)}>
      {props.popup}
      {props.leftPanel && <LeftPanelContainer>{props.leftPanel}</LeftPanelContainer>}
      <Content
        id='main-content'
        onScroll={props.onMainContentScroll}
        onTouchStart={props.onMainContentScroll}
        onTouchMove={props.onMainContentScroll}
        onTouchEnd={props.onMainContentScroll}
      >
        {props.hideHeaderOnDesktop ? (
          <Box sx={{ display: { md: 'none', xs: 'block' } }}>
            <HeaderContainer role='banner'>{props.header}</HeaderContainer>
          </Box>
        ) : (
          <HeaderContainer role='banner'>{props.header}</HeaderContainer>
        )}
        {!props.isAgentSession && <Box>{props.infoView}</Box>}
        <MainContainer $isAgentSession={Boolean(props.isAgentSession)}>{props.mainContent}</MainContainer>
      </Content>
    </Container>
  );
}
