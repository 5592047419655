import { v4 as uuid } from 'uuid';

import { evaluateConditions, evaluateQuery } from '@breathelife/condition-engine';
import {
  Conditions,
  EffectValue,
  Query,
  SetValueEffect,
  SetValueEffectSpecialValue,
  InstanceScope,
  Answers,
  IAnswerResolver,
  Timezone,
  ApplicationContext,
} from '@breathelife/types';

import { VisibilityDependencyMap } from './nodeEvaluation/visibleIf/dependencyMap';
import { filterVisibleAnswers } from './nodeEvaluation/visibleIf/filterVisibleAnswers';

export function processSetValueEffects(
  setValueEffects: SetValueEffect[] | undefined,
  changedAnswer: unknown,
  answers: Answers,
  answersResolver: IAnswerResolver,
  dependencyMap: VisibilityDependencyMap,
  scope: InstanceScope,
  timezone: Timezone,
  applicationContext: ApplicationContext,
  currentDateOverride: string | null,
): void {
  if (!setValueEffects) {
    return;
  }

  const changedNodeIds = setValueEffects
    .map((effect: SetValueEffect) => {
      const { at, value, newAnswerMustMatch, triggerIf } = effect;

      if (newAnswerMustMatch && !(newAnswerMustMatch === changedAnswer)) {
        return; // changedAnswer does not match, skip this effect.
      }

      if (triggerIf && !shouldApplyEffect(triggerIf, answers, answersResolver, scope, timezone, currentDateOverride)) {
        return;
      }

      const valueToSet = resolveValueFromEffectValue(
        value,
        answers,
        answersResolver,
        scope,
        timezone,
        currentDateOverride,
        applicationContext,
      );

      const currentValue = answersResolver.getAnswer(answers, at, scope);
      if (valueToSet !== currentValue) {
        answersResolver.setAnswer(valueToSet, answers, at, scope);
        return at;
      }
    })
    .filter(Boolean) as string[];

  filterVisibleAnswersAfterEffectChanges(
    changedNodeIds,
    answers,
    answersResolver,
    dependencyMap,
    scope,
    timezone,
    currentDateOverride,
  );
}

function filterVisibleAnswersAfterEffectChanges(
  nodeIds: string[],
  answers: Answers,
  answersResolver: IAnswerResolver,
  dependencyMap: VisibilityDependencyMap,
  scope: InstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): void {
  let updatedAnswers = answers;

  for (const nodeId of nodeIds) {
    updatedAnswers = filterVisibleAnswers(
      nodeId,
      dependencyMap,
      answersResolver,
      updatedAnswers,
      scope,
      timezone,
      currentDateOverride,
    );
  }
}

function shouldApplyEffect(
  triggerIf: Conditions,
  answers: Answers,
  answersResolver: IAnswerResolver,
  scope: InstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
): boolean {
  return evaluateConditions(triggerIf, answers, answersResolver, scope, timezone, currentDateOverride).isValid;
}

function resolveValueFromEffectValue(
  effectValue: EffectValue,
  answers: Answers,
  answersResolver: IAnswerResolver,
  scope: InstanceScope,
  timezone: Timezone,
  currentDateOverride: string | null,
  applicationContext?: Record<string, unknown>,
): string | boolean | number | undefined {
  if (effectValue === SetValueEffectSpecialValue.newId) return uuid();

  if (isQueryEffectValue(effectValue)) {
    const queryEffectValue = evaluateQuery(
      effectValue,
      answers,
      answersResolver,
      scope,
      timezone,
      applicationContext,
      currentDateOverride,
    );
    if (
      typeof queryEffectValue !== 'string' &&
      typeof queryEffectValue !== 'boolean' &&
      typeof queryEffectValue !== 'number' &&
      typeof queryEffectValue !== 'undefined'
    ) {
      return;
    }
    return queryEffectValue;
  }

  return effectValue;
}

function isQueryEffectValue(effectValue: EffectValue): effectValue is Query {
  return !!effectValue && !!(effectValue as Query).select;
}
