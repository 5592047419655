import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, UseMutationResult, UseMutationOptions } from 'react-query';

import {
  Answers,
  ComprehensivePricingAPIReturnValue,
  CreateApplicationPayload,
  LineOfBusinessName,
  QuoteInfo,
} from '@breathelife/types';

import { useDispatch } from '../../Hooks';
import { getTranslationKeyFromErrorId } from '../../Localization/errorIdsToTranslationMap';
import { Application } from '../../Models/Application';
import { QueryId } from '../../ReactQuery/common/common.types';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';

import {
  launchAssistedApplication,
  launchNewAssistedApplication,
  updateAssistedApplicationAnswers,
  getComprehensivePricing,
} from '../../Services/AssistedApplicationsService';
import { useContext } from 'react';
import { LeadsPageDataContext } from '../../Pages/Leads/LeadsPageDataContextProvider';
import { getQuotes } from '../../Services/QuotesService';
import { productsSlice } from '../../ReduxStore/Products/ProductsSlice';

export function useLaunchNewAssistedApplicationMutation(
  options?: UseMutationOptions<Application, unknown, CreateApplicationPayload>,
): UseMutationResult<Application, unknown, CreateApplicationPayload> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { setIsLoadingApplication } = useContext(LeadsPageDataContext);
  return useMutation<Application, unknown, CreateApplicationPayload>(launchNewAssistedApplication, {
    ...options,
    onMutate: () => {
      setIsLoadingApplication(true);
    },
    onSettled: () => {
      setIsLoadingApplication(false);
    },
    onSuccess: async (data, variables, context) => {
      queryClient.setQueryData([QueryId.application, data.id], data);

      void queryClient.invalidateQueries([QueryId.lead, data.leadId]);
      void queryClient.invalidateQueries([QueryId.leads]);
      void queryClient.invalidateQueries([QueryId.usedLeadStatuses]);

      if (options?.onSuccess) {
        await options.onSuccess(data, variables, context);
      }
    },
    onError: async (error, variables, context) => {
      dispatch(
        notificationSlice.actions.setError({
          message: t('notifications.failedToCreateApplication'),
        }),
      );

      if (options?.onError) {
        await options.onError(error, variables, context);
      }
    },
  });
}

type LaunchAssistedApplicationParams = {
  applicationId: string;
  product?: string;
  coverageAmount?: number;
};

export function useLaunchAssistedApplicationMutation(
  options?: UseMutationOptions<Application, unknown, LaunchAssistedApplicationParams>,
): UseMutationResult<Application, unknown, LaunchAssistedApplicationParams> {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Application, unknown, LaunchAssistedApplicationParams>(
    ({ applicationId, product, coverageAmount }) =>
      launchAssistedApplication(applicationId, {
        coverageAmount,
        product,
      }),
    {
      ...options,

      onSuccess: async (data, variables, context) => {
        queryClient.setQueryData([QueryId.application, data.id], data);

        if (options?.onSuccess) {
          await options.onSuccess(data, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToUpdateCoverageAmount'),
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}

export type SaveAssistedApplicationAnswersParams = {
  applicationId: string;
  answers: Answers;
  answersV2: Answers;
  updatedNodeIds: string[];
  isClosing: boolean;
  isManuallySavingAnswers: boolean;
  nodesToRefresh?: Record<string, unknown>[];
};

type SaveAssistedApplicationAnswersMutation = UseMutationResult<
  Application,
  unknown,
  SaveAssistedApplicationAnswersParams
>;

export function useSaveAssistedApplicationAnswersMutation(
  options?: UseMutationOptions<Application, unknown, SaveAssistedApplicationAnswersParams>,
): SaveAssistedApplicationAnswersMutation {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation<Application, unknown, SaveAssistedApplicationAnswersParams>(
    ({ applicationId, answers, answersV2 }) =>
      updateAssistedApplicationAnswers(applicationId, {
        answers,
        answersV2,
      }),
    {
      ...options,
      onSuccess: async (application, variables, context) => {
        await queryClient.invalidateQueries([QueryId.participantProcessor, application.id]);

        if (options?.onSuccess) {
          await options.onSuccess(application, variables, context);
        }
      },
      onError: async (error, variables, context) => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToUpdateApplicationAnswers'),
          }),
        );

        if (options?.onError) {
          await options.onError(error, variables, context);
        }
      },
    },
  );
}

export type FetchQuotesMutation = UseMutationResult<QuoteInfo, unknown, FetchQuotesParams>;
type FetchQuotesParams = {
  appId: string;
  coverageAmount?: number | null;
  answers?: Answers;
  includeADO?: boolean;
  includeESA?: boolean;
};

export function useFetchQuotesMutation(
  options?: UseMutationOptions<QuoteInfo, unknown, FetchQuotesParams>,
): FetchQuotesMutation {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation<QuoteInfo, unknown, FetchQuotesParams>((params) => getQuotes(params), {
    ...options,
    onMutate: (variables) => {
      dispatch(productsSlice.actions.setIsLoadingQuotes(true));
      if (variables.includeADO) {
        dispatch(
          notificationSlice.actions.setInfo({
            message: t('notifications.fetchingADOMinMax'),
            autoHideDuration: 500,
          }),
        );
      }
    },
    onSuccess: async (output, variables) => {
      queryClient.setQueryData([QueryId.quotes], output);

      if (variables.includeADO) {
        dispatch(
          notificationSlice.actions.setSuccess({
            message: t('notifications.updatedADOMinMax'),
            autoHideDuration: 1000,
          }),
        );
      }
    },
    onError: async (error, variables) => {
      const otherErrorMessage = variables.includeADO
        ? 'notifications.failedToFetchADOMinMax'
        : variables.includeESA
        ? 'notifications.failedToFetchESA'
        : 'notifications.failedToFetchQuotes';
      const errorTranslationKey = getTranslationKeyFromErrorId(error) || otherErrorMessage;

      dispatch(
        notificationSlice.actions.setError({
          message: t(errorTranslationKey),
        }),
      );
    },
    onSettled: () => {
      dispatch(productsSlice.actions.setIsLoadingQuotes(false));
    },
  });
}

export type FetchComprehensivePricingMutation = UseMutationResult<
  ComprehensivePricingAPIReturnValue,
  unknown,
  FetchComprehensivePricingParams
>;

type FetchComprehensivePricingParams = {
  applicationId: string;
  answers: Answers;
  answersV2: Answers;
  lineOfBusinessName: LineOfBusinessName;
};

export function useFetchComprehensivePricingMutation(
  options?: UseMutationOptions<ComprehensivePricingAPIReturnValue, unknown, FetchComprehensivePricingParams>,
): FetchComprehensivePricingMutation {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return useMutation<ComprehensivePricingAPIReturnValue, unknown, FetchComprehensivePricingParams>(
    (params) =>
      getComprehensivePricing(params.applicationId, params.answers, params.answersV2, params.lineOfBusinessName),
    {
      ...options,
      onMutate: () => {
        dispatch(productsSlice.actions.setIsLoadingComprehensivePricing(true));
      },
      onSuccess: async (output) => {
        dispatch(productsSlice.actions.setComprehensivePricing(output));
      },
      onError: async () => {
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchQuotes'),
          }),
        );
      },
      onSettled: () => {
        dispatch(productsSlice.actions.setIsLoadingComprehensivePricing(false));
      },
    },
  );
}
