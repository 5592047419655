/* eslint-disable @typescript-eslint/naming-convention */
import { Box } from '@breathelife/mui';
import ReactHtmlParser from 'html-react-parser';
import { ChangeEvent, ReactElement, Fragment } from 'react';

import { FieldProps, formatRequiredFieldTitle, SimpleCheckbox, WarningText } from '@breathelife/ui-components';

import { StyleVariant } from '../../FieldGenerator/FieldGenerator';
import { CheckboxFormControl, CheckboxLabel, StyledCheckbox } from './Styles';

type BaseCheckboxFieldProps = Omit<FieldProps, 'onAnswerChange'> & {
  id: string;
  label: string;
  field?: any;
  inline?: boolean;
  required?: boolean;
  showError?: boolean;
  value?: boolean;
  describedBy?: string;
  onAnswerChange?: (answer: boolean) => void;
  onAnswerComplete?: (field: string, answer: boolean, previousAnswerBoolean?: boolean) => void;
  styleVariant: StyleVariant;
  boldedBorder?: boolean;
};

type CheckboxFieldProps = BaseCheckboxFieldProps & {
  isSingleCheckbox?: boolean;
};

export function CheckboxFieldMUI(props: CheckboxFieldProps): ReactElement {
  const {
    describedBy,
    disabled,
    id,
    isSingleCheckbox,
    label,
    name,
    onAnswerChange,
    onAnswerComplete,
    optionalText,
    required,
    showError,
    styleVariant,
    title,
    validationError,
    value,
    boldedBorder,
  } = props;

  const labelValue = formatRequiredFieldTitle(required, title, label, optionalText);

  if (styleVariant === StyleVariant.consumer) {
    return (
      <Fragment>
        <SimpleCheckbox
          id={id}
          label={labelValue}
          checked={Boolean(value)}
          required={required}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onAnswerChange && onAnswerChange(event.target.checked)}
        />
        {validationError && (
          <Box component={WarningText} mt={2}>
            {validationError.message}
          </Box>
        )}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <CheckboxFormControl
        showError={!!showError}
        boldedBorder={boldedBorder}
        label={<CheckboxLabel disabled={disabled}>{ReactHtmlParser(labelValue)}</CheckboxLabel>}
        data-testid={!isSingleCheckbox && id}
        checked={!!value}
        control={
          <StyledCheckbox
            name={name}
            id={id}
            checked={!!value}
            inputProps={{ 'aria-describedby': describedBy }}
            required={required}
            disabled={disabled}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onAnswerChange && onAnswerChange(event.target.checked);
              onAnswerComplete && onAnswerComplete(name, event.target.checked, value);
            }}
          />
        }
      />
      {validationError && <WarningText>{validationError.message}</WarningText>}
    </Fragment>
  );
}

export function CheckboxField(props: BaseCheckboxFieldProps): ReactElement {
  return <CheckboxFieldMUI {...props} isSingleCheckbox={true} />;
}
