import * as yup from 'yup';

import { isValidPhoneNumberAreaCode, phoneAreaCodeList, phoneNumberRegex } from '@breathelife/questionnaire-engine';

// Source https://regex101.com/r/wUJRj6/15
const nameRegex =
  /^((([a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]([\.]?))+?)(([\s'-][a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]([\.]?))+)*?){0,}$/;

type ErrorMessages = {
  phoneErrorText: string;
  emailErrorText: string;
  firstNameErrorText: string;
  lastNameErrorText: string;
};

export function getValidationError(
  validationSchema: yup.ObjectSchema,
  fieldName: string,
  data: Record<string, unknown>,
): string | undefined {
  const fieldValue = data[fieldName];
  if (!fieldValue) return;
  try {
    validationSchema.validateSyncAt(fieldName, data);
    return undefined;
  } catch (error: any) {
    return error.message;
  }
}

export function createScheduleCallModalSchema(messages: ErrorMessages): yup.ObjectSchema {
  return yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(phoneNumberRegex, messages.phoneErrorText)
      .test({
        test: (value) => isValidPhoneNumberAreaCode(value, phoneAreaCodeList),
        message: messages.phoneErrorText,
      }),
    email: yup.string().trim().email(messages.emailErrorText),
    firstName: yup.string().trim().matches(nameRegex, messages.firstNameErrorText),
    lastName: yup.string().trim().matches(nameRegex, messages.lastNameErrorText),
  });
}
