import styled, { css } from 'styled-components';

import { FormControl, FormHelperText } from '@breathelife/mui';

type WarningTextProps = {
  color?: string;
};

export const Container = styled(FormControl)`
  width: 100%;
`;

export const WarningText = styled(FormHelperText)<WarningTextProps>`
  margin-left: 0 !important;
  &&&&& {
    color: ${(props) => props.theme.colors.text.error} !important;
  }
`;

export const SubtitleText = styled.span`
  color: ${(props) => props.theme.colors.grey[80]};
`;

export const TitleText = styled.span`
  color: ${(props) => props.theme.colors.grey[90]};
`;

export const WhiteSpaceContainer = styled.div<{ $wrapContent: boolean }>`
  ${(props) =>
    !props.$wrapContent &&
    css`
      white-space: nowrap;
    `}
`;
