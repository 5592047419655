import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCarrierContext, useNavigation } from '../../../../../Hooks';
import { Lead } from '../../../../../Models/Lead';
import { useLaunchAssistedApplicationMutation } from '../../../../../ReactQuery/AssistedApplication/assistedApplication.mutations';
import {
  ApplicationsContainer,
  ExistingApplicationsContainer,
  LaunchApplicationContainer,
  NaEmptyImage,
} from './Styles';
import { LeadItem } from '../../../../../Components/LeadItem/LeadItem';
import { CreateButton, CreateButtonActionType } from '../../../../../Components/CreateButton/CreateButton';
import { generateApplicationUrl } from '../../../../../Navigation/Urls';
import { Application, Language, Permission } from '@breathelife/types';
import { LanguageDropdown } from '../PersonalInfoView/Styles';
import { RestrictedToUserPermissions } from '../../../../../Components/Restricted/RestrictedToUserPermissions';
import { useUpdateLeadMutation } from '../../../../../ReactQuery/Lead/lead.mutations';
import { TypewriterTracking } from '@breathelife/frontend-tracking';
import { Box } from '@breathelife/mui';
import { ContentHeading } from '../Styles';

type ApplicationsViewContainerProps = {
  lead?: Lead;
  showCommunicationLanguage: boolean;
};

export function ApplicationsViewContainer(props: ApplicationsViewContainerProps): ReactElement | null {
  const { lead, showCommunicationLanguage } = props;
  const { leadTab } = useNavigation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { languageSettings } = useCarrierContext();

  const hasExistingApplication = !!lead?.applications?.length;

  const launchApplicationSuccess = useCallback(
    (application: Application) => {
      if (!application.leadId) return;
      navigate(generateApplicationUrl(application.id, application.leadId, leadTab));
    },
    [leadTab, navigate],
  );

  const launchExistingAssistedApplicationMutation = useLaunchAssistedApplicationMutation({
    onSuccess: (data: Application) => {
      launchApplicationSuccess(data);
    },
  });

  const launchExistingApplication = useCallback(
    (application: Application | undefined, canOnlyReadApplication: boolean) => {
      if (lead?.id && application) {
        if (canOnlyReadApplication) {
          navigate(generateApplicationUrl(application.id, lead.id, leadTab));
        } else {
          launchExistingAssistedApplicationMutation.mutate({
            applicationId: application.id,
          });
        }
      }
    },
    [navigate, launchExistingAssistedApplicationMutation, lead, leadTab],
  );

  const updateLeadMutation = useUpdateLeadMutation({
    onMutate: () => {
      TypewriterTracking.completedField({
        fieldId: 'personalInfoReadView-communicationLanguage-dropdown',
        hashedId: null,
      });
    },
  });

  function onChangeCommunicationLanguage(selectedLanguage: Language): void {
    if (!lead) return;

    updateLeadMutation.mutate({
      id: lead.id,
      communicationLanguage: selectedLanguage,
    });
  }

  if (!lead) return null;

  const leadApplication = lead?.applications[0];

  const title =
    !!leadApplication?.refNoIncrement && !leadApplication?.submitted
      ? leadApplication.refNoIncrement.toString()
      : leadApplication?.refNo;

  return (
    <ApplicationsContainer>
      {!hasExistingApplication && (
        <LaunchApplicationContainer>
          <NaEmptyImage role='img' />
          <CreateButton action={CreateButtonActionType.createApplicationAndLaunch} />
        </LaunchApplicationContainer>
      )}

      {hasExistingApplication && (
        <Box display='flex' flexDirection='column' width='100%'>
          {showCommunicationLanguage && (
            <ContentHeading py={2} px={2.5}>
              <div>
                <RestrictedToUserPermissions requiredPermissions={[Permission.LeadCreate]}>
                  <LanguageDropdown
                    required={false}
                    value={lead.communicationLanguage}
                    id={'applicationsView-communicationLanguage'}
                    label={t('inputs.language')}
                    onChange={onChangeCommunicationLanguage}
                    enabledLanguages={languageSettings.enabledLanguages}
                    disabled={updateLeadMutation.isLoading}
                  />
                </RestrictedToUserPermissions>
              </div>
            </ContentHeading>
          )}
          <ExistingApplicationsContainer>
            <LeadItem
              key={leadApplication.id}
              application={leadApplication}
              isItemSelected={lead?.applications?.[0].id === leadApplication.id}
              lead={lead}
              onLaunchApplication={(application: Application | undefined, canOnlyReadApplication: boolean) =>
                launchExistingApplication(application, canOnlyReadApplication)
              }
              title={'#' + title}
              titleVariant='h2'
            />
          </ExistingApplicationsContainer>
        </Box>
      )}
    </ApplicationsContainer>
  );
}
