import i18next from 'i18next';
import * as yup from 'yup';

import { HoldingForm, Language, LineOfBusinessName, ProductType, SupportedBuyer } from '@breathelife/types';

import { ProductFormFields } from '../../../Pages/Admin/Product/types';
import ApiService from '../../../Services/ApiService';
import { localizableFieldSchema } from '../field';
import { objectSchemaFieldsAreDefined } from '../typeGuards';
import { coverageAmountRangesSchema, validateCoverageAmountRanges } from './coverageAmountRanges';

function productFormFieldsSchema(): yup.ObjectSchema {
  return yup.object().shape({
    name: yup.string().required(i18next.t('validation.error.required')),
    productDescriptor: yup.string(),
    productId: yup.string().required(i18next.t('validation.error.required')),
    lineOfBusiness: yup
      .string()
      .required(i18next.t('validation.error.required'))
      .oneOf(Object.values(LineOfBusinessName)),
    insuranceFirmId: yup.string().required(i18next.t('validation.error.required')),
    type: yup
      .string()
      .nullable()
      .when('lineOfBusiness', {
        is: (lineOfBusiness) => lineOfBusiness === LineOfBusinessName.life,
        then: yup.string().required().oneOf(Object.values(ProductType)),
      }),
    holdingForm: yup.string().required(i18next.t('validation.error.required')).oneOf(Object.values(HoldingForm)),
    supportedBuyers: yup.array().of(yup.string().oneOf(Object.values(SupportedBuyer))),
    minimumAge: yup
      .number()
      .required()
      .min(0, i18next.t('validation.birthDate.min'))
      .max(130, i18next.t('validation.birthDate.max')),
    maximumAge: yup
      .number()
      .required()
      .min(0, i18next.t('validation.birthDate.min'))
      .max(130, i18next.t('validation.birthDate.max')),
    policyDetailsUrl: yup.string().nullable(),
    temporaryInsuranceUrl: yup.string().nullable(),
    renewable: yup.boolean(),
    maxRenewalAge: yup
      .number()
      .nullable()
      .min(0, i18next.t('validation.birthDate.min'))
      .max(130, i18next.t('validation.birthDate.max')),
    convertible: yup.boolean(),
    maxConversionAge: yup
      .number()
      .nullable()
      .min(0, i18next.t('validation.birthDate.min'))
      .max(130, i18next.t('validation.birthDate.max')),
    requiresMedicalExam: yup.boolean(),
    additionalFeatures: yup.string(),
    selector: yup.object().shape({
      coverageAmount: yup.string(),
      id: yup.string().required(),
      premium: yup.string(),
    }),
    index: yup.number().nullable(),
  });
}

export function productFormSchema(enabledLanguages: Language[]): yup.ObjectSchema {
  return yup.object().shape({
    ...productFormFieldsSchema().fields,
    name: localizableFieldSchema(enabledLanguages, { required: true }),
    additionalFeatures: yup.array().of(localizableFieldSchema(enabledLanguages, { required: false })),
    coverageAmountRanges: yup
      .array()
      .of(coverageAmountRangesSchema)
      .test('coverage amount ranges validation', '', (coverageAmountRanges) => {
        return validateCoverageAmountRanges(coverageAmountRanges);
      }),
  });
}

export function getFieldValidationError(
  fieldName: keyof ProductFormFields,
  value: unknown,
): yup.ValidationError | undefined {
  const schema = productFormFieldsSchema();

  if (!objectSchemaFieldsAreDefined(schema)) return;

  const fields = schema.fields as any;
  try {
    fields[fieldName].validateSync(value);
  } catch (error: any) {
    return error as yup.ValidationError;
  }
  return;
}

export const getProductIdUniqueValidationError = async (
  productId: string,
  productUUID: string,
): Promise<undefined | yup.ValidationError> => {
  const schema = yup
    .string()
    .test(
      'product-id-is-unique',
      i18next.t('validation.error.productIdAlreadyExists'),
      async function (value: string | null | undefined) {
        if (value === null || value === undefined) return true;

        try {
          const { data } = await ApiService.admin.fetchProducts({ productId: value });
          if (
            !data ||
            data.length === 0 ||
            // Allow for same productId if the only product returned has the same uuid as the product we are currently editing
            (data.length === 1 && data.find((item) => item.id === productUUID))
          ) {
            return true;
          }
          return false;
        } catch (e: any) {
          // Let the server-side validation handle unique productId check
          return true;
        }
      },
    );
  try {
    await schema.validate(productId);
    return undefined;
  } catch (e: any) {
    return e as yup.ValidationError;
  }
};
