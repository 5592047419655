import { ReactElement } from 'react';

import { Language } from '@breathelife/types';

import { text } from '../../Localization/Localizer';
import { ApplicationState } from '../../Models/ApplicationState';
import { ContactAdvisorTabs, FormProps } from '../Modal/ContactAdvisorModal';
import { LocalizationStrings, OnSubmitScheduleDataType } from '../ContactForm';

type ScheduleCallProps = {
  application?: ApplicationState;
  form: FormProps;
  onSubmitContactForm: (onSubmitContactArgs: OnSubmitScheduleDataType) => void;
  applicationAssigneeImage?: string;
  applicationAssigneePhone?: string;
  applicationAssigneeEmail?: string;
  language: Language;
  hasContactPersonInfo: boolean;
  isPlural: boolean;
  useMobileStylingOnDesktop?: boolean;
  localizationStrings?: Partial<LocalizationStrings>;
};

export function ScheduleCallTabs(props: ScheduleCallProps): ReactElement {
  const contactPhoneNumber = props.applicationAssigneePhone ?? text('scheduleCall.customerServicePhoneNumber');

  return (
    <ContactAdvisorTabs
      useMobileStylingOnDesktop={!!props.useMobileStylingOnDesktop}
      onSubmitContactForm={props.onSubmitContactForm}
      language={props.language}
      form={props.form}
      hasContactPersonInfo={props.hasContactPersonInfo}
      isPlural={props.isPlural}
      customerServicePhoneNumber={contactPhoneNumber}
      customerServiceEmail={props.applicationAssigneeEmail}
      localizationStrings={props.localizationStrings}
    />
  );
}
