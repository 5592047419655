import styled from 'styled-components';

import { Button, Menu, MenuItem } from '@breathelife/mui';

export const StyledHeaderButton = styled(Button)`
  &&& {
    margin: 0 0 0 auto;
    padding: 0 12px 0 2px;
    width: 80px;
    height: 36px;
    border-radius: 100px;
    text-transform: none;
    line-height: 1em;
    display: flex;

    border-style: solid;
    border-width: ${(props) => props.theme.metrics.borders.default};
    border-color: ${(props) => props.theme.colors.button.scheduleCall.border.default};
    border-radius: ${(props) => props.theme.metrics.button.borderRadius};
    background-color: ${(props) => props.theme.colors.button.scheduleCall.background.default};

    &:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-width: ${(props) => props.theme.metrics.borders.default};
      border-color: ${(props) => props.theme.colors.button.scheduleCall.border.hover};
      background-color: ${(props) => props.theme.colors.button.scheduleCall.background.hover};
    }
    &:focus {
      box-shadow: 0 0 0 3px ${(props) => props.theme.colors.hyperlink};
      border-width: ${(props) => props.theme.metrics.borders.focus};
      border-color: ${(props) => props.theme.colors.hyperlink};
      background-color: ${(props) => props.theme.colors.button.scheduleCall.background.hover};
    }
    &:active {
      box-shadow: none;
      border-width: ${(props) => props.theme.metrics.borders.default};
      border-color: ${(props) => props.theme.colors.button.scheduleCall.border.active};
      background-color: ${(props) => props.theme.colors.button.scheduleCall.background.active};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.tablet.min}) {
      padding-right: 16px;
      width: max-content;
    }
  }

  &&& {
    padding: 0px;
    min-width: 36px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding: 0;
  }

  .MuiList-root {
    border: 1px solid ${(props) => props.theme.colors.grey.light};
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  height: 40px;
  min-width: 180px;
  font-size: 16px;
  line-height: 22px;
  box-shadow:
    inset 0px -1px 0px #f6f6f6,
    inset 0px 1px 0px #fbfbfb;
  background: ${(props) => props.theme.colors.grey[10]} !important;
  color: ${(props) => props.theme.colors.grey[80]} !important;

  &:hover {
    background: ${(props) => props.theme.colors.grey[20]} !important;
    color: ${(props) => props.theme.colors.grey[90]} !important;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;
