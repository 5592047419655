import { ReactNode, ReactElement, Fragment, useContext } from 'react';

import { Helmet, ResponsiveProps } from '@breathelife/ui-components';
import { useGetCloudinaryLinkAttributes } from '@breathelife/react-hooks';

import { text } from '../../Localization/Localizer';
import { ApplicationAssignee } from '../../Models/ApplicationAssignee';
import { Footer } from './Footer';
import { BaseHeader, HeaderPlaceHolder } from './Header';
import { LoadingContent, LoadingContentProps } from './LoadingContent';
import { CarrierContext } from '../../Context/CarrierContext';
import { CenteredBaseLayout } from './CenteredBaseLayout/CenteredLayout';

type Props = {
  title: string;
  subtitle?: string | ReactNode;
  offsetContent?: ReactElement;
  questionId?: string;
  progress?: number;
  isLoading: boolean;
  loadingContent?: LoadingContentProps;
  children: ReactNode;
  assignee?: ApplicationAssignee;
  headerVariant?: 'default' | 'placeholder' | 'none';
  hideScheduleCall?: boolean;
  hideProgress?: boolean;
  isFullWidthLayout?: boolean;
  useEngine?: boolean;
} & ResponsiveProps;

export function CenteredLayout(props: Props): ReactElement {
  const { headerVariant = 'default', isLoading } = props;
  const { carrierInfo, cloudinaryConfig } = useContext(CarrierContext);

  const linkAttributes = useGetCloudinaryLinkAttributes([
    { cloudinaryConfig, compactLogoUrl: carrierInfo.logoCompact },
  ]);

  return (
    <Fragment>
      <Helmet
        linkAttributes={linkAttributes}
        titleAttributes={{ title: props.isLoading ? text('loading') : props.title }}
      />
      <CenteredBaseLayout
        header={
          <Fragment>
            {headerVariant === 'default' && (
              <BaseHeader hideScheduleCall={props.hideScheduleCall} hideProgress={props.hideProgress} />
            )}
            {headerVariant === 'placeholder' && <HeaderPlaceHolder />}
          </Fragment>
        }
        title={isLoading ? '' : props.title}
        subtitle={isLoading ? '' : props.subtitle}
        offsetContent={isLoading ? undefined : props.offsetContent}
        isFullWidthLayout={props.isFullWidthLayout}
        footer={<Footer />}
      >
        {isLoading && props.loadingContent ? <LoadingContent {...props.loadingContent} /> : props.children}
      </CenteredBaseLayout>
    </Fragment>
  );
}
