import { Tab, Tabs } from '@breathelife/mui';

import styled from '../../Styles/themed-styled-components';

export const StyledTab = styled(Tab)`
  min-width: 80px !important;
`;

export const StyledTabs = styled(Tabs)`
  flex-grow: 1;

  @media (min-width: ${(props) => props.theme.moduleBreakpoints.md}) {
    .MuiTabs-flexContainer {
      justify-content: flex-start !important;
    }
  }
`;
