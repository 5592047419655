import { Box } from '@breathelife/mui';
import { ReactElement, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { getUserDisplayName } from '../../../../Helpers/user';
import { useCarrierContext, useSelector } from '../../../../Hooks';
import { EmptyListContainer, ListText, ListTitle, FilteredListEmptyImg, EmptyApplicationListImg } from './Styles';
import { CreateButton, CreateButtonActionType } from '../../../../Components/CreateButton/CreateButton';

export type Props = {
  hasFilters: boolean;
};
export function TabActiveEmptyListView(props: Props): ReactElement {
  const { t } = useTranslation();
  const { features } = useCarrierContext();
  const authentication = useSelector((store) => store.leadPlatform.authentication);
  const isLeadCreationEnabled = features.leadCreation.enabled;
  const hideCreateButton = isLeadCreationEnabled && features.leadCreation?.hideCreateButton;

  const displayName = authentication.user ? ` ${getUserDisplayName(authentication.user)}` : '';
  return (
    <EmptyListContainer textAlign='center' pt={12} minHeight={400}>
      <Box mb={3}>
        {props.hasFilters ? <FilteredListEmptyImg role='img' /> : <EmptyApplicationListImg role='img' />}
      </Box>
      <Box mb={1.5}>
        <ListTitle variant='h1' grey={70}>{`${t('tabs.active.empty.hello', { name: displayName })}!`}</ListTitle>
      </Box>
      <ListText variant='body1' grey={60} component='div'>
        {t('tabs.active.empty.noApplication')}
      </ListText>

      {!hideCreateButton && (
        <Fragment>
          {isLeadCreationEnabled && (
            <ListText variant='body1' grey={60} component='div'>
              {t('tabs.active.empty.startBy')}
            </ListText>
          )}
          <Box mt={3}>
            <CreateButton
              action={
                isLeadCreationEnabled
                  ? CreateButtonActionType.createLead
                  : CreateButtonActionType.createApplicationWithLeadAndLaunch
              }
            />
          </Box>
        </Fragment>
      )}
    </EmptyListContainer>
  );
}
