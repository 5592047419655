import { Box } from '@breathelife/mui';
import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserNotificationOptions } from '@breathelife/types';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import { Checkbox, CheckboxGroup } from '../../../../Components/Checkbox/Checkbox';
import Typography from '../../../../Components/Typography';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';
import { useCarrierContext, useDispatch, useSelector } from '../../../../Hooks';
import { updateUserNotificationPreferences } from '../../../../ReduxStore/Authentication/UserOperations';
import _ from 'lodash';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export function NotificationPreferencesModal(props: Props): ReactElement {
  const { isOpen, closeModal } = props;
  const { t } = useTranslation();
  const { features } = useCarrierContext();
  const dispatch = useDispatch();

  const user = useSelector((store) => store.leadPlatform.authentication.user);

  const carrierPreferences = features.userNotificationEmail.enabled ? features.userNotificationEmail.emailTypes : null;
  const userNotificationPreferences = user?.notificationPreferences;
  const selectedPreferencesWithDefault = carrierPreferences
    ? Object.values(carrierPreferences).reduce((acc: UserNotificationOptions, key) => {
        acc[key] = userNotificationPreferences ? userNotificationPreferences[key] : false;
        return acc;
      }, {})
    : {};

  const [selectedPreferences, setSelectedPreferences] =
    useState<UserNotificationOptions>(selectedPreferencesWithDefault);

  const isLoading = useSelector((state) => state.leadPlatform.authentication.isLoading);

  const onSaveClick = useCallback(async () => {
    if (!user) return;
    const preferencesWithBoolValues = _.mapValues(selectedPreferences, (preference) => !!preference);
    await dispatch(updateUserNotificationPreferences(user.auth0Id, preferencesWithBoolValues));
    closeModal();
  }, [dispatch, user, selectedPreferences, closeModal]);

  const onChange = (notification: string) => {
    setSelectedPreferences((oldPreferences) => ({
      ...oldPreferences,
      [notification]: !selectedPreferences[notification as keyof UserNotificationOptions],
    }));
  };

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={() => {
        closeModal();
        setSelectedPreferences(selectedPreferencesWithDefault);
      }}
      title={t('modals.notificationPreferences.title')}
      submitButton={
        <SubmitButton onClick={onSaveClick} disabled={isLoading}>
          {t('cta.save')}
        </SubmitButton>
      }
    >
      <Box mb={1}>
        <Typography variant='body1' grey={70}>
          <CheckboxGroup label={t('modals.notificationPreferences.body')}>
            {Object.keys(selectedPreferences).map((notification) => (
              <Checkbox
                key={notification}
                color='primary'
                label={t(`modals.notificationPreferences.emails.${notification}`)}
                checked={!!selectedPreferences[notification as keyof UserNotificationOptions]}
                onChange={() => {
                  onChange(notification);
                }}
              />
            ))}
          </CheckboxGroup>
        </Typography>
      </Box>
    </ModalLayout>
  );
}
