import styled from '../../../../Styles/themed-styled-components';

export const WidgetCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(props) => `1px solid ${props.theme.colors.grey[40]}`};
  border-radius: 8px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

export const WidgetCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  background-color: ${(props) => props.theme.colors.grey[20]};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[40]};
  min-height: 56px;
`;

export const WidgetCardBody = styled.div`
  padding: 24px;
  max-height: 500px;
  overflow-y: auto;
`;
