import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useLanguage, useDispatch } from '../../Hooks';

import { QueryId } from '../../ReactQuery/common/common.types';
import { fetchCarrierQuestionnaire, CarrierQuestionnaireResponse } from '../../Services/CarrierQuestionnaireService';
import { notificationSlice } from '../../ReduxStore/Notification/NotificationSlice';
import { useTranslation } from 'react-i18next';

export function useFetchCarrierQuestionnaireQuery(
  applicationId?: string,
  options?: UseQueryOptions<CarrierQuestionnaireResponse | undefined, Error>,
): UseQueryResult<CarrierQuestionnaireResponse | undefined, Error> {
  const language = useLanguage();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery<CarrierQuestionnaireResponse | undefined, Error>(
    [QueryId.carrierQuestionnaire, applicationId, language],
    async () => {
      if (!applicationId) return;
      return await fetchCarrierQuestionnaire(applicationId);
    },
    {
      ...options,
      enabled: !!applicationId,
      staleTime: 1000 * 60 * 30, // 30 minutes
      onError: () =>
        dispatch(
          notificationSlice.actions.setError({
            message: t('notifications.failedToFetchCarrierQuestionnaire'),
          }),
        ),
    },
  );
}
