import styled from 'styled-components';
import { NextButton } from '../NavigationButtons/Styles';

export const StyledNextButton = styled(NextButton)`
  &&&&& {
    path {
      fill: none;
    }
  }
`;
