import { ReactElement, createContext, PropsWithChildren, useCallback, useState } from 'react';

import {
  ApplicationSupportApplication,
  ApplicationFilters,
  PageQueryOptions,
  SortParams,
  ApplicationSearchMode,
} from '@breathelife/types';

import { useFindApplicationSupportApplicationsQuery } from '../../../../ReactQuery/ApplicationSupport/Application/application.queries';
import _ from 'lodash';

export const PER_PAGE_OPTIONS = [
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

type ApplicationsContextValue = {
  applications: ApplicationSupportApplication[];
  total: number;
  isLoading: boolean;
  isSuccess: boolean;
  onPageChange: ($page: number) => void;
  onLimitPerPageChange: ($limit: number) => void;
  onSortingChange: ($sort: SortParams<ApplicationSupportApplication>) => void;
  onSearchChange: ($search?: string) => void;
  onSearchModeChange: ($searchMode?: ApplicationSearchMode) => void;
  onFilterChange: ($filter?: ApplicationFilters) => void;
  currentPage: number;
  perPage: number;
};

export const ApplicationsContext = createContext<ApplicationsContextValue>({
  applications: [],
  total: 0,
  isLoading: false,
  isSuccess: true,
  onPageChange: () => {},
  onLimitPerPageChange: () => {},
  onSortingChange: () => {},
  onSearchChange: () => {},
  onSearchModeChange: () => {},
  onFilterChange: () => {},
  perPage: 0,
  currentPage: 1,
});

export function ApplicationsContextProvider(props: PropsWithChildren<{}>): ReactElement {
  const [applicationSupportQueryOptions, setApplicationSupportQueryOptions] = useState<
    PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>
  >({
    $page: 1,
    $limit: PER_PAGE_OPTIONS[1].value,
    $sort: {
      field: 'createdAt',
      direction: 'desc',
    },
    $searchMode: ApplicationSearchMode.application,
  });

  const {
    data: applicationsGetResponse,
    isLoading,
    isSuccess,
  } = useFindApplicationSupportApplicationsQuery(applicationSupportQueryOptions);

  const onPageChange = useCallback(($page: number): void => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => ({
        ...prevValue,
        $page,
      }),
    );
  }, []);

  const onLimitPerPageChange = useCallback(($limit: number): void => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => ({
        ...prevValue,
        $limit,
      }),
    );
  }, []);

  const onSortingChange = useCallback(($sort: SortParams<ApplicationSupportApplication>) => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => ({
        ...prevValue,
        $sort,
      }),
    );
  }, []);

  const onSearchChange = useCallback(($search?: string) => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => ({
        ...prevValue,
        $page: 1,
        $search,
      }),
    );
  }, []);

  const onSearchModeChange = useCallback(($searchMode?: ApplicationSearchMode) => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => ({
        ...prevValue,
        $searchMode,
      }),
    );
  }, []);

  const onFilterChange = useCallback(($filter?: ApplicationFilters) => {
    setApplicationSupportQueryOptions(
      (prevValue: PageQueryOptions<ApplicationSupportApplication, ApplicationFilters, ApplicationSearchMode>) => {
        if (!$filter) {
          const newValue = { ...prevValue };
          delete newValue.$filter;
          return newValue;
        }
        return {
          ...prevValue,
          $page: 1,
          $filter: _.merge(prevValue.$filter, $filter),
        };
      },
    );
  }, []);

  return (
    <ApplicationsContext.Provider
      value={{
        applications: applicationsGetResponse?.data || [],
        total: applicationsGetResponse?.total || 0,
        isLoading,
        isSuccess,
        onPageChange,
        onLimitPerPageChange,
        onSortingChange,
        onSearchChange,
        onSearchModeChange,
        onFilterChange,
        perPage: applicationsGetResponse?.limit || PER_PAGE_OPTIONS[1].value,
        currentPage: applicationSupportQueryOptions.$page || 1,
      }}
    >
      {props.children}
    </ApplicationsContext.Provider>
  );
}
