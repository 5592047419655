import { Box } from '@breathelife/mui';
import _ from 'lodash';
import { ReactElement, useEffect, useState, MouseEvent, useCallback, useContext } from 'react';
import { useTheme } from '../../Styles/themed-styled-components';

import { Icon } from '../../Components/Icons';
import { PopupMenu } from '../../Components/PopupMenu/PopupMenu';
import Typography from '../../Components/Typography';
import { getUserDisplayName } from '../../Helpers/user';
import { useCarrierContext, useDispatch, useSelector } from '../../Hooks';
import { ModalType } from '../../Models/Layout';
import { AgentLicensesModal } from '../../Pages/Home/Modals/AgentLicensesModal';
import { NotificationPreferencesModal } from '../../Pages/Home/Modals/NotificationPreferencesModal/NotificationPreferencesModal';
import { PromptCompleteProfileModal } from '../../Pages/Home/Modals/PromptCompleteProfileModal';
import { SharePublicLinkModal } from '../../Pages/Home/Modals/SharePublicLinkModal';
import { UserEditModal } from '../../Pages/Home/Modals/UserEdit/UserEditModal';

import { StyledButton, UserContainer, UserPicture } from './Styles';
import { layoutSlice } from '../../ReduxStore/Layout/LayoutSlice';
import { UserMenuContext } from '../../Context/UserMenuContextProvider';

type UserMenuProps = {
  showUserName?: boolean;
  showCarret?: boolean;
  variant: 'light' | 'default';
};

export function UserMenu(props: UserMenuProps): ReactElement {
  const { showUserName, showCarret, variant } = props;

  const { features } = useCarrierContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.leadPlatform.authentication.user);

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [isCompletingProfile, setIsCompletingProfile] = useState(false);
  const modalState = useSelector((store) => store.leadPlatform.layout.modalState);
  const isUserEditModalOpen = modalState.isOpen && modalState.type === ModalType.userEdit;

  const showPopover = Boolean(anchorEl);
  const id = showPopover ? 'popover' : undefined;

  const isAuthenticatedUserMissingInfo = !user?.firstName || !user.lastName || !user.emailLogin || !user.phoneNumber;
  const userGroupName = user?.subGroupName || user?.groupName || '';

  const {
    setIsSharePublicLinkModalOpen,
    setIsAgentLicensesModalOpen,
    setIsNotificationPreferencesModalOpen,
    isAgentLicensesModalOpen,
    openUserEditModal,
    isSharePublicLinkModalOpen,
    isNotificationPreferencesModalOpen,
    items,
  } = useContext(UserMenuContext);

  useEffect(() => {
    if (!isAuthenticatedUserMissingInfo && isCompletingProfile) {
      setIsCompletingProfile(false);
    }
  }, [isAuthenticatedUserMissingInfo, isCompletingProfile, setIsCompletingProfile]);

  const onSubmitCompleteProfileModal = useCallback(() => {
    setIsCompletingProfile(true);
  }, [setIsCompletingProfile]);

  const onCloseUserEditModal = useCallback(() => {
    setIsCompletingProfile(false);
    dispatch(
      layoutSlice.actions.setModalState({
        modalState: { isOpen: false, type: ModalType.userEdit },
      }),
    );
  }, [dispatch]);

  const onCloseNotificationsModal = useCallback(() => {
    setIsNotificationPreferencesModalOpen(false);
  }, [setIsNotificationPreferencesModalOpen]);

  const onCloseSharePublicLinkModal = useCallback(() => {
    setIsSharePublicLinkModalOpen(false);
  }, [setIsSharePublicLinkModalOpen]);

  const onCloseAgentLicensesModal = useCallback(() => {
    setIsAgentLicensesModalOpen(false);
  }, [setIsAgentLicensesModalOpen]);

  const UserButton = user ? (
    <StyledButton
      data-testid='header-user-button'
      aria-describedby={id}
      onClick={(event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
    >
      <UserContainer>
        {user?.picture && (
          <Box>
            <UserPicture src={user?.picture} width={32} height={32} />
          </Box>
        )}
        {showUserName && (
          <Box ml={1} textAlign='left'>
            <Box>
              <Typography variant='body2' grey={variant === 'light' ? 0 : 70}>
                {getUserDisplayName(user)}
              </Typography>
            </Box>
            <Typography variant='small1' grey={variant === 'light' ? 0 : 70}>
              {userGroupName && <Box>{userGroupName}</Box>}
            </Typography>
          </Box>
        )}
        {showCarret && (
          <Box ml={1} lineHeight={0}>
            <Icon
              name='filledCaret'
              stroke='none'
              fill={variant === 'light' ? theme.colors.grey[0] : theme.colors.grey[90]}
              variant='default'
              rotation={showPopover ? 180 : 0}
            />
          </Box>
        )}
      </UserContainer>
    </StyledButton>
  ) : null;
  return (
    <div>
      <PromptCompleteProfileModal
        isOpen={isAuthenticatedUserMissingInfo && !isCompletingProfile}
        onSubmit={onSubmitCompleteProfileModal}
      />
      <UserEditModal
        isOpen={isUserEditModalOpen || isCompletingProfile}
        isCompletingProfile={isCompletingProfile}
        closeModal={onCloseUserEditModal}
      />
      {features.agentLicenseValidation?.enabled && (
        <AgentLicensesModal isOpen={isAgentLicensesModalOpen} closeModal={onCloseAgentLicensesModal} />
      )}
      <SharePublicLinkModal
        onOpenProfilePage={openUserEditModal}
        closeModal={onCloseSharePublicLinkModal}
        isOpen={isSharePublicLinkModalOpen}
      />
      {features.userNotificationEmail.enabled && (
        <NotificationPreferencesModal
          closeModal={onCloseNotificationsModal}
          isOpen={isNotificationPreferencesModalOpen}
        />
      )}
      {UserButton}
      <PopupMenu
        id={id}
        open={showPopover}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        items={items}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    </div>
  );
}
