import { Fragment, ReactElement, useCallback, useContext, useMemo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box } from '@breathelife/ui-components';
import { AddIcon } from '@breathelife/mui';

import { HelmetContainer } from '../../../Components/HelmetContainer';
import LoadingView from '../../../Components/LoadingView/LoadingView';
import urls, { generateQuestionnaireUrl } from '../../../Navigation/Urls';
import { QuestionnaireVersionsContext } from './ContextProvider/QuestionnaireVersionsContextProvider';
import { QuestionnaireVersionsTable } from './QuestionnaireVersionsTable/QuestionnaireVersionsTable';
import { Select } from '../../../Components/Select/Select';
import { useLanguage } from '../../../Hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../Components/Button/ActionButton';
import { CreateQuestionnaireModal } from './CreateQuestionnaireModal';
import { useCarrierContext } from '../../../Hooks';
import { IconButton } from '../../../Components/Controls';
import { Icon } from '../../../Components/Icons';
import { useDeleteQuestionnaireMutation } from '../../../ReactQuery/Admin/Questionnaire/questionnaire.mutations';
import { DeleteQuestionnaireModal } from './QuestionnaireVersionsTable/DeleteQuestionnaireModal';
import { RestrictedToUserPermissions } from '../../../Components/Restricted/RestrictedToUserPermissions';
import { Permission } from '@breathelife/types';

export function QuestionnaireManagementViewContainer(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const language = useLanguage();
  const { features } = useCarrierContext();

  const { questionnaireId: questionnaireIdParam } = useParams();
  const {
    questionnaires,
    latestQuestionnaireId,
    questionnaireVersions,
    questionnaireVersionsIsFetching,
    questionnairesIsFetching,
    setSelectedQuestionnaireId,
    selectedQuestionnaireId,
  } = useContext(QuestionnaireVersionsContext);

  useEffect(() => {
    setSelectedQuestionnaireId('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isCreateQuestionnaireModalOpen, setIsCreateQuestionnaireModalOpen] = useState<boolean>(false);

  const isMultiQuestionnaireEnabled = features.multiQuestionnaire.enabled;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const deleteQuestionnaireMutation = useDeleteQuestionnaireMutation({
    onSuccess: () => {
      setIsDeleteModalOpen(false);
      navigate(urls.questionnaireManagement.fullPath);
    },
  });

  useEffect(() => {
    const isInitialLoad = !questionnaireIdParam;
    if (latestQuestionnaireId && isInitialLoad) {
      navigate(generateQuestionnaireUrl(latestQuestionnaireId), { replace: true });
    }
  }, [latestQuestionnaireId, questionnaireIdParam, questionnaires, navigate]);

  useEffect(() => {
    if (!questionnaireIdParam || !questionnaires.find((questionnaire) => questionnaire.id === questionnaireIdParam))
      return;

    setSelectedQuestionnaireId(questionnaireIdParam);
  }, [questionnaires, questionnaireIdParam, setSelectedQuestionnaireId]);

  const onSelectQuestionnaire = useCallback(
    (questionnaireId: string) => {
      navigate(generateQuestionnaireUrl(questionnaireId));
    },
    [navigate],
  );

  const questionnairesOptions = useMemo(() => {
    return (
      questionnaires?.map((questionnaire) => {
        return {
          value: questionnaire.id,
          label: questionnaire.name[language] as string,
        };
      }) || []
    );
  }, [questionnaires, language]);

  if (
    (questionnaireVersionsIsFetching || questionnairesIsFetching) &&
    (questionnaireVersions.length > 0 || questionnaires.length > 0)
  )
    return <LoadingView />;

  return (
    <Fragment>
      <HelmetContainer text={t('pageTitles.questionnaireManagement')} />
      <Box m={2} display='flex' flexDirection='column' flexGrow={1}>
        <Box display='flex' justifyContent='space-between'>
          <Box display='flex' ml={1}>
            <Select
              isSearchFilter
              id='questionnaire-select'
              label={t('admin.questionnaireManagement.title')}
              value={selectedQuestionnaireId || ''}
              options={questionnairesOptions}
              onChange={onSelectQuestionnaire}
            />
            {isMultiQuestionnaireEnabled && selectedQuestionnaireId && (
              <RestrictedToUserPermissions requiredPermissions={[Permission.QuestionnaireRemove]}>
                <Box ml={2}>
                  <IconButton
                    data-testid='actionButton-delete'
                    icon={<Icon name='delete' alt={t('cta.delete')} />}
                    title={t('cta.delete')}
                    onClick={() => setIsDeleteModalOpen(true)}
                    aria-label={t('cta.delete')}
                  />

                  {isDeleteModalOpen && isDeleteModalOpen && (
                    <DeleteQuestionnaireModal
                      onSubmitClick={() => deleteQuestionnaireMutation.mutate(selectedQuestionnaireId)}
                      isOpen={isDeleteModalOpen}
                      closeModal={() => setIsDeleteModalOpen(false)}
                    />
                  )}
                </Box>
              </RestrictedToUserPermissions>
            )}
          </Box>

          {isMultiQuestionnaireEnabled && (
            <ActionButton
              data-testid='add-new-rule-button'
              color='primary'
              variant='contained'
              onClick={() => {
                setIsCreateQuestionnaireModalOpen(true);
              }}
              startIcon={<AddIcon htmlColor='white' />}
            >
              {t('cta.addNew')}
            </ActionButton>
          )}
        </Box>

        <Box mt={2}>
          <QuestionnaireVersionsTable />
        </Box>
        {isCreateQuestionnaireModalOpen && (
          <CreateQuestionnaireModal
            isOpen={isCreateQuestionnaireModalOpen}
            onCancel={() => {
              setIsCreateQuestionnaireModalOpen(false);
            }}
          />
        )}
      </Box>
    </Fragment>
  );
}
