import { TFunction } from 'i18next';
import _ from 'lodash';
import { ReactElement, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../Styles/themed-styled-components';
import { RenderingSection, RenderingSubsection } from '@breathelife/questionnaire-engine';
import { ImageTypes } from '@breathelife/ui-components';

import { Icon } from '../../../Components/Icons';
import Typography from '../../../Components/Typography';
import { LeadPlatformTheme } from '../../../Styles/Types';
import { NavButton, SubNavLink, VisuallyHidden, SectionListItem, SubsectionListItem } from './Styles';
import { Box } from '@breathelife/mui';
import { ErrorCounter } from '../../Badge/ErrorCounter';
import { CarrierContext } from '../../../Context/CarrierContext';

export type NavigationRenderingSection = RenderingSection & { visited: boolean };

type Props = {
  activeSectionIndex: number;
  onSectionClick: (index: number) => void;
  allSections: NavigationRenderingSection[];
  hasSectionEndIcons?: boolean;
};

const getSectionEndIcon = (
  section: NavigationRenderingSection,
  t: TFunction,
  displayErrorCount: boolean,
): ReactElement | null => {
  if (section.completed) {
    return (
      <Icon
        name='circledCheckMark'
        variant='green'
        inverted
        stroke='none'
        imageType={ImageTypes.meaningful}
        ariaLabel={t('assistedApplication.sectionComplete')}
      />
    );
  }

  if (!section.completed && section.visited) {
    const errorCount = section.numberOfIncompleteFields + section.numberOfInvalidFields;
    return (
      <Box position='relative'>
        <Box>
          <Icon
            name='circledWarning'
            variant='orange'
            inverted
            stroke='none'
            imageType={ImageTypes.meaningful}
            ariaLabel={t('assistedApplication.sectionWarning')}
          />
        </Box>

        {displayErrorCount && <ErrorCounter count={errorCount} withPlacementWrapper max={9} />}
      </Box>
    );
  }

  return null;
};

export function AssistedApplicationNavigation(props: Props): ReactElement {
  const theme = useTheme() as LeadPlatformTheme;
  const { t } = useTranslation();

  const { activeSectionIndex, onSectionClick, hasSectionEndIcons = true, allSections } = props;

  const { features } = useContext(CarrierContext);
  return (
    <Box display='flex' flexDirection='column' alignItems='start'>
      <nav role='navigation' aria-label={t('assistedApplication.assistedApplicationNavigationMenu')}>
        <ol role='menu'>
          {allSections.map((section: NavigationRenderingSection, sectionIndex: number) => {
            const isActive = activeSectionIndex === sectionIndex;
            return (
              <SectionListItem key={`AssistedApplication-${section.id}`} role='none'>
                {isActive && <VisuallyHidden>{t('assistedApplication.currentSection')}</VisuallyHidden>}
                <NavButton
                  role='menuitem'
                  $isActive={isActive}
                  textColor={isActive ? theme.colors.grey[90] : theme.colors.grey[70]}
                  onClick={() => onSectionClick(sectionIndex)}
                  endIcon={
                    hasSectionEndIcons ? getSectionEndIcon(section, t, features.incompleteFieldsSupport.enabled) : null
                  }
                  data-testid={`AssistedApplication-${section.id}`}
                >
                  {section.title}
                </NavButton>
                {isActive && <AssistedApplicationSubNavigation section={section} />}
              </SectionListItem>
            );
          })}
        </ol>
      </nav>
    </Box>
  );
}

function AssistedApplicationSubNavigation(props: { section: NavigationRenderingSection }): ReactElement | null {
  const { section } = props;
  const { t } = useTranslation();

  const [activeSubsectionIndex, setActiveSubsectionIndex] = useState(0);

  const subsectionsNavigation = useMemo(
    () => section.subsections.filter((subsection) => subsection.visible && subsection.showInNavigation),
    [section.subsections],
  );

  if (!subsectionsNavigation.length) return null;
  return (
    <ol role='menu'>
      {subsectionsNavigation.map((subsection: RenderingSubsection, subsectionIndex: number) => {
        const isActive = activeSubsectionIndex === subsectionIndex;
        return (
          <SubsectionListItem key={`AssistedApplication-${subsection.id}`} role='none'>
            {isActive && <VisuallyHidden>{t('assistedApplication.currentSubsection')}</VisuallyHidden>}
            <SubNavLink
              href={`#${_.kebabCase(subsection.title)}`}
              role='menuitem'
              $isActive={isActive}
              onClick={() => setActiveSubsectionIndex(subsectionIndex)}
            >
              <Typography variant='button'>{subsection.title}</Typography>
            </SubNavLink>
          </SubsectionListItem>
        );
      })}
    </ol>
  );
}
