import { z } from 'zod';
import { KeepTypeAlias } from '../utilities';

export enum NotificationStatus {
  Unread = 'unread',
  Read = 'read',
}

export enum NotificationType {
  Info = 'info',
  Action = 'action',
}

export enum NotificationEventName {
  OneSpanEmailBounce = 'OneSpanEmailBounce',
  OneSpanCreatePackageError = 'OneSpanCreatePackageError',
  ApplicationSubmitted = 'ApplicationSubmitted',
  NewFeatureLaunch = 'NewFeatureLaunch',
}

const baseNotificationSchema = z.object({
  id: z.string(),
  userId: z.string(),
  status: z.nativeEnum(NotificationStatus),
  createdAt: z.date(),
  updatedAt: z.date(),
});

const oneSpanEmailBounceEventMetadataSchema = z.object({
  applicationId: z.string(),
  leadId: z.number(),
  applicationRefNumber: z.string(),
  email: z.string(),
  signerName: z.string(),
  roles: z.array(z.string()),
});

const oneSpanEmailBounceEventSchema = z.object({
  event: z.literal(NotificationEventName.OneSpanEmailBounce),
  metadata: oneSpanEmailBounceEventMetadataSchema,
});

const applicationSubmittedEventMetadataSchema = z.object({
  applicationId: z.string(),
  leadId: z.number(),
  applicationRefNumber: z.string(),
});

const applicationSubmittedEventSchema = z.object({
  event: z.literal(NotificationEventName.ApplicationSubmitted),
  metadata: applicationSubmittedEventMetadataSchema,
});

const oneSpanCreatePackageErrorEventMetadataSchema = z.object({
  applicationId: z.string(),
  leadId: z.number(),
  applicationRefNumber: z.string(),
});

const oneSpanCreatePackageErrorEventSchema = z.object({
  event: z.literal(NotificationEventName.OneSpanCreatePackageError),
  metadata: oneSpanCreatePackageErrorEventMetadataSchema,
});

const newFeatureEventMetadataSchema = z.object({
  name: z.string(),
});

const newFeatureEventSchema = z.object({
  event: z.literal(NotificationEventName.NewFeatureLaunch),
  metadata: newFeatureEventMetadataSchema,
});

export const eventSchema = z.union([
  applicationSubmittedEventSchema,
  oneSpanEmailBounceEventSchema,
  oneSpanCreatePackageErrorEventSchema,
  newFeatureEventSchema,
]);

export const partialEventSchema = z.union([
  oneSpanEmailBounceEventSchema.partial(),
  applicationSubmittedEventSchema.partial(),
  oneSpanCreatePackageErrorEventSchema.partial(),
  newFeatureEventSchema.partial(),
]);

export const metadataSchema = z.union([
  oneSpanEmailBounceEventMetadataSchema,
  applicationSubmittedEventMetadataSchema,
  oneSpanCreatePackageErrorEventMetadataSchema,
  newFeatureEventMetadataSchema,
]);

const encryptedMetadataSchema = z.union([
  z.intersection(
    applicationSubmittedEventSchema.omit({ metadata: true }),
    z.object({ encryptedMetadata: z.instanceof(Buffer) }),
  ),
  z.intersection(
    oneSpanEmailBounceEventSchema.omit({ metadata: true }),
    z.object({ encryptedMetadata: z.instanceof(Buffer) }),
  ),
  z.intersection(
    oneSpanCreatePackageErrorEventSchema.omit({ metadata: true }),
    z.object({ encryptedMetadata: z.instanceof(Buffer) }),
  ),
  z.intersection(newFeatureEventSchema.omit({ metadata: true }), z.object({ encryptedMetadata: z.instanceof(Buffer) })),
]);

export const encryptedNotificationSchema = z.intersection(baseNotificationSchema, encryptedMetadataSchema);

export const notificationSchema = z.intersection(baseNotificationSchema, eventSchema);
export const notificationsSchema = z.array(z.intersection(baseNotificationSchema, eventSchema));
export const partialNotificationSchema = z.intersection(baseNotificationSchema.partial(), partialEventSchema);
export const fetchAllNotificationsSchema = z.object({
  notifications: notificationsSchema,
  totalUnreadNotifications: z.number(),
});

export type Metadata = KeepTypeAlias<z.infer<typeof metadataSchema>>;
export type EventNotification = KeepTypeAlias<z.infer<typeof eventSchema>>;
export type Notification = KeepTypeAlias<z.infer<typeof notificationSchema>>;
export type EncryptedNotification = KeepTypeAlias<z.infer<typeof encryptedNotificationSchema>>;
export type FetchAllNotificationsResponse = KeepTypeAlias<z.infer<typeof fetchAllNotificationsSchema>>;
