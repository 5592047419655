import { ReactElement, useContext } from 'react';

import config from '@breathelife/config/frontend';
import { SchedulingTimeFrameConfig } from '@breathelife/types';

import { CarrierContext } from '../../../Context/CarrierContext';
import { getSchedulingTimeFrames } from '../../../Helpers/schedulingTimeFrames';
import { shortLocale, text } from '../../../Localization/Localizer';
import { ApplicationAssignee, Communication } from '../../../Models/ApplicationAssignee';
import { useGetAdvisorTitleFallback } from '../../../Hooks/useGetAdvisorTitleFallback';
import { OnSubmitScheduleDataType } from '../../ContactForm';
import { ContactAdvisorModal } from '../../Modal/ContactAdvisorModal';

export type Props = {
  useMobileStylingOnDesktop?: boolean;
  assignee: ApplicationAssignee;
  communication: Communication;
  referenceNumber?: string;
  onSubmit: (scheduleCallPreferences: OnSubmitScheduleDataType) => void;
  isSubmitted: boolean;
  onClose: () => void;
  schedulingTimeFrameConfig?: SchedulingTimeFrameConfig;
  isPlural: boolean;
};

export function ContactModal(props: Props): ReactElement {
  const language = shortLocale();
  const { carrierInfo } = useContext(CarrierContext);
  const carrierContactPhoneNumber = carrierInfo.contactPhoneNumber;
  const carrierContactEmail = carrierInfo?.contactEmail;

  const { cloudName, folder } = config.get<Record<string, string>>('keys.cloudinary');
  const schedulingTimeFrames = getSchedulingTimeFrames(props.schedulingTimeFrameConfig);
  const advisorTitleFallback = useGetAdvisorTitleFallback();

  return (
    <ContactAdvisorModal
      useMobileStylingOnDesktop={props.useMobileStylingOnDesktop}
      onClose={props.onClose}
      localizationStrings={{
        [language]: {
          legal: text(`scheduleCall.modal.legal.life`),
          submit: text('scheduleCall.modal.submit'),
          title: text('scheduleCall.modal.title'),
          emailUs: {
            single: text('scheduleCall.modal.emailUs.single'),
            plural: text('scheduleCall.modal.emailUs.plural'),
          },
          subtitle: {
            single: text('scheduleCall.modal.subtitle.single'),
            plural: text('scheduleCall.modal.subtitle.plural'),
          },
          confirmation: {
            single: {
              title: text('scheduleCall.modal.confirmation.single.title'),
              subtitle: text('scheduleCall.modal.confirmation.single.subtitle'),
            },
            plural: {
              title: text('scheduleCall.modal.confirmation.plural.title'),
              subtitle: text('scheduleCall.modal.confirmation.plural.subtitle'),
            },
          },
        },
      }}
      isPlural={props.isPlural}
      language={language}
      form={{
        ...props.communication.userInfo,
        schedulingTimeFrames,
        isSubmitting: props.communication.isLoading,
        isSubmitted: props.isSubmitted,
        bestMoments: props.communication.preferences?.bestMoments,
        preferredMethods: props.communication.preferences?.preferredMethods,
        onSubmitSchedule: (preferences: OnSubmitScheduleDataType) => props.onSubmit(preferences),
      }}
      contactPersonInfo={{
        name: props.assignee.name ?? '',
        title: props.assignee.subgroup?.name || props.assignee.group?.name || advisorTitleFallback,
        imgUrl: props.assignee.picture,
      }}
      carrierContactPhoneNumber={carrierContactPhoneNumber}
      carrierContactEmail={carrierContactEmail}
      customerServicePhoneNumber={props.assignee.phone}
      customerServiceEmail={props.assignee.email}
      securityDisclosure={props.assignee.securityDisclosure}
      referenceNumber={
        config.get<boolean>('ui.displayReferenceNumber') && props.referenceNumber ? props.referenceNumber : undefined
      }
      cloudinaryProps={{
        cloudName,
        folder,
      }}
    />
  );
}
