import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs, TabType } from '../../../Components/Tabs/Tabs';
import { SectionGroupId } from '../../../Helpers/questionnaireAnswers';
import { useAssistedApplicationContext } from '../../../Hooks/useAssistedApplicationContext';
import { HeaderTabContainer } from './Styles';
import { NumberOfVisibleErrorsBySectionGroup } from '../AssistedApplicationContainer';

export function HeaderTabBar({
  numberOfVisibleErrorsBySectionGroup,
  showCompletionIcons,
}: {
  showCompletionIcons: boolean;
  numberOfVisibleErrorsBySectionGroup: NumberOfVisibleErrorsBySectionGroup;
}): ReactElement {
  const { currentSectionGroupId, onSwitchSectionGroupTab } = useAssistedApplicationContext();
  const { t } = useTranslation();
  const { numberOfErrorsForContractTab, numberOfErrorsForInsuredsTab, contractTabIsCompleted, insuredsTabIsCompleted } =
    useMemo(() => {
      let nContractTabErrors = 0;
      let nInsuredsTabErrors = 0;
      let contractTabCompleted = false;
      let insuredsHasOneIncompleteProposedInsured = false;
      if (showCompletionIcons) {
        for (const visibleErrorsForSectionGroup of numberOfVisibleErrorsBySectionGroup) {
          if (visibleErrorsForSectionGroup.sectionGroupId === 'contract') {
            nContractTabErrors = visibleErrorsForSectionGroup.numberOfErrors;
            contractTabCompleted = visibleErrorsForSectionGroup.completed;
          } else {
            nInsuredsTabErrors += visibleErrorsForSectionGroup.numberOfErrors;
            if (!insuredsHasOneIncompleteProposedInsured && !visibleErrorsForSectionGroup.completed) {
              insuredsHasOneIncompleteProposedInsured = true;
            }
          }
        }
      }
      return {
        numberOfErrorsForContractTab: nContractTabErrors,
        numberOfErrorsForInsuredsTab: nInsuredsTabErrors,
        contractTabIsCompleted: !showCompletionIcons ? false : contractTabCompleted,
        insuredsTabIsCompleted: !showCompletionIcons ? false : !insuredsHasOneIncompleteProposedInsured,
      };
    }, [numberOfVisibleErrorsBySectionGroup, showCompletionIcons]);

  // TODO: Fetch the section groups information from the questionnaire https://zinnia.atlassian.net/browse/HOT-4668
  const tabs: TabType[] = [
    {
      label: t('assistedApplication.multiInsuredTabs.insuredPersons'),
      numberOfErrors: numberOfErrorsForInsuredsTab,
      completed: insuredsTabIsCompleted,
    },
    {
      label: t('assistedApplication.multiInsuredTabs.policyContract'),
      numberOfErrors: numberOfErrorsForContractTab,
      completed: contractTabIsCompleted,
    },
  ];

  const onSwitchTabs = useCallback(
    (newValue: number) => {
      const sectionGroupId = newValue === 1 ? SectionGroupId.contract : SectionGroupId.insuredPeople;
      onSwitchSectionGroupTab(sectionGroupId);
    },
    [onSwitchSectionGroupTab],
  );

  return (
    <HeaderTabContainer>
      <Tabs
        value={currentSectionGroupId === SectionGroupId.contract ? 1 : 0}
        onChange={(_event, newValue) => onSwitchTabs(newValue)}
        tabs={tabs}
      />
    </HeaderTabContainer>
  );
}
