import _ from 'lodash';
import { ReactElement } from 'react';

import { Box } from '@breathelife/mui';

import { AdvisorContactInfo } from './AdvisorContactInfo';
import { Confirmation } from './Confirmation';
import { DesktopContainer, LeftDesktopContent, RightDesktopContent, SecurityDisclosure } from './Styles';
import { BaseContactAdvisorModalProps } from './types';
import { ContactForm, OnSubmitScheduleDataType, StringGrammarNumber } from '../../ContactForm';
import { AdvisorInfoPanel, Title } from '@breathelife/ui-components';

type Props = BaseContactAdvisorModalProps & {
  onSubmitContactForm: (onSubmitContactArgs: OnSubmitScheduleDataType) => void;
};

export function ContactAdvisorModalDesktop(props: Props): ReactElement {
  const {
    form,
    language,
    localizationStrings,
    customerServicePhoneNumber,
    customerServiceEmail,
    carrierContactPhoneNumber,
    carrierContactEmail,
    contactPersonInfo,
    securityDisclosure,
    cloudinaryProps,
    openingHours,
    referenceNumber,
    useMobileStylingOnDesktop,
    hideEmail,
    hidePhone,
    onSubmitContactForm,
    isPlural: isPluralProps = false,
  } = props;

  const textStrings = localizationStrings[language];
  const hasAssignee = contactPersonInfo?.name || contactPersonInfo?.imgUrl;
  const isPlural = !hasAssignee || isPluralProps;
  const noun = isPlural ? StringGrammarNumber.plural : StringGrammarNumber.single;
  const { schedulingTimeFrames, isSubmitting, ...scheduleData } = _.omit(form, 'onSubmitSchedule');

  return (
    <DesktopContainer>
      <LeftDesktopContent px={6} pt={4} pb={6} display='flex' flexDirection='column'>
        <Box mb={3}>
          {contactPersonInfo && hasAssignee && (
            <AdvisorInfoPanel advisorInfo={contactPersonInfo} cloudinaryProps={cloudinaryProps} />
          )}
        </Box>
        <Title level={2}>{textStrings.title}</Title>

        {!useMobileStylingOnDesktop && (
          <AdvisorContactInfo
            language={language}
            localizationStrings={localizationStrings}
            customerServicePhoneNumber={customerServicePhoneNumber || carrierContactPhoneNumber}
            customerServiceEmail={customerServiceEmail || carrierContactEmail}
            contactPersonInfo={contactPersonInfo}
            securityDisclosure={securityDisclosure}
            openingHours={openingHours}
            referenceNumber={referenceNumber}
            isPlural={isPlural}
          />
        )}

        {securityDisclosure && (
          <Box marginTop='auto'>
            <SecurityDisclosure>{securityDisclosure}</SecurityDisclosure>
          </Box>
        )}
      </LeftDesktopContent>

      <RightDesktopContent>
        <Box margin={6}>
          {!form.isSubmitted ? (
            <ContactForm
              schedulingTimeFrames={schedulingTimeFrames}
              language={language}
              localizationStrings={localizationStrings}
              isPlural={isPlural}
              hideEmail={hideEmail}
              hidePhone={hidePhone}
              scheduleData={scheduleData}
              isSubmitting={isSubmitting}
              onSubmitContactForm={onSubmitContactForm}
            />
          ) : (
            <Confirmation
              title={textStrings.confirmation[noun].title}
              subtitle={textStrings.confirmation[noun].subtitle}
            />
          )}
        </Box>
      </RightDesktopContent>
    </DesktopContainer>
  );
}
