import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@breathelife/mui';
import { ProductSelector } from '@breathelife/types';

import { NodeIdSelector } from '../../../../../Components/NodeIds/NodeIdSelector';
import Typography from '../../../../../Components/Typography';
import { useLanguage } from '../../../../../Hooks';
import { ValueChangeFunction } from '../../types';
import { AllQuestionnairesNodeIdsContext } from '../../AllQuestionnairesNodeIdsContextProvider';

type Props = {
  selector?: Partial<ProductSelector> | null;
  onInputValueChange: ValueChangeFunction;
  disabled?: boolean;
};

export function EntitySelector({ selector, onInputValueChange, disabled }: Props): ReactElement {
  const { t } = useTranslation();
  const language = useLanguage();
  const { nodeIdsForAllQuestionnaires } = useContext(AllQuestionnairesNodeIdsContext);

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Box mt={2}>
          <Typography variant='h3'>{t('admin.productManagement.labels.selectors')}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} display='flex' flexDirection='column'>
          <NodeIdSelector
            selectedLanguage={language}
            nodeIds={nodeIdsForAllQuestionnaires}
            selectedNodeId={selector?.id ?? ''}
            readOnly={false}
            required
            disabled={disabled}
            label={t('admin.productManagement.labels.productFieldAnswers')}
            onChange={(option) => {
              if (option !== null) {
                onInputValueChange({
                  path: 'selector.id',
                  value: option.value,
                });
              }
            }}
          />
        </Box>
        <Box mt={2} display='flex' flexDirection='column'>
          <NodeIdSelector
            selectedLanguage={language}
            nodeIds={nodeIdsForAllQuestionnaires}
            disabled={disabled}
            selectedNodeId={selector?.coverageAmount ?? ''}
            readOnly={false}
            label={t('admin.productManagement.labels.coverageAmountFieldAnswer')}
            onChange={(option) => {
              if (option !== null) {
                onInputValueChange({
                  path: 'selector.coverageAmount',
                  value: option.value,
                });
              }
            }}
          />
        </Box>
        <Box mt={2} display='flex' flexDirection='column'>
          <NodeIdSelector
            selectedLanguage={language}
            nodeIds={nodeIdsForAllQuestionnaires}
            disabled={disabled}
            selectedNodeId={selector?.premium ?? ''}
            readOnly={false}
            label={t('admin.productManagement.labels.premiumFieldAnswer')}
            onChange={(option) => {
              if (option !== null) {
                onInputValueChange({
                  path: 'selector.premium',
                  value: option.value,
                });
              }
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
