import { Box } from '@breathelife/mui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from '../../../../Components/Button/SubmitButton';
import Typography from '../../../../Components/Typography';
import { ModalLayout } from '../../../../Layouts/Modal/ModalLayout';

type ApplicationNotFoundProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  applicationId: string | undefined;
};

export function ApplicationNotFoundConfirmModal(props: ApplicationNotFoundProps): ReactElement {
  const { t } = useTranslation();
  const { isOpen, onClose, onConfirm, applicationId } = props;

  return (
    <ModalLayout
      maxWidth='sm'
      isOpen={isOpen}
      closeModal={onClose}
      title={t('modals.assistedApplication.errors.applicationNotFoundMightHaveBeenRetentionDeleted.title')}
      submitButton={
        <SubmitButton data-testid='application-not-found-modal-confirm' onClick={onConfirm}>
          {t('modals.assistedApplication.errors.applicationNotFoundMightHaveBeenRetentionDeleted.button')}
        </SubmitButton>
      }
    >
      <Box>
        <Typography variant='body1'>
          {t('modals.assistedApplication.errors.applicationNotFoundMightHaveBeenRetentionDeleted.body', {
            applicationId,
          })}
        </Typography>
      </Box>
    </ModalLayout>
  );
}
