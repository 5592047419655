import {
  Localized,
  NodeIdToAnswerPathMap,
  QuestionnaireDefinition,
  QuestionnaireEngine,
} from '@breathelife/questionnaire-engine';
import {
  DEFAULT_TIMEZONE_NAME,
  InsuranceModule,
  QuestionnaireBlueprint,
  Timezone,
  VersionedAnswers,
} from '@breathelife/types';

import { getQuestionnaireEngineConfig } from '../../Helpers/questionnaireEngineConfigs';
import { Application } from '../../Models/Application';
import { CarrierQuestionnaireResponse } from '../../Services/CarrierQuestionnaireService';
import { PlatformFeatures } from '../../Models/PlatformFeatures';

export type ApplicationProgressProps = {
  application?: Application;
  needsAnalysisOnly?: boolean;
  questionnaireResponse?: CarrierQuestionnaireResponse;
};

type ApplicationProgressByModuleProps = {
  application?: Application;
  insuranceModule: InsuranceModule;
  nodeIdToAnswerPathMap?: NodeIdToAnswerPathMap;
  questionnaire?: Localized<QuestionnaireDefinition>;
  blueprint?: QuestionnaireBlueprint;
  features: PlatformFeatures;
};

export function getApplicationProgressByModule(props: ApplicationProgressByModuleProps): number {
  const { application, insuranceModule, nodeIdToAnswerPathMap, questionnaire, blueprint, features } = props;

  if (!application || !nodeIdToAnswerPathMap || !questionnaire || !blueprint) return 0;

  const timezoneResult = Timezone.from(application.timezone || DEFAULT_TIMEZONE_NAME);
  if (timezoneResult.success === false) {
    return 0;
  }

  const questionnaireEngine = new QuestionnaireEngine({
    questionnaire,
    nodeIdToAnswerPathMap,
    context: {
      insuranceModules: [insuranceModule],
    },
    config: getQuestionnaireEngineConfig(application.mode, features),
    blueprint,
    timezone: timezoneResult.value,
    applicationContext: application.applicationContext,
    currentDateOverride: application.submissionDate?.toString() || null,
  });

  if (!questionnaireEngine) return 0;

  const isCompleted =
    insuranceModule === InsuranceModule.insuranceApplication ? application.signed || application.submitted : undefined;

  return questionnaireEngine.calculateProgress(
    new VersionedAnswers({ v1: application.answers, v2: application.answersV2 }),
    isCompleted,
  );
}
