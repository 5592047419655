import { ReactElement, createContext } from 'react';

import {
  CloudinaryConfig,
  Language,
  NavigationSidebarVariants,
  UserFields,
  LeadStatus,
  LineOfBusinessName,
  LeadTableColumnInfo,
} from '@breathelife/types';

import { FileType } from '../Helpers/getFileIcon';
import { EnabledTabs } from '../Models/Layout';
import { LeadStatusesColumnData } from '../Models/Lead';
import { Mga } from '../Models/Mga';
import { PlatformFeatures } from '../Models/PlatformFeatures';
import { CountryCode, LocalizedHostname } from '../types';

export type ContextProps = {
  carrierInfo: {
    companyName: string;
    id: string;
    logo: string;
    logoCompact: string;
    isDefaultTheme: boolean;
    lineOfBusiness: LineOfBusinessName[];
    minorAge?: number;
    validFileUploadMimeTypes?: string[];
  };
  cloudinaryConfig?: CloudinaryConfig;
  countryCode: CountryCode;
  enabledTabs: EnabledTabs;
  externalResources?: {
    downloadableFiles?: { title: string; subtitle?: string; source: string; type: FileType }[];
    headerMenuResources?: { title: string; link: string; icon: ReactElement<SVGElement> }[];
  };
  headerVariant?: 'primary' | 'secondary' | 'light';
  isEmailActionEnabled: (status: LeadStatus) => boolean;
  googleMapsPlaces: string;
  leadStatuses: LeadStatusesColumnData;
  leadTableColumns: LeadTableColumnInfo[];
  unsubToolUrl?: string;
  responseType?: string;
  ssoConnectionName?: string;
  defaultToSsoConnection?: boolean;
  mgas: Mga[];
  languageSettings: {
    enabledLanguages: Language[];
    default: Language;
  };
  features: PlatformFeatures;
  monitoringEnvironment?: string;
  navigationSidebarVariant?: NavigationSidebarVariants;
  iconMap: Record<string, string>;
  ssoProfileFields: UserFields;
  coverageAmountStepRounding?: number;
  pdfFetchDuration?: number;
  showBlueprintIdInEditor?: boolean;
  localizedHostnames?: LocalizedHostname[];
  git: {
    commitSha: string;
  };
};

export const CarrierContext = createContext({} as ContextProps);
